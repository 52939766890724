import { ChevronDown, ChevronUp, IconSprite, IconSize } from "@emisgroup/icons-react";
import React, { FC } from "react";

interface IRibbonActionItemViewProps {
    /**
     * The ARIA ID being uses for this component
     */
    ariaControls?: string;

    /**
     * If the icon is hidden or not
     */
    ariaHidden: boolean;

    /**
     * Any special classes for the button
     */
    buttonClasses: string;

    /**
     * If the button is disabled or not
     */
    buttonDisabled: boolean;

    /**
     * Any special classes for the icon
     */
    iconClasses: string;

    /**
     * The name of the icon
     */
    iconName: string;

    /**
     * The size of the icon
     */
    iconSize?: IconSize;

    /**
     * The onClick handler callback
     */
    onClickHandler: () => void;

    /**
     * The onKey handler callback
     */
    onKeyHandler?: (e: React.KeyboardEvent) => void;

    /**
     * Boolean to show primary sublist
     */
    showPrimarySublist?: boolean;

    /**
     * The title of the button
     */
    title: string;

    /**
     * The type of button
     */
    type: string;
}

/**
 * Responsible for rendering the Ribbon action item view
 *
 * @param props The props passed to the component
 * @returns the RibbonActionItemView
 */
export const RibbonActionItem: FC<IRibbonActionItemViewProps> = (props: IRibbonActionItemViewProps): JSX.Element => {
    const {
        ariaControls,
        ariaHidden,
        buttonClasses,
        buttonDisabled,
        iconClasses,
        iconName,
        iconSize,
        onClickHandler,
        onKeyHandler,
        showPrimarySublist,
        title,
        type,
    } = props;

    // If it's a sublist of any kind...
    if (type === "viewSublist" || type === "secondarySublist" || (type === "primary" && showPrimarySublist)) {
        return (
            <button
                aria-controls={ariaControls}
                className={buttonClasses}
                disabled={buttonDisabled}
                onKeyDown={onKeyHandler}
                onMouseDown={onClickHandler}
                type="button"
            >
                <IconSprite ariaHidden={ariaHidden} className={iconClasses} name={iconName} size={iconSize} title="" />
                <span className="eui-ribbon__sublist-text">
                    {title}
                    <ChevronDown
                        className="eui-ribbon__icon eui-ribbon__icon--responsive eui-ribbon__icon--expand"
                        size="x-small"
                        title="Expand"
                    />
                    <ChevronUp
                        className="eui-ribbon__icon eui-ribbon__icon--responsive eui-ribbon__icon--collapse"
                        size="x-small"
                        title="Collapse"
                    />
                </span>
            </button>
        );
    }

    // If it's a button that only appears inside the sublist itself...
    if (type === "sublistButton") {
        return (
            <button
                className="eui-ribbon__sublist-item--link"
                disabled={buttonDisabled}
                onKeyDown={onKeyHandler}
                onMouseDown={onClickHandler}
                type="button"
            >
                {title}
            </button>
        );
    }
    // Otherwise, just return a normal action button.
    return (
        <button
            className={buttonClasses}
            disabled={buttonDisabled}
            onKeyDown={onKeyHandler}
            onMouseDown={onClickHandler}
            type="button"
        >
            <IconSprite ariaHidden={ariaHidden} className={iconClasses} name={iconName} size={iconSize} title="" />
            {title}
        </button>
    );
};
