import * as React from "react";
import { Banner } from "@emisgroup/ui-banner";
import { useTranslation } from "@emisgroup/application-intl";
import { NotificationInfo } from "@emisgroup/icons-react";

import "./propertyWarning.css";

type PropertyWarningProps = { name: string; warnings: string[] };

const PropertyWarning = ({ name, warnings }: PropertyWarningProps) => (
    <Banner color="warning" data-testid={`${name}-warnings banner-warning`} className="property-warning">
        <Banner.Cap>
            <NotificationInfo title={useTranslation().t("information")} />
        </Banner.Cap>
        <Banner.Content vertical={true}>
            {warnings.map(warning => (
                <div key={warning} className="property-warning-text">
                    {warning}
                </div>
            ))}
        </Banner.Content>
    </Banner>
);

export default PropertyWarning;
