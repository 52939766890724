import * as React from "react";
import { DateEntry as UIDateEntry } from "@emisgroup/ui-date-picker";
import { DateEntry } from "@emisgroup/clinical-code-entry";
import { createDateFromYMDString, createYMDStringFromDate } from "@emisgroup/clinical-code-entry/lib/utils";
import { useTranslation } from "@emisgroup/application-intl";
import { Validity } from "@emisgroup/clinical-code-entry/lib/types";
import { AppMode, Code, ComponentType, DataEntryComponentProps, DataFromTemplateRun } from "../types";
import AppModeContext from "../context/appMode";
import { HistoryItem, RunningTemplateContext, TemplateHistoryContext } from "../context";
import DataEntryComponent from "./dataEntryComponent";
import Checkbox from "./controlledCheckbox";
import WarningBox from "../warningBox";
import useDateEntry from "../useDateEntry";

import "./diaryEntry.css";

type DiaryEntryProps = DataEntryComponentProps & { code: Code };

const DiaryEntry = (props: DiaryEntryProps) => {
    const { t } = useTranslation();
    const { id, label, isMandatory, onChange, code } = props;
    const componentId = `component-${id}`;
    const { mode } = React.useContext(AppModeContext);
    const { templateData = {}, runDate } = React.useContext(RunningTemplateContext);
    const isInRunMode = mode === AppMode.RUN;
    const hasInteracted = templateData[id]?.interacted;
    const [isDateValid, setIsDateValid] = React.useState<boolean>(true);
    const { history, getHistoryKey } = React.useContext(TemplateHistoryContext);

    const existingDiaryEntry = React.useMemo<HistoryItem | null>(() => {
        if (!code) {
            return null;
        }
        const historyKey = getHistoryKey("", ComponentType.DIARY_ENTRY, code.emisCodeId);
        const itemsForCodeId = historyKey ? history[historyKey] : null;
        if (!itemsForCodeId || !itemsForCodeId.length) {
            return null;
        }

        return itemsForCodeId[0];
    }, [history, code]);

    const [checked, followUpDate] = React.useMemo(() => {
        if (!isInRunMode) {
            return [false, undefined];
        }

        const data = templateData[id];
        const selected = data ? Boolean(data.selected) : false;
        const value = data && data.value ? createYMDStringFromDate(data.value as Date, "day") : undefined;
        return [selected, value];
    }, [templateData, isInRunMode]);

    const handleCheckedChange = ev => {
        const isChecked = ev.target.checked;

        const data = {
            [id]: {
                selected: isChecked,
                interacted: true,
            },
        } as DataFromTemplateRun;
        onChange(data);
        if (!isChecked) {
            setIsDateValid(true);
        }
    };

    const handleDateChange = (selectedDate: Date) => {
        onChange({ [id]: { value: selectedDate, interacted: true } } as DataFromTemplateRun);
    };

    const handleBlur = () => {
        onChange({ [id]: { interacted: true } });
    };

    const existingDiaryEntryWarning = () => {
        return (
            <WarningBox title={t("components.diary.existingDiaryEntry")}>
                <div data-testid="existing-diary-entry-warning">{t("components.diary.existingDiaryEntryWarning")}</div>
                <div className="ctc-diary-entry-warning">
                    <div className="ctc-diary-entry-warning__field-name">{t("components.diary.date")}</div>
                    <div className="ctc-diary-entry-warning__field-data">{existingDiaryEntry?.date}</div>
                    <div className="ctc-diary-entry-warning__field-name">{t("components.diary.diaryText")}</div>
                    <div className="ctc-diary-entry-warning__field-data">{existingDiaryEntry?.associatedText}</div>
                </div>
            </WarningBox>
        );
    };

    const isCheckboxValid = !isInRunMode || !isMandatory || !hasInteracted || checked;

    const renderInRunMode = () => {
        return (
            <Checkbox
                id={`${componentId}-checkbox`}
                onChange={handleCheckedChange}
                onBlur={handleBlur}
                checked={checked}
                tabIndex={-1}
            />
        );
    };

    const renderInEditMode = () => (
        <Checkbox id={`${componentId}-checkbox`} title={label} disabled={true} checked={false} />
    );

    const onDateChange = (selectedDate: string, validity: Validity) => {
        if (selectedDate) {
            handleDateChange(createDateFromYMDString(selectedDate));
        }
        setIsDateValid(validity.isValid);
    };

    const dateEntryRef = React.useRef<HTMLDivElement>(null);
    useDateEntry(dateEntryRef.current);

    const datePicker =
        isInRunMode && checked ? (
            <div ref={dateEntryRef}>
                <DateEntry
                    data-testid="effective-date-picker"
                    dateValue={followUpDate}
                    onChange={onDateChange}
                    todaysDate={runDate}
                    futureDateAllowed={true}
                    isMandatory={true}
                />
            </div>
        ) : (
            <div className="cep-date__entry">
                {/* We are attaching an empty function because the existing datepicker is disabled and doesn't serve any functionality */}
                <UIDateEntry disabled onDateChange={() => {}} />
            </div>
        );

    const areValuesValid = isCheckboxValid && isDateValid;

    return (
        <DataEntryComponent {...props} areValuesValid={areValuesValid}>
            <div className="canvas-item-entry">
                <div id={componentId} className="ctc-diary-entry">
                    {isInRunMode ? renderInRunMode() : renderInEditMode()}
                    <div className="ctc-diary-entry__diary-date">{datePicker}</div>
                </div>
                {!isCheckboxValid && (
                    <div className="custom-form-element__error">{t("components.selectionMandatory")}</div>
                )}
            </div>
            {checked && existingDiaryEntry && existingDiaryEntryWarning()}
        </DataEntryComponent>
    );
};

export default DiaryEntry;
