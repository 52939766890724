import { Dimension, PerspectivesDefinition, IHasPerspectives, IPerspective, IListItem } from "../types";

export const getDefaultPredefinedKeys = (perspectivesDefinitions: PerspectivesDefinition[]): string[] =>
    perspectivesDefinitions.flatMap(perspectivesDefinition =>
        perspectivesDefinition.predefined
            .filter(predefined => predefined.isDefault)
            .map(predefined => `${perspectivesDefinition.setting}-${predefined.key}`),
    );

export const getPerspectivesQuickPickList = (t, perspectivesDefinitions: PerspectivesDefinition[]): IListItem[] =>
    perspectivesDefinitions.flatMap(perspectivesDefinition =>
        perspectivesDefinition.predefined.map(predefined => ({
            text: t(predefined.display),
            value: `${perspectivesDefinition.setting}-${predefined.key}`,
        })),
    );

export const buildPerspectivesKey = (
    perspectivesDefinitions: PerspectivesDefinition[],
    selectedPerspectiveKeys: string[],
): string => {
    const builtPerspectivesKeys: string[] = [];

    perspectivesDefinitions.forEach(perspectivesDefinition => {
        const perspectiveOptionValues: string[] = [];
        perspectivesDefinition.dimensions.forEach(dimension => {
            const selectedDimensionValues: string[] = [];
            dimension.values.forEach(dimensionValue => {
                const keyValue = `${perspectivesDefinition.setting}-${dimensionValue}`;
                if (selectedPerspectiveKeys.includes(keyValue) && !selectedDimensionValues.includes(keyValue)) {
                    selectedDimensionValues.push(dimensionValue);
                }
            });

            const dimensionValuesKey = selectedDimensionValues.join(",");
            const valuesForDimension =
                selectedDimensionValues.length !== 1 ? `[${dimensionValuesKey}]` : dimensionValuesKey;
            perspectiveOptionValues.push(valuesForDimension);
        });

        builtPerspectivesKeys.push(`${perspectivesDefinition.setting}-${perspectiveOptionValues.join("-")}`);
    });

    return builtPerspectivesKeys.join(":");
};

export const getDefaultPerspectivesKeyForSetting = (
    perspectivesDefinitions: PerspectivesDefinition[],
    setting: string,
): string => {
    const perspectivesDefinitionForSetting = perspectivesDefinitions.filter(
        perspectivesDefinition => perspectivesDefinition.setting === setting,
    );
    return buildPerspectivesKey(
        perspectivesDefinitionForSetting,
        getDefaultPredefinedKeys(perspectivesDefinitionForSetting),
    );
};

const removeDimensionGroupMarkers = (perspectivesKey: string): string =>
    perspectivesKey.replace(/[[\]]/g, "").replace(/,/g, "-");

const expandKey = (
    perspectivesDefinitions: PerspectivesDefinition[],
    perspectivesKey: string,
    getOptions: (perspectivesDefinition: PerspectivesDefinition) => IPerspective[],
): string[] => {
    const expandedKeys: string[] = [];

    const perspectiveKeys = removeDimensionGroupMarkers(perspectivesKey)
        .split(":")
        .map(p => {
            const [setting, ...values] = p.split("-");
            return { setting, values };
        });

    perspectivesDefinitions.forEach(perspectivesDefinition => {
        getOptions(perspectivesDefinition).forEach(option => {
            if (
                perspectiveKeys.some(p => p.setting === perspectivesDefinition.setting && p.values.includes(option.key))
            ) {
                expandedKeys.push(`${perspectivesDefinition.setting}-${option.key}`);
            }
        });
    });
    return expandedKeys;
};

export const getSelectedQuickPickItems = (perspectivesDefinitions: PerspectivesDefinition[], perspectivesKey: string) =>
    expandKey(perspectivesDefinitions, perspectivesKey, p => p.predefined);

export const expandPerspectivesKey = (perspectivesDefinitions: PerspectivesDefinition[], perspectivesKey: string) =>
    expandKey(perspectivesDefinitions, perspectivesKey, p =>
        p.dimensions.flatMap(d => d.values.map(v => ({ key: v }))),
    );

export const getDimensionsForSetting = (
    perspectivesDefinitions: PerspectivesDefinition[],
    setting: string,
): Dimension[] =>
    perspectivesDefinitions.find(perspectivesOption => perspectivesOption.setting === setting)?.dimensions ?? [];

export const getDefaultDimensionValuesForSetting = (
    perspectivesDefinitions: PerspectivesDefinition[],
    setting: string,
): string[] => getDimensionsForSetting(perspectivesDefinitions, setting)?.map(dimension => dimension.values[0]);

export const findDimensionByValue = (
    perspectiveDefinitions: PerspectivesDefinition[],
    setting: string,
    dimensionValue: string,
): Dimension | null =>
    perspectiveDefinitions
        ?.find(perspectivesOption => perspectivesOption.setting === setting)
        ?.dimensions.find(dimension => dimension.values.includes(dimensionValue)) ?? null;

const isPerspectivesMatch = (dimensionValues: string[], perspectiveKey: string) =>
    perspectiveKey.split("-").every(dimension =>
        dimension.startsWith("[")
            ? removeDimensionGroupMarkers(dimension)
                  .split("-")
                  .some(groupedDimensionValue => dimensionValues.includes(groupedDimensionValue))
            : dimensionValues.includes(dimension),
    );

export const applyPerspectives = (
    defaultPerspectiveKey: string,
    runtimeDimensionValues: string[],
    items: IHasPerspectives[],
    nestedItemsPropertyName?: string,
) => {
    const comparedPerspectives = {};
    const findPerspectiveMatch = (perspectives: IPerspective[], dimensionValues: string[]): IPerspective | undefined =>
        perspectives
            .slice()
            .reverse()
            .find(perspective =>
                perspective.key.split(":").find(perspectiveCondition => {
                    if (typeof comparedPerspectives[perspectiveCondition] === "undefined") {
                        comparedPerspectives[perspectiveCondition] = isPerspectivesMatch(
                            dimensionValues,
                            perspectiveCondition,
                        );
                    }
                    return comparedPerspectives[perspectiveCondition];
                }),
            );

    const isDefaultARuntimeMatch =
        defaultPerspectiveKey.length === 0 || isPerspectivesMatch(runtimeDimensionValues, defaultPerspectiveKey);

    const reducer = (result: IHasPerspectives[], itemWithPerspectives: IHasPerspectives) => {
        let includeItem = false;
        let customPerspectiveMatch;

        const { perspectives, ...item } = itemWithPerspectives as any;

        if (typeof perspectives === "undefined") {
            includeItem = isDefaultARuntimeMatch;
        } else {
            customPerspectiveMatch = findPerspectiveMatch(perspectives, runtimeDimensionValues);
            includeItem = Boolean(customPerspectiveMatch);
        }

        if (includeItem) {
            const nestedItems =
                nestedItemsPropertyName && item[nestedItemsPropertyName]
                    ? { [nestedItemsPropertyName]: item[nestedItemsPropertyName].reduce(reducer, []) }
                    : undefined;
            const { key, ...overrides } = customPerspectiveMatch ?? {};
            const itemResult: IHasPerspectives = {
                ...item,
                ...nestedItems,
                ...overrides,
            };

            result.push(itemResult);
        }

        return result;
    };

    return items.reduce(reducer, []);
};

export const updatePerspectiveProperty = (
    parent: IHasPerspectives,
    propertyName: string,
    propertyValue: any,
    perspectiveKey: string,
) => {
    const perspectives = parent.perspectives ?? [];
    const perspective: IPerspective = perspectives.find(p => p.key === perspectiveKey) ?? { key: perspectiveKey };
    const newPerspective = { ...perspective, [propertyName]: propertyValue };
    if (parent[propertyName] === propertyValue) delete newPerspective[propertyName];
    return perspectives.map(p => (p.key === perspectiveKey ? newPerspective : p));
};

export const keyToDescription = (key: string) =>
    key
        .replace(/\[\]/g, "never")
        .replace(/\[/g, "")
        .replace(/]/g, "")
        .replace(/,/g, ", ")
        .replace(/:/g, ", ")
        .replace(/-/g, ": ");
