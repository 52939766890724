/* eslint-disable react/style-prop-object */
import { Button } from "@emisgroup/ui-button";
import React, { CSSProperties, SyntheticEvent, useEffect, useState } from "react";
import { getDivClassName, getExpandableNodeIcon, getUlClassName, isAncestorOf, shouldScroll } from "./treeViewUtils";
import { NodeIcons, TreeNodeData } from "./types";
import "./treeViewNode.scss";

type TreeViewNodeProps = {
    depth: number;
    icons: NodeIcons;
    node: TreeNodeData;
    onNodeSelect: (node: TreeNodeData) => void;
    onNodeDoubleClick?: (node: TreeNodeData) => void;
    indentation: number;
    selectedNodeId: string;
    showNodeId?: string;
};

type TreeNodeState = {
    nodeExpanded: boolean;
    expandableNodeIcon: JSX.Element;
    ulClassName: string;
};

export const TreeViewNode: React.FC<TreeViewNodeProps> = ({
    depth,
    icons,
    node,
    onNodeSelect,
    onNodeDoubleClick,
    indentation,
    selectedNodeId,
    showNodeId,
}: TreeViewNodeProps): JSX.Element => {
    const nodeRef = React.useRef<HTMLLIElement>(null);

    const divStyle: CSSProperties = { paddingLeft: depth * indentation };

    const nodeExpanded = Boolean(showNodeId) && node.nodeId !== showNodeId && isAncestorOf(node, showNodeId as string);

    const [state, setState] = useState<TreeNodeState>({
        nodeExpanded,
        expandableNodeIcon: getExpandableNodeIcon(icons, nodeExpanded),
        ulClassName: getUlClassName(nodeExpanded),
    });

    const handleSelect = (event: SyntheticEvent, selectedNode: TreeNodeData): void => {
        event.preventDefault();
        event.stopPropagation();
        onNodeSelect(selectedNode);
    };

    const handleDoubleClick = (event: SyntheticEvent, selectedNode: TreeNodeData): void => {
        if (event.target instanceof SVGSVGElement) {
            return;
        }
        if (onNodeDoubleClick) {
            onNodeDoubleClick(selectedNode);
        }
    };

    const handleToggle = async (event: SyntheticEvent): Promise<void> => {
        event.stopPropagation();

        setState({
            ...state,
            nodeExpanded: !state.nodeExpanded,
            ulClassName: getUlClassName(!state.nodeExpanded),
            expandableNodeIcon: getExpandableNodeIcon(icons, !state.nodeExpanded),
        });
    };

    const childNodeElements = React.useMemo<JSX.Element[] | null>(() => {
        return node.children
            ? node.children.map(
                  (childNode: TreeNodeData): JSX.Element => (
                      <TreeViewNode
                          key={childNode.nodeId}
                          depth={depth + 1}
                          icons={icons}
                          node={childNode}
                          onNodeSelect={onNodeSelect}
                          onNodeDoubleClick={onNodeDoubleClick}
                          indentation={indentation}
                          selectedNodeId={selectedNodeId}
                          showNodeId={showNodeId}
                      />
                  ),
              )
            : null;
    }, [node.children, selectedNodeId, onNodeSelect, indentation, depth, icons]);

    useEffect(() => {
        setTimeout(() => {
            const parentDisplayed =
                nodeRef.current?.parentElement &&
                getComputedStyle(nodeRef.current?.parentElement as Element).display !== "none";

            if (parentDisplayed && shouldScroll(node, showNodeId, nodeRef.current?.scrollIntoView)) {
                nodeRef.current?.scrollIntoView();
            }
        }, 0);
    }, [showNodeId]);

    useEffect(() => {
        if (!state.nodeExpanded && typeof showNodeId !== "undefined" && isAncestorOf(node, showNodeId)) {
            setState({
                ...state,
                nodeExpanded: true,
                ulClassName: getUlClassName(true),
                expandableNodeIcon: getExpandableNodeIcon(icons, true),
            });
        }
    }, [showNodeId]);

    return (
        <li ref={nodeRef} className="eui-tree__node" data-testid={node["data-testid"]}>
            <div
                role="none"
                className={getDivClassName(node, selectedNodeId)}
                onClick={(event: SyntheticEvent): void => {
                    handleSelect(event, node);
                }}
                onDoubleClick={(event: SyntheticEvent): void => {
                    handleDoubleClick(event, node);
                }}
                style={divStyle}
            >
                {node.isLeaf ? (
                    <Button borderless={true} className="eui-tree__icon button-borderless">
                        {icons.leafIconElement}
                    </Button>
                ) : (
                    <Button
                        className="eui-tree__icon button-borderless"
                        borderless={true}
                        onClick={(event: SyntheticEvent): void => {
                            handleToggle(event);
                        }}
                    >
                        {state.expandableNodeIcon}
                    </Button>
                )}
                {node.content}
            </div>
            {!node.isLeaf && <ul className={state.ulClassName}>{childNodeElements}</ul>}
        </li>
    );
};

export default TreeViewNode;
