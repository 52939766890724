/* eslint-disable import/prefer-default-export */
export const enGBMessages = {
    template: "Template",
    components: {
        branding: {
            title: "Legal information",
            name: "UKCA Mark",
            type: "Label type",
            ref: "Name",
            lot: "Version number",
            date: "Release date",
            address: "Contact details",
            notes: "Notes",
        },
        associatedText: {
            name: "Associated text",
            description: "User can add associated text",
            withEllipsis: "Associated text...",
            forLabel: "associated text for {{label}}",
            default: "Default associated text",
        },
        selectionMandatory: "Selection is mandatory",
        categories: {
            header: "Consultation section",
            summary: "Section: {{category}}",
            additional: "Additional",
            assessment: "Assessment",
            comment: "Comment",
            examination: "Examination",
            familyHistory: "Family history",
            history: "History",
            problem: "Problem",
            procedure: "Procedure",
            result: "Result",
            social: "Social",
            testRequest: "Test request",
            followUp: "Follow up",
        },
        coded: {
            name: "Clinical code",
            code: "Code",
            showAllQualifiers: "Show all qualifiers if available",
            showAllQualifiersShort: "Show all qualifiers",
            mandatoryText: "Select a Code.",
        },
        component: "component",
        componentType: "Component type",
        collapseAll: "Collapse all",
        expandAll: "Expand all",
        container: "Container",
        clinicalContent: {
            name: "Text editor",
            entity: "Text editor entity",
        },
        date: "Date",
        diary: {
            name: "Diary entry",
            date: "Date:",
            diaryText: "Diary text:",
            existingDiaryEntry: "A diary entry already exists for this code",
            existingDiaryEntryWarning: "Adding a new diary entry will replace this one.",
        },
        freeText: {
            name: "Textbox",
            enterSomeText: "Enter some text",
        },
        maximumCharactersWarning: "Maximum number of characters allowed is {{maxLength}}",
        numericEntry: {
            name: "Numeric value",
            initialValue: "Initial value",
            enterANumber: "Enter a number",
        },
        panel: {
            name: "Panel",
            contentLibraryResource: "Content Library resource",
            nameOfPanel: "Panel name",
            titleOfPanel: "Panel title",
            displayPanelTitle: "Display panel title",
            externalName: "Name as saved in the content library",
            mandatoryTextTitle: "Enter a title for this {{componentType}}.",
            mandatoryTextName: "Enter a name for this {{componentType}}.",
        },
        pickingList: {
            options: "Options",
            availableOption: "1 available option",
            availableOptions: "{{number}} available options",
            codes: "Codes",
            selectFromList: "Select from list...",
            numberSelected: "{{numberSelected}} selected",
            removeSelection: "Remove selection",
            atLeastTwoItems: "Select one or more Codes.",
            mandatoryText: "Enter items for the {{componentType}}",
            allItemsUnique: "All items in the list must be unique",
            allItemsRequireData: "All items in the list require data",
            uncoded: "Text picking list",
            coded: "Picking list",
            allowMultipleSelection: "Allow multiple selection",
            exclusiveCheckbox: "Exclusive checkbox",
            styles: {
                header: "Display style",
                dropdown: "Dropdown",
                radioButtons: "Radio buttons",
            },
        },
        property: {
            label: "Label",
            tabName: "Tab Name",
            title: "Template title",
            tooltip: "Tooltip",
            mandatory: "Mandatory",
            valueRequired: "A value is required for {{propertyName}}",
            promptForDate: "Prompt for date",
            selectedByDefault: "Select by default",
            selectedByDefaultWarning:
                "This component is controlled by a visibility rule. Data will only be recorded in the clinical record if the component is displayed in the template.",
            invalidProperty: "This component has invalid properties or visibility rules",
            visibilityRules: "Visibility rules",
            panelActionButtons: "Panel action buttons",
            mandatoryText: "Enter a label for this {{componentType}}",
            only: "only",
            and: "and",
        },
        savedContent: "Content Library",

        tabBar: {
            name: "Tabs",
            description: "Description",
            tabs: "tabs",
            tabbedContainer: "tabbed container",
            scrollLeft: "Scroll tabs left",
            scrollRight: "Scroll tabs right",
            locked: "tab locked",
            tabPage: "Tab page",
            mandatoryTextContainer: "Enter a name for these {{componentType}}.",
        },
        history: {
            term: "Term",
            date: "Date",
            value: "Value",
            qualifiers: "Qualifiers",
            associatedText: "Associated text",
            historyForTitle: "history for {{title}}",
            closeTitleHistory: "close {{title}} history",
            showHistoryForCodeTerm: "Show history for {{codeTerm}}",
            cannotLoadEntries: "Cannot load previous entries",
            noPreviousEntries: "No previous entries",
            retrievingHistory: "Retrieving history...",
            retryRetrieval: "retry history retrieval",
            unsupported: "Previous entries not yet supported",
        },
        uncoded: {
            name: "Checkbox",
            shortDataDescription: "Data to be stored",
            dataDescription: "Add to clinical record as",
            mandatoryText: "Enter the text that will be added to the Clinical record.",
        },
        calculator: {
            name: "Calculator",
            parameters: "Expression parameters",
            expression: "Expression",
            atLeastOneParameter: "Calculator must have at least one expresion parameter",
            mandatoryText: "Enter an expression.",
            allHaveLabel: "All entries must have a parameter value",
            noDuplicates: "Duplicate parameters are not allowed",
            parameterDoesNotExist: "All parameters in the expression must be defined",
            invalidCalculation: "Not a valid arithmetic expression",
            couldNotFindValue: "Could not find coded value for {{param}}",
            divisionByZero: "Divide by zero detected",
            calculatedValue: "Calculated value",
            addCodeToResult: "Add a code to the result",
            codeMustBeNumeric: "Code must be numeric",
            fileResult: "File the result in the patient record",
        },
    },
};
