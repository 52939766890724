/* eslint-disable import/prefer-default-export */
import React from "react";
import { ComponentContainer as ContainerType } from "../types";

type ContainerProps = {
    children: React.ReactNode | React.ReactNodeArray;
    id?: string;
    label?: string;
    dataTestId?: string;
    className?: string;
    component: ContainerType;
    isSelected?: boolean;
    onItemSelect?: (eventData) => void;
};

export function Container({
    children,
    id,
    label,
    dataTestId,
    className,
    component,
    isSelected,
    onItemSelect,
}: ContainerProps) {
    const handleClick = evt => {
        if (onItemSelect) {
            onItemSelect({ evt, item: component });
            evt.stopPropagation();
        }
    };
    return (
        <div
            role="group"
            id={id}
            data-testid={dataTestId}
            className={`${className}${isSelected ? " selected" : ""}`}
            aria-label={label}
            onClick={handleClick}
        >
            {children}
        </div>
    );
}
