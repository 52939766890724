import * as React from "react";
import {
    Branding,
    Calculator,
    ClinicalContent,
    Coded,
    CodedPickingList,
    DiaryEntry,
    FreeText,
    NumericValue,
    UncodedPickingList,
    Uncoded,
    CodedComponent,
    ComponentType,
    MouseEventHandler,
    CanvasItem,
    Panel,
    CanvasItemClickHandler,
    Tab,
    ClinicalContentEntity,
    ClinicalContentComponent,
    NumericValueComponent,
    PickingListComponent,
    CodedPickingListComponent,
    DiaryEntryComponent,
    UncodedComponent,
    FreeTextComponent,
    CalculatorComponent,
    BrandingComponent,
} from "@emisgroup/clint-templates-common";
import { ParametersForPropertyUpdate } from "../types";
import TabContainer from "../templateComponents/tabContainer"; // eslint-disable-line import/no-cycle
import DesignerCanvasColumns from "./designerCanvasColumns"; // eslint-disable-line import/no-cycle
import DroppablePanelComponent from "../templateComponents/droppablePanelComponent";

type CanvasComponentProps = {
    component: CanvasItem;
    selectedItem: CanvasItem;
    isSelected: boolean;
    dragDropEnabled: boolean;
    arePropertiesValid: boolean;

    onSelect: MouseEventHandler | CanvasItemClickHandler;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;

    isCollapseAll?: boolean;
    isExpandAll?: boolean;
};

function noop() {}

const CanvasComponent = ({
    component,
    selectedItem,
    isSelected,
    dragDropEnabled,
    arePropertiesValid,
    onSelect,
    onPropertyUpdate,
    isCollapseAll = false,
    isExpandAll = true,
}: CanvasComponentProps) => {
    const handlePropUpdateFromComponent = (params: { propertyName: string; propertyValue: any }) => {
        onPropertyUpdate({ item: component, ...params });
    };

    const componentProps = {
        isSelected: selectedItem && selectedItem.id === component.id,
        onSelect: onSelect as MouseEventHandler,
        arePropertiesValid,
        onChange: noop,
        onPropertyUpdate: handlePropUpdateFromComponent,
    };
    switch (component.type) {
        case ComponentType.CODED:
            return <Coded {...componentProps} {...(component as CodedComponent)} />;

        case ComponentType.UNCODED:
            return <Uncoded {...componentProps} {...(component as UncodedComponent)} />;

        case ComponentType.PANEL:
            return (
                <DroppablePanelComponent
                    component={component as Panel}
                    dragDropEnabled={dragDropEnabled}
                    arePropertiesValid={arePropertiesValid}
                    onSelect={onSelect as CanvasItemClickHandler}
                    isCollapseAll={isCollapseAll}
                    isExpandAll={isExpandAll}
                >
                    <DesignerCanvasColumns
                        component={component as Panel}
                        dragDropEnabled={dragDropEnabled}
                        onItemPropertyUpdate={handlePropUpdateFromComponent}
                        onItemSelect={onSelect}
                        handleItemAddition={noop}
                    />
                </DroppablePanelComponent>
            );

        case ComponentType.TAB_CONTAINER:
            return (
                <TabContainer
                    tabContainer={component as Tab}
                    isSelected={isSelected}
                    dragDropEnabled={dragDropEnabled}
                    arePropertiesValid={arePropertiesValid}
                    onSelect={onSelect as CanvasItemClickHandler}
                    onPropertyUpdate={onPropertyUpdate || (() => {})}
                />
            );

        case ComponentType.CLINICAL_CONTENT:
            return (
                <ClinicalContent
                    clinicalContentComponent={component as ClinicalContentComponent}
                    isSelected={isSelected || (selectedItem as ClinicalContentEntity).componentId === component.id}
                    onSelect={onSelect as MouseEventHandler}
                />
            );

        case ComponentType.NUMERIC_VALUE:
            return <NumericValue {...componentProps} {...(component as NumericValueComponent)} />;

        case ComponentType.PICKING_LIST:
            return <UncodedPickingList {...componentProps} {...(component as PickingListComponent)} />;

        case ComponentType.FREE_TEXT:
            return <FreeText {...componentProps} {...(component as FreeTextComponent)} />;

        case ComponentType.CODED_PICKING_LIST:
            return <CodedPickingList {...componentProps} {...(component as CodedPickingListComponent)} />;

        case ComponentType.DIARY_ENTRY:
            return <DiaryEntry {...componentProps} {...(component as DiaryEntryComponent)} />;

        case ComponentType.CALCULATOR:
            return <Calculator {...componentProps} {...(component as CalculatorComponent)} />;

        case ComponentType.BRANDING:
            return <Branding onSelect={onSelect as MouseEventHandler} component={component as BrandingComponent} />;

        default:
            return null;
    }
};

export default CanvasComponent;
