import { useTranslation } from "@emisgroup/application-intl";
import { ActionData, AuthContext, getGenericDialog } from "@emisgroup/clint-templates-common";
import { InfoConfirm, InfoError, InfoWarning } from "@emisgroup/icons-react";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Dialog } from "@emisgroup/ui-dialog";
import { RadioButton } from "@emisgroup/ui-radio-button";
import React from "react";
import { TestRunModeContext } from "../context/testRunMode";
import exchangeToken from "../utils/exchangeToken";
import "./reportPreviewDialog.css";

export type ReportPreviewDialogProps = {
    hideDialog: () => void;
};

const patientAudience = "patient";
const healthcareAudience = "healthcare";

enum Stage {
    UserInput,
    Loading,
    TransientError,
    PermanentError,
    Success,
}

type UserInputGenerateState = {
    stage: Stage.UserInput;
    audience: string;
};

type LoadingGenerateState = {
    stage: Stage.Loading;
    audience: string;
};

type SuccessfulGenerateState = {
    stage: Stage.Success;
    fileUrl: string;
};

type FailureGenerateState = {
    stage: Stage.PermanentError | Stage.TransientError;
    error: string;
};

type GenerateState = UserInputGenerateState | LoadingGenerateState | SuccessfulGenerateState | FailureGenerateState;

const testDetails = {
    serviceName: "Example Report Preview",
    patientDemographics: {
        firstName: "Micky",
        lastName: "Mouse",
        nhsNumber: "1234567890",
        dateOfBirth: "1928-11-18",
        postcode: "LS1 3BU",
        gender: "Male",
        addressLine1: "5 Example Street",
        mobilePhoneNumber: "4412345678",
        homePhoneNumber: "4412345678",
    },
};

const generatePreview = async (
    state: LoadingGenerateState,
    actionData: ActionData,
    setResult: (result: GenerateState) => void,
    getBearerToken: () => Promise<string>,
) => {
    try {
        if (!actionData.dateCompleted || actionData.items.length === 0) {
            setResult({
                error: "reportPreview.noActionData",
                stage: Stage.PermanentError,
            });
            return;
        }

        const templateToken = await getBearerToken();
        const platformToken = await exchangeToken(templateToken);

        const response = await fetch(process.env.APP_REPORT_PREVIEW_URL ?? "", {
            method: "POST",
            body: JSON.stringify({ ...testDetails, template: actionData, audience: state.audience }),
            headers: { "Content-type": "application/json", Authorization: `Bearer ${platformToken}` },
        });

        if (!response.ok)
            throw new Error(`Failed to get pdf: Status: ${response.status} Status text: ${response.statusText}`);

        setResult({ fileUrl: URL.createObjectURL(await response.blob()), stage: Stage.Success });
    } catch (ex) {
        console.error(ex);
        setResult({ error: "reportPreview.failed", stage: Stage.TransientError });
    }
};

const getTextResult = (icon, text) => {
    return (
        <div className="report-dialog-content">
            {icon}
            <p className="report-dialog-text">{text}</p>
        </div>
    );
};

const getAudienceOptions = (audience, updateAudience, t) => {
    const options = [
        {
            text: t("reportPreview.patientAudience"),
            value: patientAudience,
        },
        {
            text: t("reportPreview.healthcareAudience"),
            value: healthcareAudience,
        },
    ];
    return (
        <>
            <div className="report-dialog-audience">{t("reportPreview.selectAudience")}</div>
            <RadioButton.Group
                ariaLabel="Audiences"
                name="Audiences"
                value={audience}
                onChange={value => updateAudience(value)}
            >
                {options.map(option => {
                    const optionValue = option.value as string;
                    return (
                        <RadioButton
                            key={optionValue}
                            value={optionValue}
                            id={optionValue}
                            className="report-dialog-radio"
                        >
                            {option.text}
                        </RadioButton>
                    );
                })}
            </RadioButton.Group>
        </>
    );
};

const initialState: GenerateState = { stage: Stage.UserInput, audience: patientAudience };

const ReportPreviewDialog = (props: ReportPreviewDialogProps) => {
    const { t } = useTranslation();
    const [state, setState] = React.useState<GenerateState>(initialState);
    const { actionData, updateActionData } = React.useContext(TestRunModeContext);
    const { getBearerToken } = React.useContext(AuthContext);

    React.useEffect(() => {
        if (state.stage === Stage.Loading) generatePreview(state, actionData, setState, getBearerToken);
        if (state.stage === Stage.Success) updateActionData({} as ActionData);
    }, [state]);

    let label;
    let action;
    let content;

    switch (state.stage) {
        case Stage.Loading:
            content = (
                <Dialog open={true} preventBackdropClose={true}>
                    <Dialog.Inner id="spinner-dialog">
                        <ProgressSpinner
                            delay={1}
                            className="spinner"
                            size="x-small"
                            text={t("reportPreview.loading")}
                        />
                    </Dialog.Inner>
                </Dialog>
            );
            break;
        case Stage.Success:
            label = t("open");
            action = () => {
                window.open(state.fileUrl);
                props.hideDialog();
            };
            content = getTextResult(<InfoConfirm size="large" title="success" />, t("reportPreview.success"));
            break;
        case Stage.TransientError:
            label = t("retry");
            action = () => setState(initialState);
            content = getTextResult(<InfoWarning size="large" title="failed" />, t(state.error));
            break;
        case Stage.PermanentError:
            label = t("close");
            action = props.hideDialog;
            content = getTextResult(<InfoError size="large" title="failed" />, t(state.error));
            break;
        case Stage.UserInput:
        default: {
            const updateAudience = audience => setState({ ...state, audience });
            content = getAudienceOptions(state.audience, updateAudience, t);
            label = "Generate";
            action = () => setState({ ...state, stage: Stage.Loading });
            break;
        }
    }

    const buttons = label
        ? [
              {
                  label,
                  ariaLabel: label,
                  onClick: action,
                  isPrimary: true,
              },
          ]
        : [];

    return getGenericDialog(t("reportPreview.title"), buttons, props.hideDialog, content);
};

export default ReportPreviewDialog;
