import React from "react";
import { CanvasItem, ComponentType, ComponentIcon } from "@emisgroup/clint-templates-common";
import "./templateNavigation.css";

type TemplateNavigationProps = {
  item: any;
};

const TemplateNavigation = ({ item }: TemplateNavigationProps) => {
    const { panelName, members } = item;

    const renderIcons = (members: CanvasItem[]) => {
      if (members?.length) {
        return members.slice(0, 2).map(member => (
          <span key={member.id} className="icon">
            <ComponentIcon type={member?.type as ComponentType} />
          </span>
        ));
      }
      return null;
    }

    return (
      <a href={`#${item.id}`} className="sidebar-card" title={panelName || ' '}>
        {renderIcons(members)}
        <span className="content">{panelName || <>&nbsp;</>}</span>
      </a>
    );
};

export default TemplateNavigation;
