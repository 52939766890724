import React from "react";
import { CanvasItem, ConfigContext, PERSPECTIVES } from "@emisgroup/clint-templates-common";
import {
    IPerspective,
    PerspectivesDefinition,
    getPerspectivesQuickPickList,
    getDefaultPredefinedKeys,
    getSelectedQuickPickItems,
    buildPerspectivesKey,
    expandPerspectivesKey,
} from "@emisgroup/clint-templates-perspectives";
import { useTranslation } from "@emisgroup/application-intl";
import { Button } from "@emisgroup/ui-button";
import { Checkbox } from "@emisgroup/ui-checkbox";
import PerspectivesAdvanced from "./perspectivesAdvanced";
import "./perspectives.css";
import { NamedPerspective } from "../utils/perspectiveUtils";

type PropsForPerspectives = {
    editEnabled: boolean;
    selectedCanvasItem: CanvasItem;
    className?: string;
    onUpdate?: (perspectives?: IPerspective[]) => void;
};

const Perspectives = ({ editEnabled, selectedCanvasItem, className, onUpdate = () => ({}) }: PropsForPerspectives) => {
    const { t } = useTranslation();
    const [showAdvanced, setShowAdvanced] = React.useState(false);
    const { features } = React.useContext(ConfigContext);
    const perspectives: PerspectivesDefinition[] = features[PERSPECTIVES];

    const [quickPickDataSource, defaultQuickPicks] = React.useMemo(
        () => [getPerspectivesQuickPickList(t, perspectives), getDefaultPredefinedKeys(perspectives)],
        [],
    );
    const isQuickPick = (key: string) => quickPickDataSource.find(d => d.value === key);
    const isDefaultQuickPicks = (selectedDimensionValues: string[]) =>
        selectedDimensionValues.length === defaultQuickPicks.length &&
        defaultQuickPicks.reduce((result, key) => result && selectedDimensionValues.includes(key), true);

    const selectedQuickPicks = React.useMemo<string[]>(() => {
        return typeof selectedCanvasItem.perspectives !== "undefined"
            ? getSelectedQuickPickItems(perspectives, selectedCanvasItem.perspectives[0].key)
            : defaultQuickPicks;
    }, [selectedCanvasItem.perspectives]);

    const handleQuickPickChange = selectedValues => {
        const existingPerspectives = selectedCanvasItem.perspectives ?? [];
        const existingPerspectiveOverrides = existingPerspectives.length > 0 ? existingPerspectives[0] : {};
        onUpdate(
            isDefaultQuickPicks(selectedValues)
                ? undefined
                : [{ ...existingPerspectiveOverrides, key: buildPerspectivesKey(perspectives, selectedValues) }],
        );
    };

    const currentPerspectives = selectedCanvasItem.perspectives ?? [];
    const hasNonQuickPickKeys = currentPerspectives.some(p =>
        expandPerspectivesKey(perspectives, p.key).some(k => !isQuickPick(k)),
    );
    const advancedPerspectives = currentPerspectives.length > 1 || hasNonQuickPickKeys;

    return (
        <>
            {selectedCanvasItem.id && (
                <div data-testid="perspectives-container">
                    <div data-testid="perspectives" className={`perspectives ${className ?? ""}`}>
                        <div className="sidebar-row-header perspectives__header">{t("perspectives.perspectives")}</div>

                        <Button
                            className="perspectives__advanced"
                            title={t("perspectives.advanced")}
                            data-testId="perspectives-advanced-button"
                            onClick={() => setShowAdvanced(true)}
                        >
                            {t("perspectives.advanced")}
                        </Button>
                        {showAdvanced && (
                            <PerspectivesAdvanced
                                perspectives={(selectedCanvasItem.perspectives ?? []) as NamedPerspective[]}
                                onUpdate={onUpdate}
                                onClose={() => setShowAdvanced(false)}
                            />
                        )}
                    </div>
                    {quickPickDataSource.map(item => (
                        <div
                            key={item.value.toString()}
                            id={`perspectives-${selectedCanvasItem.id}`}
                            className="perspectives-options"
                            data-testId={`option-${item.value}`}
                        >
                            <Checkbox
                                id={item.value.toString()}
                                data-testid={item.value.toString()}
                                checked={selectedQuickPicks.includes(item.value.toString())}
                                disabled={!editEnabled || advancedPerspectives}
                                onChange={checked => {
                                    const newSelectedValues = checked
                                        ? [...selectedQuickPicks, item.value]
                                        : selectedQuickPicks.filter(value => value !== item.value);
                                    handleQuickPickChange(newSelectedValues);
                                }}
                            >
                                {item.text}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Perspectives;
