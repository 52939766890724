import React from "react";
import ReactDOM from "react-dom";
import { config } from "@emisgroup/config-sdk-ts";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import ClinicalCodePicker from "../ClinicalCodePicker";
import { Dialog } from "@emisgroup/ui-dialog";

const APP_EMIS_ID = process.env.APP_EMIS_ID || "";
const CKB_URL = process.env.APP_PICKER_CLINKB_URL || "";
const CKB_REGION = process.env.APP_CLINKB_REGION || "";
const CKB_CODE_SYSTEM = process.env.APP_CLINKB_CODE_SYSTEM || "";
const APP_EMIS_WEB_API_URL = process.env.APP_EMIS_WEB_API_URL || "";

const codePickerContainerElement = document.createElement("div");

const defaultShowPreferredTerm = true;
const defaultShowEmisCodes = true;

type CodePickerConfig = {
    showPreferredTerm?: boolean;
    showEmisCodes?: boolean;
};

type CodePickerConfigCall = {
    isLoading: boolean;
    error?: string;
    pickerConfig?: CodePickerConfig;
};

type CodePickerPortalProps = {
    bearerToken: string;
    suppressPreferredTermAlert?: boolean;
    multiSelection?: boolean;
    onClose: () => void;
    onCodeSelect: (code: any) => void;
};

const CodePickerPortal = ({
    bearerToken,
    suppressPreferredTermAlert,
    multiSelection,
    onCodeSelect,
    onClose,
}: CodePickerPortalProps) => {
    const [codePickerConfig, setCodePickerConfig] = React.useState<CodePickerConfigCall>({ isLoading: true });

    const setConfig = () => {
        const showPreferredTerm =
            !suppressPreferredTermAlert && config.organisation?.codePickerOptions?.showPreferredTerm;
        const showEmisCodes = config.organisation?.codePickerOptions?.showEmisCodes;
        setCodePickerConfig({
            error:
                typeof showPreferredTerm === "undefined" || typeof showEmisCodes === "undefined"
                    ? "error-code:no-org-picker-config"
                    : undefined,
            isLoading: false,
            pickerConfig: { showPreferredTerm, showEmisCodes },
        });
    };

    const setError = () => {
        setCodePickerConfig({
            error: "error-code:config-promise-rejected",
            isLoading: false,
        });
    };

    React.useEffect(() => {
        config.useEmisWeb(APP_EMIS_WEB_API_URL, bearerToken).then(setConfig, setError);
    }, []);

    const loadingDisplay = (
        <Dialog open={true} preventBackdropClose={true}>
            <Dialog.Inner id="spinner-dialog">
                <ProgressSpinner delay={1} className="spinner" size="x-small" />;
            </Dialog.Inner>
        </Dialog>
    );
    const { pickerConfig, error, isLoading } = codePickerConfig;
    if (error) console.warn(error);
    const picker = (
        <>
            {isLoading && loadingDisplay}
            {!isLoading && (
                <React.Suspense fallback={loadingDisplay}>
                    <ClinicalCodePicker
                        displayPicker
                        EMISAppId={APP_EMIS_ID}
                        region={CKB_REGION}
                        codingSystem={CKB_CODE_SYSTEM}
                        highlightInternallyAuthored={pickerConfig?.showEmisCodes ?? defaultShowEmisCodes}
                        ckbURL={CKB_URL}
                        APIToken={bearerToken}
                        onCancel={onClose}
                        onSuccess={onCodeSelect}
                        onHandleError={console.error}
                        suppressPreferredTermAlert={!(pickerConfig?.showPreferredTerm ?? defaultShowPreferredTerm)}
                        multiSelection={multiSelection}
                        onMultiSuccess={multiSelection ? onCodeSelect : undefined}
                    />
                </React.Suspense>
            )}
        </>
    );

    React.useEffect(() => {
        document.body.appendChild(codePickerContainerElement);
        return () => {
            document.body.removeChild(codePickerContainerElement);
        };
    }, []);

    return ReactDOM.createPortal(picker, codePickerContainerElement);
};

export default CodePickerPortal;
