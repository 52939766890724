import { TriangleDown, TriangleUp } from "@emisgroup/icons-react";
import { IdGenerator } from "../../utils/idGenerator";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { IProps } from "@emisgroup/clint-templates-common";
import { IRadioItem } from "../../types";
import { ISubRibbonOptions } from "./ISubRibbonOptions";
import { Ribbon } from "./ribbon";
import { RibbonActionItemLogic } from "./ribbonActionItemLogic";
import { RibbonSublistLogic } from "./ribbonSubListLogic";
import { RadioButton } from "@emisgroup/ui-radio-button";
import "./ribbonLogic.css";

export interface IRibbonProps extends IProps {
    /**
     * List of actions in the Ribbon
     */
    actionList: IRibbonAction[];

    /**
     * Callback function for handling click of action item
     */
    onActionClick?: (selectedAction: string) => void;

    /**
     * Primary action item of the Ribbon.
     */
    primaryAction: IRibbonAction;

    /**
     * Radio button props
     */
    radioButtons?: IRadioButtonProps;

    /**
     * List of action items to be shown in View panel
     */
    viewPanelActions?: IRibbonAction[];
}

/**
 * Represents the action props needed for the Ribbon component
 */
export interface IRibbonAction {
    /**
     * Disables the button if the action is disabled
     */
    disabled?: boolean;

    /**
     * To group the actions
     */
    group?: string;

    /**
     * Action icon name
     */
    iconName: string;

    /**
     * Click handler for the action button click
     */
    onClick?: () => void;

    /**
     * Heading for the list of sub-items (View dropdown only)
     */
    optionsHeading?: string;

    /**
     * List of options to show when clicking a ribbon button
     */
    sublist?: ISubRibbonOptions[];

    /**
     * Action title
     */
    title: string;
}

/**
 * Radio button props
 */
export interface IRadioButtonProps {
    /**
     * Allows the use of the onSelected function
     */
    onSelected?: (selected: string) => void;

    /**
     * Targets array of radio items
     */
    optionArray: IRadioItem[];
}

/**
 * Responsible for rendering the Ribbon component
 *
 * @param props The props passed to the component
 * @returns the Ribbon
 */
export const RibbonLogic: React.FC<IRibbonProps> = (props: IRibbonProps): JSX.Element => {
    const {
        actionList,
        className,
        "data-testid": dataTestId,
        id,
        onActionClick,
        primaryAction,
        radioButtons,
        viewPanelActions,
    } = props;

    const [actionMenuOpen, setActionMenuOpen] = useState(false);
    const [currentlyOpenSublist, setCurrentlyOpenSublist] = useState<string | undefined>(undefined);
    const [wasActionClick, setWasActionClick] = useState<boolean>(false);

    // Ref for the open sublist
    const node: React.RefObject<HTMLDivElement> = useRef(null);
    const topLevelNode = (): HTMLDivElement => node.current as HTMLDivElement;

    // Ref for the action menu (mobile view)
    const liNode: React.RefObject<HTMLLIElement> = useRef(null);
    const topLevelLiNode = (): HTMLLIElement => liNode.current as HTMLLIElement;

    /**
     * Check if the click is outside the sublist
     *
     * @param e The mouse event
     * @param topNode The top LI element
     * @returns Given the topNode, was the next click inside it or it's children?
     */
    const checkClickOutside = (e: MouseEvent, topNode: HTMLDivElement | HTMLLIElement): boolean =>
        e.target instanceof Node && topNode !== null && !topNode.contains(e.target);

    /**
     * Close the sublist
     *
     * @param e Used to get the target of the mouse click to compare with the topNode
     */
    const handleRibbonClick = (e: MouseEvent): void => {
        const topNode: HTMLDivElement = topLevelNode();
        const topLiNode: HTMLLIElement = topLevelLiNode();

        if (actionMenuOpen && !wasActionClick && checkClickOutside(e, topLiNode)) {
            // If the action menu is open and the user clicks outside to close it
            setCurrentlyOpenSublist(undefined);
            setActionMenuOpen(false);
        } else if (!wasActionClick && checkClickOutside(e, topNode)) {
            // If the user clicks outside a sublist
            setCurrentlyOpenSublist(undefined);
        }
        setWasActionClick(false);
    };

    // reactEffect to listen for clicks, and handle them (for clicking off of action menu or sublists)
    useEffect((): any => {
        window.addEventListener("click", handleRibbonClick, true);
        return (): void => {
            // useEffect cleanup
            window.removeEventListener("click", handleRibbonClick, true);
        };
    });

    const actionButtonClasses: string = classNames("eui-ribbon__action-item", "eui-ribbon__action-item--sublist", {
        "eui-ribbon__action-item--sublist-expanded": actionMenuOpen,
    });

    const ribbonClasses: string = classNames("eui-ribbon", className);

    const secondaryActionListClasses: string = classNames("eui-ribbon__list", {
        "eui-ribbon__list--show-on-mobile": actionMenuOpen,
    });

    const actionListUniqueId: string = IdGenerator.generateRandomId("-action-list");

    /**
     * Handling action item click
     *
     * @param selectedAction The name of the selected action
     */
    const actionClicked = (selectedAction: string): void => {
        if (onActionClick) {
            onActionClick(selectedAction);
        }
    };

    /**
     * Get an action button, and style it appropriately
     *
     * @param item The action button
     * @param type The button type
     * @param uniqueId An ID for ARIA properties
     * @returns The action button
     */
    const getActionButton = (item: IRibbonAction, type: string, uniqueId?: string): JSX.Element => {
        // Logic to trigger a call back function, return the clicked button and to show/hide a sublist
        const actionItemAction = (): void => {
            const currentlySelectedAction: string = item.title.toLowerCase();

            // Execute if the action item has an onClick function, otherwise just report back what was clicked
            if (item.onClick) {
                item.onClick();
            } else {
                actionClicked(item.title);
            }

            // If you re-click a ribbon button to close the sublist, do so. Otherwise, show the sublist
            if (currentlySelectedAction === currentlyOpenSublist) {
                setCurrentlyOpenSublist(undefined);
            } else {
                // Sublist logic
                if (type === "primary" && item.sublist) {
                    setActionMenuOpen(false);
                    setCurrentlyOpenSublist(currentlySelectedAction);
                }

                if (type === "secondarySublist") {
                    setCurrentlyOpenSublist(currentlySelectedAction);
                }

                if (type === "viewSublist") {
                    setActionMenuOpen(false);
                    setCurrentlyOpenSublist(currentlySelectedAction);
                }
            }

            // If it's the primary button, set it as such
            if (type === "view" || (type === "primary" && item.sublist === undefined)) {
                setActionMenuOpen(false);
                setCurrentlyOpenSublist(undefined);
            }

            // If it's just a normal secondary button, set it as such
            if (type === "secondary" && !actionMenuOpen) {
                setCurrentlyOpenSublist(undefined);
            }

            setWasActionClick(true);
        };

        // Handle a keyboard key down (Space or Enter) and run the action function
        const onKeyDownHandler = (e: React.KeyboardEvent): void => {
            if (e.key === "Enter" || e.key === " ") {
                actionItemAction();
            }
        };

        // Handle a mouse click and run the action function
        const onClickHandler = (): void => {
            actionItemAction();
        };

        return (
            <RibbonActionItemLogic
                ariaControls={uniqueId}
                disabled={item.disabled || false}
                iconAriaHidden={true}
                iconName={item.iconName}
                iconSize="medium"
                onClick={onClickHandler}
                onKeyDown={onKeyDownHandler}
                showPrimarySublist={type === "primary" && item.sublist && item.sublist.length > 0}
                title={item.title}
                type={type}
            />
        );
    };

    /**
     * Get the sublist component and pass back the clicked button
     *
     * @param dataSource The contents of the sublist array on a ribbon item
     * @param title The title of the sublist button
     * @param type The type of the sublist button
     * @param optionsHeading Optional: The sub-heading used in th view sublist
     * @param uniqueId An ID for ARIA properties
     * @returns  A sublist element
     */
    const getSublist = (
        dataSource: ISubRibbonOptions[],
        title: string,
        type: string,
        optionsHeading?: string,
        uniqueId?: string,
    ): JSX.Element => {
        const lowercaseTitle: string = title.toLowerCase();

        return (
            <RibbonSublistLogic
                ariaId={uniqueId}
                currentlyOpenSublist={currentlyOpenSublist}
                node={node}
                onActionSelect={actionClicked}
                options={dataSource}
                optionsHeading={optionsHeading}
                title={lowercaseTitle}
                type={type}
            />
        );
    };

    /**
     * Return a list of action buttons to populate the ribbon with
     *
     * @param dataSource The list of actions to make into ribbon buttons
     * @param type The button type
     * @returns A usable ribbon button
     */
    const getActionItems = (dataSource: IRibbonAction[], type: string): JSX.Element[] =>
        dataSource.map((item: IRibbonAction, index: number): JSX.Element => {
            const key: string = index + item.title;
            const setMenuToExpanded: boolean = item.title.toLowerCase() === currentlyOpenSublist;

            // If a button has a sublist, change it to type: "xxxSublist", otherwise leave it alone...
            let buttonType: string;
            if (type === "view" && item.sublist) {
                buttonType = "viewSublist";
            } else if (type === "secondary" && item.sublist) {
                buttonType = "secondarySublist";
            } else {
                buttonType = type;
            }

            const liClassNames: string = classNames(
                { "eui-ribbon__item": buttonType === "secondary" || buttonType === "secondarySublist" },
                { "eui-ribbon__view-item": buttonType === "view" || buttonType === "viewSublist" },
                {
                    "eui-ribbon__view-item--sublist": buttonType === "viewSublist",
                },
                {
                    "eui-ribbon__view-item--sublist-expanded": buttonType === "viewSublist" && setMenuToExpanded,
                },
                {
                    "eui-ribbon__item--sublist eui-ribbon__expand-item": buttonType === "secondarySublist",
                },
                {
                    "eui-ribbon__item--sublist-expanded": buttonType === "secondarySublist" && setMenuToExpanded,
                },
            );

            // Generate a unique ID for the ARIA properties on sublist items
            const sublistUniqueId: string = IdGenerator.generateRandomId("-sublist");

            return (
                <li key={key} className={liClassNames}>
                    {getActionButton(item, buttonType, sublistUniqueId)}
                    {item.sublist
                        ? getSublist(item.sublist, item.title, buttonType, item.optionsHeading, sublistUniqueId)
                        : null}
                </li>
            );
        });

    /**
     * Gets the radio buttons for the end view
     *
     * @returns Radio buttons for the end view
     */
    const getRadioButtons = (): JSX.Element | null => {
        if (!radioButtons) {
            return null;
        }
        const selectedOption: IRadioItem | undefined = radioButtons.optionArray.find(
            (option: IRadioItem): boolean | undefined => option.checked,
        );
        return (
            <div className="eui-ribbon__controls">
                <RadioButton.Group
                    className="ribbon-radio"
                    name={"Ribbon-Radios"}
                    value={selectedOption ? String(selectedOption.value) : undefined}
                    onChange={(selected: any) => actionClicked(selected)}
                >
                    {radioButtons.optionArray.map(option => {
                        const optionValue = option.value as string;
                        return (
                            <RadioButton key={optionValue} value={optionValue} id={optionValue}>
                                {option.text}
                            </RadioButton>
                        );
                    })}
                </RadioButton.Group>
            </div>
        );
    };

    /**
     * Get the end view items for the ribbon
     *
     * @returns The right hand side (end) of the ribbon
     */
    const getEndView = (): JSX.Element | null => {
        if (viewPanelActions) {
            return (
                <div className="eui-ribbon__end">
                    {getRadioButtons()}
                    <ul className="eui-ribbon__view">{getActionItems(viewPanelActions, "view")}</ul>
                </div>
            );
        }
        return null;
    };

    /**
     * Get the primary items for the ribbon
     *
     * @param primaryItem
     * @returns The primary items of the ribbon
     */
    const getPrimaryActions = (primaryItem: IRibbonAction): JSX.Element => {
        const classes: string = classNames("eui-ribbon__primary-options", {
            "eui-ribbon__primary-options--expanded": currentlyOpenSublist === "add",
        });
        return (
            <div className={classes}>
                {getActionButton(primaryItem, "primary")}
                {getSublist(
                    primaryItem.sublist && primaryItem.sublist.length > 0 ? primaryItem.sublist : [],
                    primaryItem.title,
                    "add",
                    primaryItem.optionsHeading,
                )}
            </div>
        );
    };

    /**
     * Get the group items
     *
     * @param dataSource The actions to be used in this group
     * @param group The name of this group
     * @param key The key for this group
     * @returns A group element
     */
    const getGroupElement = (dataSource: IRibbonAction[], group: string, key: string): JSX.Element => (
        <li key={key} aria-label={group} className="eui-ribbon__group eui-ribbon__group--right">
            <ul className="eui-ribbon__group-items">{getActionItems(dataSource, "secondary")}</ul>
        </li>
    );

    /**
     * Get the group items
     *
     * @param dataSource The actions to use in this group
     * @param groups The name(s) of the group(s)
     * @returns Returns a group element for the ribbon
     */
    const getGroupItems = (dataSource: IRibbonAction[], groups: string[]): JSX.Element[] =>
        groups.map((group: string, index: number): JSX.Element => {
            let dataList: IRibbonAction[];
            const key: string = group + index;

            if (group.trim() === "") {
                dataList = dataSource.filter((item: IRibbonAction): boolean => !item.group || item.group.trim() === "");
                return getGroupElement(dataList, "Ungrouped", key);
            }
            dataList = dataSource.filter((item: IRibbonAction): boolean => item.group === group);
            return getGroupElement(dataList, group, key);
        });

    /**
     * Get the secondary action items
     *
     * @returns A collection of all action items after the primary button
     */
    const getSecondaryActions = (): JSX.Element[] => {
        const groupArray: string[] = actionList.map((e: IRibbonAction): string =>
            e.group && e.group.trim() !== "" ? e.group : "",
        );
        const groups: string[] = [...new Set(groupArray)];
        const noGroupIndex: number = groups.indexOf("");

        // To push all the ungrouped items to the end of the list
        if (noGroupIndex > -1) {
            groups.splice(noGroupIndex, 1);
            groups.push("");
        }

        if (groups.length > 1) {
            // Has more than one group
            return getGroupItems(actionList, groups);
        }
        return getActionItems(actionList, "secondary");
    };

    /**
     * Get the icon for the action button
     *
     * @returns The icon for the action button
     */
    const getActionIcon = (): JSX.Element => {
        const iconClassName: string = "eui-ribbon__action-icon eui-ribbon__action-icon--show";

        if (actionMenuOpen) {
            return (
                <TriangleUp
                    ariaHidden={false}
                    className={iconClassName}
                    color="primary"
                    size="medium"
                    title="Collapse"
                />
            );
        }
        return (
            <TriangleDown ariaHidden={false} className={iconClassName} color="primary" size="medium" title="Expand" />
        );
    };

    /**
     * Handling click on Actions
     */
    const toggleActionsEvent = (): void => {
        setActionMenuOpen(!actionMenuOpen);
        if (onActionClick) {
            onActionClick("Actions");
        }
    };

    return (
        <Ribbon
            actionButtonClasses={actionButtonClasses}
            actionIcon={getActionIcon()}
            actionListUniqueId={actionListUniqueId}
            actionMenuOpen={actionMenuOpen}
            data-testid={dataTestId}
            endView={getEndView}
            id={id}
            node={liNode}
            primaryActionButton={
                primaryAction && primaryAction.sublist
                    ? getPrimaryActions(primaryAction)
                    : getActionButton(primaryAction, "primary")
            }
            ribbonClasses={ribbonClasses}
            secondaryActionListClasses={secondaryActionListClasses}
            secondaryActions={getSecondaryActions()}
            toggleActionMenu={toggleActionsEvent}
        />
    );
};
