import React from "react";
import { Trans } from "react-i18next";
import { Banner } from "@emisgroup/ui-banner";
import { Dialog } from "@emisgroup/ui-dialog";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import {
    CodedComponent,
    ComponentType,
    getCanvasItemsWithNestedMembers,
    ComponentContainer,
    RunningTemplateContext,
    PatientDataContext,
    ComponentConfigDataContext,
    TemplateHistoryContext,
    useCanvasSizeClass,
    CodedDataItem,
    CodedPickingListComponent,
    Code,
    DiaryEntryComponent,
    CalculatorComponent,
    Panel,
} from "@emisgroup/clint-templates-common";
import { ConfidentialLock } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";

import { getConfigDataForCodedComponents } from "./queries/clinicalCodeConfig";
import getConfigComponentWarning from "./utils/getConfigComponentWarning";
import VisibilityRulesErrorAlert from "./visibilityRulesErrorAlert";
import useRuleEvaluation from "./useRuleEvaluation";
import { RunningContainerProps } from "./types";

import "@emisgroup/clint-templates-common/lib/appCanvas.scss";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-over-1280.css";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-800-1280.css";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-630-800.css";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-under-630.css";

type CanvasProps = {
    runningContainer: (props: RunningContainerProps) => JSX.Element | null;
    templateDefinition: ComponentContainer;
    queryApiUrl: string;
    ckbUrl: string;
    getBearerToken: () => Promise<string>;
    cancelTemplate: () => void;
    className?: string;
    showTitle?: boolean;
};

const Canvas = ({
    runningContainer,
    templateDefinition,
    queryApiUrl,
    ckbUrl,
    getBearerToken,
    cancelTemplate,
    className,
    showTitle = false,
}: CanvasProps) => {
    const { t } = useTranslation();
    const { isWaitingForPatientSelection, isInitialising } = React.useContext(PatientDataContext);
    const { evaluate, itemsToRender, visibilityRulesError, clearVisibilityRulesErrors } = useRuleEvaluation({
        templateDefinition,
        queryApiUrl,
        getBearerToken,
    });

    const { templateData } = React.useContext(RunningTemplateContext);
    const { areConfidentialItemsRemoved, updateHistory } = React.useContext(TemplateHistoryContext);
    const { error: componentsConfigError, setComponentsConfig } = React.useContext(ComponentConfigDataContext);
    const canvasRef = React.useRef<HTMLDivElement>(null);
    const canvasSizeClass = useCanvasSizeClass(() => canvasRef.current as HTMLDivElement);

    const canvasItemsWithNestedMembers = React.useMemo(() => {
        return getCanvasItemsWithNestedMembers(templateDefinition.members, templateDefinition);
    }, [templateDefinition.members, templateDefinition]);

    const codeIdAsText = (code?: Code) => (code ? code.emisCodeId.toString() : "");
    const codesFromTemplateItems = canvasItemsWithNestedMembers
        .filter(
            item =>
                item.type === ComponentType.CODED ||
                item.type === ComponentType.CODED_PICKING_LIST ||
                item.type === ComponentType.DIARY_ENTRY ||
                item.type === ComponentType.CALCULATOR,
        )
        .flatMap(item => {
            switch (item.type) {
                case ComponentType.CODED:
                    return [codeIdAsText((item as CodedComponent).code)];
                case ComponentType.CODED_PICKING_LIST:
                    return (item as CodedPickingListComponent).codes.map(codeIdAsText);
                case ComponentType.DIARY_ENTRY:
                    return [codeIdAsText((item as DiaryEntryComponent).code)];
                case ComponentType.CALCULATOR:
                    return [codeIdAsText((item as CalculatorComponent).code)];
                default:
                    return "";
            }
        })
        .filter(Boolean);

    const hasCustomActionButtons = canvasItemsWithNestedMembers.some(
        item => item.type === ComponentType.PANEL && (item as Panel).actionButtons,
    );

    const codesFromTemplateData = React.useMemo(() => {
        return Object.entries(templateData).reduce((finalList, [, componentData]) => {
            if (componentData.items) {
                const selectedItems = componentData.items
                    ?.filter(item => item.selected && (item as CodedDataItem).code)
                    .map(item => item as CodedDataItem)
                    .map(({ code }) => code.emisCodeId.toString());

                return selectedItems ? [...finalList, ...selectedItems] : finalList;
            }
            return finalList;
        }, [] as string[]);
    }, [templateData]);

    const componentCodes = React.useMemo(
        () => [...codesFromTemplateItems, ...codesFromTemplateData],
        [codesFromTemplateItems, codesFromTemplateData],
    );

    async function getComponentConfigData() {
        const bearerToken = await getBearerToken();
        if (componentCodes.length) {
            const configData = await getConfigDataForCodedComponents(ckbUrl, bearerToken, componentCodes);
            setComponentsConfig(configData.data, configData.error);
        }
    }

    const warningDialog = getConfigComponentWarning({
        retry: getComponentConfigData,
        cancel: cancelTemplate,
        errorCode: componentsConfigError ?? "",
    });

    React.useEffect(() => {
        if (!isWaitingForPatientSelection && !isInitialising) {
            evaluate();
        }
    }, [isWaitingForPatientSelection, isInitialising, evaluate]);

    React.useEffect(() => {
        getComponentConfigData();
    }, [JSON.stringify(componentCodes)]);

    React.useEffect(() => {
        updateHistory(itemsToRender);
    }, [itemsToRender]);

    const canvasClass = `canvas run-mode ${className || ""} col-12`;

    if (isWaitingForPatientSelection) {
        return (
            <div data-testid="app-canvas" className={canvasClass} ref={canvasRef}>
                <div className="select-patient-message">
                    <h3>{t("canvas.selectPatientToRun")}</h3>
                </div>
            </div>
        );
    }

    if (isInitialising) {
        return (
            <div data-testid="app-canvas" className={canvasClass} ref={canvasRef}>
                <Dialog open={true} preventBackdropClose={true}>
                    <Dialog.Inner id="spinner-dialog">
                        <ProgressSpinner delay={1} className="spinner" size="small" text={t("canvas.initialising")} />
                    </Dialog.Inner>
                </Dialog>
            </div>
        );
    }

    const visiblityRulesAppliedTemplate = { ...templateDefinition, members: itemsToRender };

    if (visibilityRulesError) {
        const retry = () => {
            clearVisibilityRulesErrors();
            evaluate();
        };
        return (
            <div role="none" data-testid="app-canvas" className={canvasClass} ref={canvasRef}>
                <VisibilityRulesErrorAlert cancel={cancelTemplate} retry={retry} />
            </div>
        );
    }

    const RunningContainer = runningContainer;
    return (
        <div role="none" data-testid="app-canvas" className={canvasClass} ref={canvasRef}>
            {componentsConfigError && warningDialog}
            {showTitle && (
                <div role="none" data-testid="app-canvas-title" className="canvas-title">
                    {templateDefinition.label}
                </div>
            )}
            {areConfidentialItemsRemoved && (
                <Banner color="warning" data-testid="app-canvas-warning" className="property-warning banner-warning">
                    <Banner.Cap>
                        <ConfidentialLock title="" />
                    </Banner.Cap>
                    <Banner.Content vertical={true}>
                        <Trans i18nKey="canvas.confidentialityWarning" components={{ bold: <strong /> }} />
                    </Banner.Content>
                </Banner>
            )}
            <RunningContainer
                className={canvasSizeClass}
                columnCount={templateDefinition.columnCount}
                container={visiblityRulesAppliedTemplate}
                hasCustomActionButtons={hasCustomActionButtons}
            />
        </div>
    );
};

export default Canvas;
