import React, { FC, useState } from "react";
import { IRadioItem } from "../../types";
import { ISubRibbonOptions } from "./ISubRibbonOptions";
import { RibbonSubList } from "./ribbonSubList";
import { RadioButton } from "@emisgroup/ui-radio-button";
import { RibbonSublistItemsLogic } from "./ribbonSubListItemsLogic";

interface IRibbonSublistProps {
    /**
     * The ARIA ID being uses for this component
     */
    ariaId?: string;

    /**
     * Name of the currently open sublist
     */
    currentlyOpenSublist: string | undefined;

    /**
     * Node reference object
     */
    node: React.RefObject<HTMLDivElement>;

    /**
     * Callback function for handling what sublist button was clicked
     */
    onActionSelect: (selectedAction: string) => void;

    /**
     * The list of options in the sublist
     */
    options: ISubRibbonOptions[];

    /**
     * Heading for the list of sub-items (View dropdown only)
     */
    optionsHeading?: string;

    /**
     * Action title
     */
    title: string;

    /**
     * The type of button that was clicked
     */
    type: string;
}

/**
 * Responsible for rendering the Ribbon sublist
 *
 * @param props The props passed to the component
 * @returns the RibbonSublist element
 */
export const RibbonSublistLogic: FC<IRibbonSublistProps> = (props: IRibbonSublistProps): JSX.Element | null => {
    const { ariaId, currentlyOpenSublist, node, onActionSelect, options, optionsHeading, title, type } = props;

    const [sublistContentAsRadio, setSublistContentAsRadio] = useState(false);

    const actionClicked = (selectedAction: IRadioItem): void => {
        // Pass back the selected action button title
        onActionSelect(selectedAction.value.toString());
    };

    const getSublistContent = (isRadios: boolean): JSX.Element => {
        if (isRadios) {
            // If we have radios, render them as such
            return (
                <RadioButton.Group name="view-radio" onChange={e => actionClicked(e)} value={title}>
                    {(options as IRadioItem[]).map((option: IRadioItem) => (
                        <RadioButton value={option.value.toString()} key={option.value.toString()}>
                            {option.text}
                        </RadioButton>
                    ))}
                </RadioButton.Group>
            );
        }

        // Anything else, we just get the items and let it deal with the render
        return <RibbonSublistItemsLogic onActionSelect={onActionSelect} options={options} type="sublistButton" />;
    };

    /**
     * Check to see if the sublist content is radio buttons or not
     *
     * @returns The contents of the sublist
     */
    const checkSublistContent = (): JSX.Element => {
        // Dirty forEach to see if the type is of radio
        options.forEach((option: ISubRibbonOptions): void => {
            if (option.value) {
                setSublistContentAsRadio(true);
            }
        });

        return getSublistContent(sublistContentAsRadio);
    };

    if (currentlyOpenSublist === title) {
        return (
            <RibbonSubList
                ariaId={ariaId}
                getSublistContent={checkSublistContent}
                node={node}
                optionsHeading={optionsHeading}
                type={type}
            />
        );
    }

    return null;
};
