import React from "react";
import { RadioButton } from "@emisgroup/ui-radio-button";
import { ConfidentialLock, TriangleDown } from "@emisgroup/icons-react";
import {
    CanvasItem,
    Rule,
    ConditionOperator,
    Condition,
    ComponentCondition,
    findCanvasItem,
    TemplateContext,
    QueryCondition,
    ConditionSource,
    AuthContext,
    ConfigContext,
    ConditionSourceType,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import "./visibilityRules.css";
import { QUERY_RESOURCE_TYPE } from "@emisgroup/clint-content/lib/constants";
import { ContentLibraryItem } from "@emisgroup/content-picker/lib/types";
import { ContentPicker } from "@emisgroup/content-picker";
import VisibilityRulesList from "./visibilityRulesList";
import { getAvailableRuleComponentStates, getRuleConditionSourcesForItem } from "../utils/componentUtils";
import { canvasItemCanHaveVisibilityRules, getConditionSourceType, getConditionWithNewState } from "../utils/ruleUtils";
import ComponentSelectorDialog from "./componentSelectorDialog";
import { IRadioItem } from "../types";
import { createVisibilityRulesComponentTree } from "../utils/componentSelectorUtils";
import { Button } from "@emisgroup/ui-button";
import { DropdownMenu } from "@emisgroup/ui-dropdown-menu";
import { ListItem } from "@emisgroup/ui-list";

enum DialogBoxType {
    SELECTING_QUERY,
    SELECTING_COMPONENT,
}

type VisibilityDialogState = {
    dialogBoxType?: DialogBoxType;
    conditionIndex?: number;
};

type PropsForVisibilityRules = {
    show: boolean;
    editEnabled: boolean;
    selectedCanvasItem: CanvasItem;
    disallowedItems?: CanvasItem[];
    invalidRuleConditions?: any;
    onRuleAddOrUpdate?: (rule: Rule | null) => void;
    onUpdateRuleConditionValidity?: (conditionIndex: number) => void;
};

const VisibilityRules = ({
    show,
    editEnabled,
    selectedCanvasItem,
    disallowedItems,
    invalidRuleConditions,
    onRuleAddOrUpdate = () => ({}),
    onUpdateRuleConditionValidity = () => ({}),
}: PropsForVisibilityRules) => {
    const { t } = useTranslation();
    const { getBearerToken } = React.useContext(AuthContext);
    const { contentLibraryUrl } = React.useContext(ConfigContext);
    const { templateDefinition } = React.useContext(TemplateContext);
    const [visibilityDialog, setVisibilityDialog] = React.useState<VisibilityDialogState>({});
    const [addedConditionIndex, setAddedConditionIndex] = React.useState<number>();
    const visibilityRuleDivRef = React.useRef<HTMLDivElement>(null);
    const [splitDropdownMenuOpen, setSplitDropdownMenuOpen] = React.useState(false);
    const rule = selectedCanvasItem.rule ?? {
        conditionOperator: ConditionOperator.ALL,
        conditionMembers: [],
    };
    const [selectedConditionOperator, setSelectedConditionOperator] = React.useState<ConditionOperator>();

    const canEditRules = editEnabled && (canvasItemCanHaveVisibilityRules(selectedCanvasItem) ?? false);

    const conditionOperatorOptions: IRadioItem[] = [
        {
            text: t("templates.rules.allRules"),
            value: ConditionOperator.ALL,
            disabled: !canEditRules,
        },
        {
            text: t("templates.rules.anyRules"),
            value: ConditionOperator.ANY,
            disabled: !canEditRules,
        },
    ];

    const updateCondition = (conditionIndex: number, updatedCondition: Condition) => {
        const newRuleAfterUpdate: Rule = {
            ...rule,
            conditionMembers: rule.conditionMembers.map((conditionMember, index) =>
                conditionIndex === index ? updatedCondition : conditionMember,
            ),
        };

        onRuleAddOrUpdate(newRuleAfterUpdate);
        onUpdateRuleConditionValidity(conditionIndex);
    };

    const deleteCondition = (conditionIndex: number) => {
        const newConditionMembers = rule.conditionMembers.filter((_, index) => index !== conditionIndex);
        const newRuleAfterDeletion =
            newConditionMembers.length > 0
                ? {
                      ...rule,
                      conditionOperator:
                          newConditionMembers.length > 1 ? rule.conditionOperator : ConditionOperator.ALL,
                      conditionMembers: newConditionMembers,
                  }
                : null;

        onRuleAddOrUpdate(newRuleAfterDeletion);
    };

    const selectNewSource = (conditionIndex: number) => {
        const condition: Condition = rule.conditionMembers[conditionIndex] as Condition;
        const dialogBoxType =
            getConditionSourceType(condition) === ConditionSourceType.QUERY
                ? DialogBoxType.SELECTING_QUERY
                : DialogBoxType.SELECTING_COMPONENT;

        setVisibilityDialog({ dialogBoxType, conditionIndex });
    };

    const updateConditionOperator = radioItem => {
        const conditionOperator = radioItem;
        const newRuleAfterUpdate: Rule = {
            ...rule,
            conditionOperator,
        };

        setSelectedConditionOperator(newRuleAfterUpdate.conditionOperator);
        onRuleAddOrUpdate(newRuleAfterUpdate);
    };

    const addComponentCondition = (actorCanvasItemId: string) => {
        const actorCanvasItem = findCanvasItem(actorCanvasItemId, templateDefinition.members);

        const defaultConditionSourceForItem = getRuleConditionSourcesForItem(actorCanvasItem)[0];

        const condition: ComponentCondition = {
            conditionSource: defaultConditionSourceForItem,
            actorCanvasItemId,
        };

        const defaultComponentState = getAvailableRuleComponentStates(t, actorCanvasItem)[0].value;

        const newComponentCondition: Condition = getConditionWithNewState(condition, defaultComponentState);

        if (typeof visibilityDialog.conditionIndex !== "undefined") {
            updateCondition(visibilityDialog.conditionIndex, newComponentCondition);
        } else {
            const ruleWithNewCondition: Rule = {
                ...rule,
                conditionMembers: rule.conditionMembers.concat(newComponentCondition),
            };
            setAddedConditionIndex(ruleWithNewCondition.conditionMembers.length - 1);
            onRuleAddOrUpdate(ruleWithNewCondition);
        }

        setVisibilityDialog({});
    };

    const addQueryCondition = (queryItem: ContentLibraryItem) => {
        const queryCondition: QueryCondition = {
            conditionSource: ConditionSource.QUERY,
            queryId: queryItem.ern.replace(/emis:resource:/, ""),
            queryName: queryItem.name,
        };

        if (typeof visibilityDialog.conditionIndex !== "undefined") {
            updateCondition(visibilityDialog.conditionIndex, queryCondition);
            return;
        }

        const ruleWithNewCondition: Rule = {
            ...rule,
            conditionMembers: rule.conditionMembers.concat(queryCondition),
        };

        setAddedConditionIndex(ruleWithNewCondition.conditionMembers.length - 1);
        onRuleAddOrUpdate(ruleWithNewCondition);
    };

    const visibilityRuleData = [
        {
            action: () => setVisibilityDialog({ dialogBoxType: DialogBoxType.SELECTING_COMPONENT }),
            text: t("templates.rules.componentOnThisPage"),
        },
        {
            action: () => setVisibilityDialog({ dialogBoxType: DialogBoxType.SELECTING_QUERY }),
            text: t("templates.rules.queryFromContentLibrary"),
        },
    ];

    const addRuleButton = (
        <DropdownMenu>
            <DropdownMenu.Trigger>
                <Button
                    aria-label={t("templates.rules.addVisibilityRule")}
                    className="visibility-rules-list_dropdown"
                    data-testid="add-visibility-rule"
                    onClick={() => setSplitDropdownMenuOpen(true)}
                    variant="filled"
                >
                    {t("templates.rules.addVisibilityRule")}
                    <TriangleDown title="" />
                </Button>
            </DropdownMenu.Trigger>
            {splitDropdownMenuOpen && (
                <DropdownMenu.Content align="center" id="buttondropdown-content">
                    {visibilityRuleData.map((item, index) => (
                        <DropdownMenu.Item
                            key={index}
                            onClick={() => {
                                setTimeout(() => {
                                    // time out added to avoid JS injection clash of pointer event between closing dialog and opening new dialog.
                                    item.action();
                                }, 0);
                            }}
                            id="buttondropdown-menu"
                        >
                            <ListItem className="buttondropdown-list">{item.text}</ListItem>
                        </DropdownMenu.Item>
                    ))}
                </DropdownMenu.Content>
            )}
        </DropdownMenu>
    );

    const handleBlur = (e: React.FocusEvent) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
            e.stopPropagation();
        } else {
            onRuleAddOrUpdate(rule);
            setAddedConditionIndex(undefined);
        }
    };

    React.useEffect(() => {
        if (typeof addedConditionIndex !== "undefined" && visibilityRuleDivRef.current?.scrollIntoView) {
            visibilityRuleDivRef.current.scrollIntoView();
        }
    }, [addedConditionIndex]);

    const selectedConditionActorId =
        visibilityDialog?.dialogBoxType === DialogBoxType.SELECTING_COMPONENT &&
        typeof visibilityDialog.conditionIndex !== "undefined"
            ? (rule.conditionMembers[visibilityDialog.conditionIndex] as ComponentCondition).actorCanvasItemId
            : undefined;

    return (
        <div
            className={`sidebar-right visibility-rules ${show ? "" : "hidden"}`}
            data-testid="visibility-rules-panel"
            onBlur={handleBlur}
            ref={visibilityRuleDivRef}
        >
            <div className="sidebar-row sidebar-row-header">
                {t("templates.rules.visibilityRules")}
                {!editEnabled && (
                    <ConfidentialLock title={t("templates.rules.visibilityRulesLocked")} className="panel-lock" />
                )}
            </div>

            {selectedCanvasItem.id && (
                <div className="visibility-rules-display">
                    <VisibilityRulesList
                        canvasItemLabel={selectedCanvasItem.label}
                        rule={rule}
                        invalidRuleConditions={invalidRuleConditions}
                        canEditRules={canEditRules}
                        onDelete={deleteCondition}
                        onUpdate={updateCondition}
                        onSelectNewSource={selectNewSource}
                    />

                    {rule.conditionMembers.length > 1 && (
                        <>
                            <div
                                data-testid="conditionJoinSelect"
                                className="sidebar-row visibility-rules-list__join-select"
                            >
                                <div className="visibility-rules-list__join-select__with">
                                    {t("templates.rules.with")}
                                </div>
                                <RadioButton.Group
                                    ariaLabel="conditionOperator"
                                    name="with"
                                    value={selectedConditionOperator}
                                    onChange={updateConditionOperator}
                                    id="visibility-rules-radio-group"
                                >
                                    {conditionOperatorOptions.map(option => {
                                        const optionValue = option.value as string;
                                        return (
                                            <RadioButton
                                                key={optionValue}
                                                value={optionValue}
                                                id={optionValue}
                                                className="visibility-rules-radio"
                                            >
                                                {option.text}
                                            </RadioButton>
                                        );
                                    })}
                                </RadioButton.Group>
                            </div>
                        </>
                    )}

                    {canEditRules && addRuleButton}
                </div>
            )}
            {visibilityDialog.dialogBoxType === DialogBoxType.SELECTING_QUERY && (
                <div data-testid="query-picker-dialog">
                    <ContentPicker
                        resourceTypes={[QUERY_RESOURCE_TYPE]}
                        contentLibraryUrl={contentLibraryUrl}
                        getBearerToken={getBearerToken}
                        onClose={() => {
                            setVisibilityDialog({});
                        }}
                        onError={() => setVisibilityDialog({})}
                        onLoaded={(queryItem: ContentLibraryItem) => {
                            addQueryCondition(queryItem);
                            setVisibilityDialog({});
                        }}
                        onlyLoadIdentifier={true}
                        showInDialog={true}
                    />
                </div>
            )}
            {visibilityDialog.dialogBoxType === DialogBoxType.SELECTING_COMPONENT && (
                <ComponentSelectorDialog
                    componentsTreeRootNode={createVisibilityRulesComponentTree(
                        t,
                        templateDefinition,
                        selectedCanvasItem.id,
                        disallowedItems?.map(item => item.id),
                    )}
                    showComponentId={selectedConditionActorId || selectedCanvasItem.id}
                    selectedComponentId={selectedConditionActorId}
                    onCancel={() => setVisibilityDialog({})}
                    onSelect={id => {
                        addComponentCondition(id);
                    }}
                />
            )}
        </div>
    );
};

export default VisibilityRules;
