import classNames from "classnames";
import React, { FC } from "react";
import { IconSize } from "@emisgroup/icons-react";
import { RibbonActionItem } from "./ribbonActionItem";
import { IKeyboardProps } from "@emisgroup/clint-templates-common";

interface IRibbonActionItemProps extends IKeyboardProps {
    /**
     * The ARIA ID being uses for this component
     */
    ariaControls?: string;

    /**
     * Set the item to disabled
     */
    disabled: boolean;

    /**
     * Set if the icon is hidden or not
     */
    iconAriaHidden?: boolean;

    /**
     * The icon name
     */
    iconName: string;

    /**
     * The size of the icon
     */
    iconSize?: IconSize;

    /**
     * The button click callback
     */
    onClick: () => void;

    /**
     * Boolean to show primary sublist
     */
    showPrimarySublist?: boolean;

    /**
     * The title of the button
     */
    title: string;

    /**
     * The type of icon
     */
    type: string;
}

/**
 * Responsible for rendering the Ribbon action item
 *
 * @param props The props passed to the component
 * @returns the RibbonActionItem
 */
export const RibbonActionItemLogic: FC<IRibbonActionItemProps> = (props: IRibbonActionItemProps): JSX.Element => {
    const {
        ariaControls,
        disabled,
        iconAriaHidden,
        iconName,
        iconSize,
        onClick,
        onKeyDown,
        showPrimarySublist,
        title,
        type,
    } = props;

    const actionIconclass: string = classNames("eui-ribbon__button-icon", {
        "eui-ribbon__button-icon--responsive": type !== "primary" && type !== "viewSublist",
    });

    const actionListclass: string = classNames(
        "eui-ribbon__button",
        { "eui-ribbon__button--filled": type === "primary" },
        { "eui-ribbon__button--sublist": showPrimarySublist },
        { "eui-ribbon__button--responsive": type === "secondary" || type === "secondarySublist" },
        { "eui-ribbon__button--sublist": type === "viewSublist" || type === "secondarySublist" },
    );

    return (
        <RibbonActionItem
            ariaControls={ariaControls}
            ariaHidden={iconAriaHidden || false}
            buttonClasses={actionListclass}
            buttonDisabled={disabled}
            iconClasses={actionIconclass}
            iconName={iconName}
            iconSize={iconSize}
            onClickHandler={onClick}
            onKeyHandler={onKeyDown}
            showPrimarySublist={showPrimarySublist}
            title={title}
            type={type}
        />
    );
};
