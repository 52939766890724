import { Checkbox } from "@emisgroup/ui-checkbox";
import { IListItem } from "@emisgroup/clint-templates-perspectives";

type MultiSelectOptionProps = {
    isSelected: boolean;
    item: IListItem;
    onSelectionChange: (option: IListItem, checked: boolean) => void;
    "data-testid"?: string;
};

const MultiSelectOption = (props: MultiSelectOptionProps) => {
    const { isSelected, item, onSelectionChange, "data-testid": dataTestId } = props;

    const onChange = checked => {
        if (!item.disabled) {
            onSelectionChange(item, checked);
        }
    };

    return (
        <Checkbox data-testid={dataTestId} onChange={onChange} checked={isSelected} disabled={item.disabled}>
            {item.text}
        </Checkbox>
    );
};

export default MultiSelectOption;
