import React from "react";
import { Input } from "@emisgroup/ui-input";
import {
    ListQualifiers,
    QualifierData,
    ValueQualifiers,
} from "@emisgroup/clinical-code-entry/lib/qualifiersEntry/types";
import { QualifiersEntry, NumericEntry } from "@emisgroup/clinical-code-entry";
import { ErrorHandling, ErrorState, Validity } from "@emisgroup/clinical-code-entry/lib/types";
import { NumericData } from "@emisgroup/clinical-code-entry/lib/numericEntry/types";
import { AppMode, DataFromTemplateRun, DataEntryComponentProps, Code } from "../types";
import AppModeContext from "../context/appMode";
import { ComponentConfigData, ComponentConfigDataContext, RunningTemplateContext } from "../context";
import Checkbox from "./controlledCheckbox";
import DataEntryComponent from "./dataEntryComponent";
import Problem from "./problem";
import "./coded.css";
import { CATEGORY_PROBLEM } from "../constants";

const isProblem = (category: string) => category === CATEGORY_PROBLEM;

export type CodeEntryProps = DataEntryComponentProps & {
    isChecked: boolean;
    value?: any;
    code: Code;
    category?: string;
    showAllQualifiers: boolean;
    qualifierData: QualifierData;
    disallowSelection?: boolean;
    excludeHistory?: boolean;
    onCodeChange: (value: any, validity?: Validity) => void;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
};

const checkRunMode = (): boolean => {
    const { mode } = React.useContext(AppModeContext);
    return mode === AppMode.RUN;
};

const getComponentConfigData = (componentsConfig: ComponentConfigData[], code: Code, isInRunMode: boolean) =>
    React.useMemo(() => {
        if (!isInRunMode) {
            return null;
        }
        return componentsConfig && componentsConfig.length
            ? componentsConfig.filter(dataForComponent => dataForComponent.id === `${code.emisCodeId}`)[0]
            : null;
    }, [componentsConfig, isInRunMode]);

const extractComponentConfigData = (componentConfigData: ComponentConfigData | null): [any, boolean] =>
    React.useMemo(() => {
        const qualifiersFromComponent = componentConfigData?.attributes?.qualifiers;
        const compound = qualifiersFromComponent?.compound as any;
        let qualifiers: any[];
        if (compound) {
            qualifiers = compound[0].components.map(c => ({ ...c.code.qualifiers.numeric, name: c.code.term }));
        } else {
            const numeric = qualifiersFromComponent?.numeric;
            qualifiers = numeric ? [numeric] : [];
        }

        return [qualifiers, componentConfigData?.attributes?.allowFutureDate ?? false];
    }, [componentConfigData]);

const isValueSelected = (isNumericCode: boolean, value: any, isChecked: boolean): boolean => {
    return (isNumericCode && typeof value !== "undefined") || (!isNumericCode && isChecked);
};

const canShowQualifiers = (
    isSelected: boolean,
    qualifierList: ListQualifiers,
    valueQualifier: ValueQualifiers,
): boolean => {
    return isSelected && ((qualifierList && qualifierList.length > 0) || (valueQualifier && valueQualifier.length > 0));
};

export const CodeEntry = (props: CodeEntryProps) => {
    const {
        id,
        label,
        code,
        showAllQualifiers,
        qualifierData,
        disallowSelection,
        isChecked,
        value,
        onCodeChange,
        onHandleError,
        isMandatory = false,
        category = "",
    } = props;
    const componentId = `component-${id}`;
    const { componentsConfig } = React.useContext(ComponentConfigDataContext);
    const [listQualifiers, setListQualifiers] = React.useState<ListQualifiers>([]);
    const [valueQualifiers, setValueQualifiers] = React.useState<ValueQualifiers>([]);
    const [showAsValid, setShowAsValid] = React.useState<boolean>(true);

    const isInRunMode = checkRunMode();

    const isCheckboxValid = (checked: boolean) => !isInRunMode || !isMandatory || checked;

    const handleCheckboxBlur = () => {
        onCodeChange({ interacted: true });
        setShowAsValid(isCheckboxValid(isChecked));
    };

    const handleCheckboxChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        onCodeChange({
            selected: ev.target.checked,
            interacted: true,
        });
        setShowAsValid(isCheckboxValid(ev.target.checked));
    };

    const interacted = React.useRef<boolean>(false);

    const handleNumericValueChange = (newValue: NumericData, validity: Validity) => {
        const anyValues: boolean = newValue.values.some(v => v && typeof v.value !== "undefined");

        onCodeChange({ value: newValue, selected: anyValues, interacted: interacted.current }, validity);

        if (interacted.current) {
            setShowAsValid(validity.isValid);
        } else {
            interacted.current = true;
        }
    };

    const isSelected = isValueSelected(code.isNumeric, value, isInRunMode);

    const showQualifiers = canShowQualifiers(isSelected, listQualifiers, valueQualifiers);

    const updateQualifiers = (updatedQualifierData: QualifierData) =>
        onCodeChange({ qualifierData: updatedQualifierData });

    const handleProblemChange = problem => {
        onCodeChange({ selected: true, value, problem });
    };

    const componentConfigData = getComponentConfigData(componentsConfig, code, isInRunMode);

    const [numericQualifiers, futureDateAllowed] = extractComponentConfigData(componentConfigData);

    React.useEffect(() => {
        if (isInRunMode) {
            setListQualifiers(componentConfigData?.attributes?.qualifiers?.list ?? []);
            setValueQualifiers(componentConfigData?.attributes?.qualifiers?.value ?? []);
        }
    }, [isInRunMode, componentConfigData]);

    const renderInRunMode = () => {
        const renderNumericEntry = () => (
            <NumericEntry
                data-testid={componentId}
                numericData={value || { values: [] }}
                ariaLabel={label}
                onChange={handleNumericValueChange}
                numericQualifiers={numericQualifiers}
                isMandatory={isMandatory}
            />
        );
        const checkBox =
            code.isNumeric || disallowSelection ? null : (
                <div className="canvas-item-selection">
                    <Checkbox
                        id={componentId}
                        onChange={handleCheckboxChange}
                        onBlur={handleCheckboxBlur}
                        checked={isChecked}
                        labelText={label}
                        invalid={!showAsValid}
                        isMandatory={isMandatory}
                        tabIndex={-1}
                    />
                </div>
            );

        return (
            <>
                {checkBox}
                <div className="canvas-item-entry">
                    {code.isNumeric && numericQualifiers && renderNumericEntry()}
                    {showQualifiers && (
                        <div className="coded-component-qualifiers indented-data-entry">
                            <QualifiersEntry
                                data-testid="qualifier-selection"
                                listQualifiers={listQualifiers}
                                valueQualifiers={valueQualifiers}
                                qualifierData={qualifierData}
                                createQualifiers={showAllQualifiers}
                                onChange={updateQualifiers}
                            />
                        </div>
                    )}
                    {isSelected && isProblem(category) && (
                        <Problem
                            code={code}
                            qualifiers={qualifierData}
                            componentConfigData={componentConfigData}
                            onChange={handleProblemChange}
                            onHandleError={onHandleError}
                        />
                    )}
                </div>
            </>
        );
    };

    const renderInEditMode = () =>
        code.isNumeric ? (
            <div className="canvas-item-entry">
                <Input
                    id={componentId}
                    type="text"
                    aria-label={label}
                    defaultValue="Numeric..."
                    disabled={true}
                    className="coded-component-numeric"
                />
            </div>
        ) : (
            <div className="canvas-item-selection">
                <Checkbox
                    id={componentId}
                    aria-label={label}
                    disabled={true}
                    checked={false}
                    labelText={label}
                    isMandatory={isMandatory}
                />
            </div>
        );

    const canBeShown = !isInRunMode || !disallowSelection || code.isNumeric || showQualifiers || isProblem(category);
    if (!canBeShown) {
        return null;
    }

    return (
        <>
            <DataEntryComponent
                {...props}
                areValuesValid={showAsValid}
                futureDateAllowed={futureDateAllowed}
                className={code.isNumeric ? "" : "checkbox-component"}
            >
                {isInRunMode ? renderInRunMode() : renderInEditMode()}
            </DataEntryComponent>
        </>
    );
};

type CodedProps = DataEntryComponentProps & {
    code: Code;
    category?: string;
    tooltip?: string;
    showAllQualifiers: boolean;
    disallowSelection?: boolean;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
};

const Coded = (props: CodedProps) => {
    const { id, onChange } = props;
    const { templateData = {} } = React.useContext(RunningTemplateContext);

    const isInRunMode = checkRunMode();
    const checked = React.useMemo(() => {
        if (!isInRunMode) {
            return false;
        }

        return templateData[id] ? Boolean(templateData[id].selected) : false;
    }, [templateData[id], isInRunMode]);

    const qualifierData = React.useMemo<QualifierData>(() => {
        if (!isInRunMode) {
            return [];
        }
        return templateData[id] ? templateData[id].qualifierData || [] : [];
    }, [templateData[id]?.qualifierData, isInRunMode]);

    const handleCodedValueChange = codedValue => {
        const data = {
            [id]: codedValue,
        } as DataFromTemplateRun;
        onChange(data);
    };

    return (
        <CodeEntry
            {...props}
            isChecked={checked}
            qualifierData={qualifierData}
            value={templateData[id]?.value}
            onCodeChange={handleCodedValueChange}
        />
    );
};

export default Coded;
