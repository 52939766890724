import { SyntheticEvent } from "react";
import { UpdateableEditorEntity } from "@emisgroup/clint-content/lib/types";
import { QualifierData } from "@emisgroup/clinical-code-entry/lib/qualifiersEntry/types";
import { IHasPerspectives, IPerspective } from "@emisgroup/clint-templates-perspectives";
import { FeaturesConfig } from "./context/config"; // eslint-disable-line import/no-cycle

export type MouseEventHandler = (event: React.MouseEvent) => void;
export enum AppMode {
    READ = "read",
    EDIT = "edit",
    RUN = "run",
}

export enum Scopes {
    LOAD_TEMPLATE = "contlib-rsx.read",
    SAVE_TEMPLATE = "contlib-rsx.write",
}

export enum ComponentType {
    BRANDING = "branding",
    UNCODED = "uncoded",
    NUMERIC_VALUE = "numeric value",
    CODED = "coded",
    PANEL = "panel",
    CONTAINER = "container",
    CLINICAL_CONTENT = "clinical content",
    CLINICAL_CONTENT_ENTITY = "clinical content entity",
    TEMPLATE = "template",
    TAB_CONTAINER = "tab container",
    TAB_PAGE = "tab page",
    SAVED_CONTENT = "saved content",
    PICKING_LIST = "picking list",
    FREE_TEXT = "free text",
    DIARY_ENTRY = "diary entry",
    CODED_PICKING_LIST = "coded picking list",
    CALCULATOR = "calculator",
}

type ItemInColumn = { columnIndex: number };

type BaseComponent = IHasPerspectives & {
    id: string;
    type: ComponentType;
    rule: Rule | null;
    tooltip?: string;
    isMandatory?: boolean;
    category?: string;
    hasDatePrompt?: boolean;
    hasAssociatedText?: boolean;
    selectedByDefault?: boolean;
    perspectives?: IPerspective[];
};

type ComponentInColumn = ItemInColumn & BaseComponent;

export type UncodedComponent = ComponentInColumn & {
    label: string;
    data?: string;
};

export type CodedComponent = ComponentInColumn & {
    label: string;
    code: Code;
    showAllQualifiers: boolean;
    value?: number;
};

export type ClinicalContentEntity = ComponentInColumn & {
    label: string;
    componentId: string;
    update?: UpdateableEditorEntity;
};

export type ClinicalContentComponent = ComponentInColumn & {
    label: string;
    content?: any;
    members: ClinicalContentEntity[];
};

export type NumericValueComponent = ComponentInColumn & {
    label: string;
    value: number;
};

export type PickingListOption = { id: string; text: string; value?: number; data?: string };
export type PickingListComponent = ComponentInColumn & {
    label: string;
    options: PickingListOption[];
    isMultiSelect: boolean;
    exclusiveCheckbox: boolean;
    displayStyle?: string;
    hasValues?: boolean;
    hasFilingData?: boolean;
};

export type CodedPickingListComponent = ComponentInColumn & {
    label: string;
    displayStyle?: string;
    showAllQualifiers: boolean;
    codes: Code[];
};

export type FreeTextComponent = ComponentInColumn & {
    label: string;
};

export type DiaryEntryComponent = ComponentInColumn & {
    label: string;
    code: Code;
};

export type ComponentParameter = { componentId: string; label: string };
export type CalculatorComponent = ComponentInColumn & {
    label: string;
    parameters: ComponentParameter[];
    expression: string;
    code?: Code;
    fileResult?: boolean;
};

export enum BrandingType {
    UKCAIcon = "UKCA Icon",
    UKCALabelling = "UKCA Labelling",
}

export type BaseBrandingComponent = BaseComponent & {
    brandingType?: BrandingType;
};

export type DeviceLabelComponent = BaseBrandingComponent & {
    brandingType: BrandingType.UKCALabelling;
    brandingReleaseDate?: string;
    brandingVersion?: string;
    brandingName?: string;
    brandingAddress?: string;
    brandingNotes?: string;
};

export type DeviceIconComponent = BaseBrandingComponent;

export type BrandingComponent = DeviceIconComponent | DeviceLabelComponent;

export type CanvasItem =
    | UncodedComponent
    | CodedComponent
    | NumericValueComponent
    | FreeTextComponent
    | ClinicalContentComponent
    | ClinicalContentEntity
    | ComponentContainer
    | PickingListComponent
    | DiaryEntryComponent
    | CodedPickingListComponent
    | CalculatorComponent;

export type ComponentContainer = IHasPerspectives & {
    id: string;
    members: CanvasItem[];
    columnCount: number;
    label: string;
    type: string;
    columnIndex?: number;
    rule?: Rule | null;
    ern?: string;
    libraryName?: string;
};

export type Panel = ComponentContainer & {
    actionButtons?: ActionButton[];
    panelName?: string;
    displayPanelTitle?: boolean;
};

export type Tab = ComponentInColumn & {
    label: string;
    members: TabPage[];
};

export type TabPage = ComponentContainer;

export type Code = {
    emisCodeId: number;
    term: string;
    isNumeric: boolean;
    snomedConceptId: string;
    snomedDescriptionId: string;
};

export type CodeValueType = {
    values: { value: number }[];
};

export type DataPropertyValidity = {
    propertyName: string;
    isValid: boolean;
};

type DataItemFromTemplateRun = {
    selected?: boolean;
    interacted?: boolean;
    associatedText?: string;
    value?: number | string | Date | CodeValueType | PickingListOption;
    effectiveDate?: string;
    items?: DataItem[];
    qualifierData?: QualifierData;
    status?: string;
    message?: string;
};

export type CodedDataItem = DataItemFromTemplateRun & { code: Code };
export type DataItem = DataItemFromTemplateRun | CodedDataItem;

export type DataFromTemplateRun = {
    [id: string]: DataItemFromTemplateRun;
};

export type TemplateDataItem = {
    type: ComponentType;
    narrativeData?: any;
    selected?: boolean;
    interacted?: boolean;
    initialValue?: any;
    associatedText?: string;
    value?: number | string | Date | CodeValueType | PickingListOption;
    effectiveDate?: string;
    items?: DataItem[];
    qualifierData?: QualifierData;
    status?: string;
    message?: string;
};

export type TemplateData = {
    [id: string]: TemplateDataItem;
};

export type DataEntryComponentProps = {
    id: string;
    type: ComponentType;
    label: string;
    isSelected: boolean;
    isMandatory?: boolean;
    arePropertiesValid: boolean;
    hasAssociatedText?: boolean;
    associatedText?: any;
    hasDatePrompt?: boolean;
    tooltip?: string;
    onSelect: MouseEventHandler;
    runtimeValidator?: (templateData: TemplateDataItem) => boolean;
    onChange: (data: DataFromTemplateRun) => void;
    onPropertyUpdate: (params: { propertyName: string; propertyValue: any }) => void;
};

export enum ConditionOperator {
    ANY = "any",
    ALL = "all",
}
export enum ConditionSource {
    COMPONENT_SELECTED = "component",
    QUERY = "query",
    COMPONENT_VALUE_SELECTED = "component value selected",
    COMPONENT_HAS_VALUE = "component has a defined value",
    COMPONENT_VALUE_COMPARISON = "component value comparison",
    COMPONENT_SCORE_VALUE_COMPARISON = "component score value comparison",
}

export type ConditionState = {
    text: string;
    value: string;
    conditionSource: ConditionSource;
};

export enum ConditionSourceType {
    COMPONENT = "component",
    QUERY = "query",
}

export type Condition = {
    conditionSource: ConditionSource;
};

export type ComponentCondition = Condition & {
    actorCanvasItemId: string;
};

export type ComponentSelectedCondition = ComponentCondition & {
    selected?: boolean;
};

export type ComponentValueCondition = ComponentCondition & {
    value?: string;
};

export type ComponentValueSelectedCondition = ComponentValueCondition & {
    negated?: string;
};

export type ComponentHasValueCondition = ComponentCondition & {
    hasValue?: boolean;
};

export enum ComponentValueConditionOperator {
    EQUALS = "equals",
    NOT_EQUAL = "notEqual",
    GREATER_THAN = "greaterThan",
    GREATER_THAN_OR_EQUAL = "greaterThanOrEqual",
    LESS_THAN = "lessThan",
    LESS_THAN_OR_EQUAL = "lessThanOrEqual",
    RANGE = "range",
}

export type ComponentValueComparisionCondition = ComponentValueCondition & {
    operator: ComponentValueConditionOperator;
};

export type QueryCondition = Condition & {
    queryId: string;
    queryName: string;
    resultValue?: boolean;
};

export type ConditionsGroup = {
    conditionOperator: ConditionOperator;
    conditionMembers: ConditionMember[];
};

export type ConditionMember = Condition | ConditionsGroup;
export type Rule = ConditionsGroup;
export type ComponentWithAssociatedText = { hasAssociatedText?: boolean; associatedText?: string };

export type QueryResults = {
    [name: string]: any;
};

export type ItemClickProps = { evt: MouseEvent; item: CanvasItem };
export type CanvasItemClickHandler = (eventData: ItemClickProps) => void;

export enum TypeOfProperty {
    MAIN = "main",
    DATA = "data",
    ADDITIONAL_DATA = "additional",
    NONE = "neither",
}

export type BasePropertyAttributes = {
    name: string;
    description: string;
    defaultValue: any;
    getDefaultValue?: (features: FeaturesConfig) => void;
    required: boolean;
};

export enum ComponentDataType {
    NUMERIC = "number",
    STRING = "string",
    ARRAY = "array",
    BOOLEAN = "boolean",
    DROPDOWN = "dropdown",
    MULTI_SELECT_DROPDOWN = "multi-select-dropdown",
    VALUE_LIST = "value-list",
    CODE = "code",
    CODE_LIST = "code-list",
    MULTI_LINE_STRING = "multi-line-string",
    PARAMETER_LIST = "parameter-list",
    CALCULATION_EXPRESSION = "calculation-expression",
}

type DataAttributes = {
    type: ComponentDataType;
    onUpdate?: (value: any, componentProps: CanvasItem) => { updatedItem: CanvasItem; updatedProperties: string[] };
    validate?: (translator, value: any, component: CanvasItem) => { isValid: boolean; message?: string };
    sendChangesMessage?: boolean;
    updateFromProperty?: string;
};

export type DropdownPropertyDataType = DataAttributes & {
    options: string[];
};

export type MultiSelectPropertyOption = {
    value: string;
    text: string;
};

export type MultiSelectPropertyDataType = DataAttributes & {
    placeHolder: string;
    getOptions: (features: FeaturesConfig) => MultiSelectPropertyOption[];
};

export type ConditionalPropertyDataType = DataAttributes & {
    evaluateCondition: (componentProps: CanvasItem, features: FeaturesConfig) => boolean;
};

export type PropertyDataTypeWithWarning = DataAttributes & {
    getWarnings: (componentProps: CanvasItem) => string[];
};

export type PropertyDataType =
    | DropdownPropertyDataType
    | ConditionalPropertyDataType
    | PropertyDataTypeWithWarning
    | DataAttributes;

export type PropertyAttributes = BasePropertyAttributes & {
    readOnly: boolean;
    dataAttributes?: PropertyDataType;
    propertyType: TypeOfProperty;
    hasEditor?: boolean;
    allowsOverrideEdit?: boolean;
};

export type TemplatePropertyMap = {
    [ComponentType.TEMPLATE]: Array<PropertyAttributes>;
};

export type UncodedItemPropertyMap = {
    [ComponentType.UNCODED]: Array<PropertyAttributes>;
};

export type CodedItemPropertyMap = {
    [ComponentType.CODED]: Array<PropertyAttributes>;
};

export type ClinicalContentItemPropertyMap = {
    [ComponentType.CLINICAL_CONTENT]: Array<PropertyAttributes>;
};

export type PanelPropertyMap = {
    [ComponentType.PANEL]: Array<PropertyAttributes>;
};

export type TabPropertyMap = {
    [ComponentType.TAB_CONTAINER]: Array<PropertyAttributes>;
};

export type TabPagePropertyMap = {
    [ComponentType.TAB_PAGE]: Array<PropertyAttributes>;
};

export type ClinicalValuePropertyMap = {
    [ComponentType.NUMERIC_VALUE]: Array<PropertyAttributes>;
};

export type PickingListPropertyMap = {
    [ComponentType.PICKING_LIST]: Array<PropertyAttributes>;
};

export type CodedPickingListPropertyMap = {
    [ComponentType.CODED_PICKING_LIST]: Array<PropertyAttributes>;
};

export type FreeTextPropertyMap = {
    [ComponentType.FREE_TEXT]: Array<PropertyAttributes>;
};

export type DiaryEntryPropertyMap = {
    [ComponentType.DIARY_ENTRY]: Array<PropertyAttributes>;
};

export type CalculatorPropertyMap = {
    [ComponentType.CALCULATOR]: Array<PropertyAttributes>;
};

export type BrandingPropertyMap = {
    [ComponentType.BRANDING]: Array<PropertyAttributes>;
};

export type TabItemType = { text: string; isEmbedded: boolean };

export interface ButtonProps {
    label: string;
    ariaLabel?: string;
    onClick: () => void;
    isPrimary?: boolean;
    isSecondary?: boolean;
    isDanger?: boolean;
    iconClasses?: string;
    disabled?: boolean;
    isWarning?: boolean;
    isOutlined?: boolean;
}

export interface ICheckboxProps extends IProps, IKeyboardProps, IMouseProps {
    /**
     * Sets the id for the aria-describedby field
     */
    ariaDescribedBy?: string;
    /**
     * The aria label
     */
    ariaLabel?: string;
    /**
     * Sets the checkbox to checked
     */
    checked?: boolean;
    /**
     * Sets the checkbox to disabled
     */
    disabled?: boolean;
    /**
     * Should the checkbox be indeterminate?
     */
    indeterminate?: boolean;
    /**
     * Any classNames to add to the checkbox input
     */
    inputClassName?: string;
    /**
     * Sets the checkbox to invalid
     */
    invalid?: boolean;
    /**
     * The text of the label
     */
    labelText?: string;
    /**
     * Name of the element
     */
    name?: string;
    /**
     * The callback function for when the checkbox state is changed
     */
    onChange?: (e: SyntheticEvent<any>) => void;
    /**
     * Sets the checkbox to required
     */
    required?: boolean;
    /**
     * The title of the checkbox
     */
    title?: string;
}

export interface IProps extends IPropsWithTestId, IPropsWithClassName, IPropsWithId {}
export interface IPropsWithTestId {
    /**
     * Test ID for use in test automation
     */
    "data-testid"?: string;
}
export interface IPropsWithClassName {
    /**
     * Custom class names for this component
     */
    className?: string;
}
export interface IPropsWithId {
    /**
     * The ID of the component
     */
    id?: string;
}
export interface IKeyboardProps {
    /**
     * Callback function on keydown
     */
    onKeyDown?: (e: React.KeyboardEvent) => void;
    /**
     * Callback function on keyup
     */
    onKeyUp?: (e: React.KeyboardEvent, value?: string) => void;
}
export interface IMouseProps {
    /**
     * Mouse click handler
     */
    onClick?: (e: SyntheticEvent<any>) => void;
    /**
     * Mouse down handler
     */
    onMouseDown?: (e: React.MouseEvent) => void;
    /**
     * Mouse enter handler
     */
    onMouseEnter?: (e: React.MouseEvent) => void;
    /**
     * Mouse leave handler
     */
    onMouseLeave?: (e: React.MouseEvent) => void;
}

export enum AlertType {
    ERROR = "error",
    WARNING = "warning",
    INFO = "information",
}

export enum AlertSize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
    X_LARGE = "x-large",
}

export type ComponentContainerHistoryItem = ComponentContainer & { change: { itemId: string } };

// These action types are understood by downstream consumers. Changing existing actions is a breaking change.
export enum ActionType {
    CREATE = "create",
    DISCARD = "discard",
    NEXTSTEP = "nextStep",
    DECLINE = "decline",
    STOP = "stop",
}

export enum ActionButtonStyle {
    PRIMARY = "primary",
    SECONDARY = "secondary",
}

export type ActionButton = {
    action: ActionType;
    label: string;
    style: ActionButtonStyle;
    validate: boolean;
};

export enum PerspectiveSetting {
    RUNNER = "runner",
    REFERRAL_REPORT = "referralReport",
}

type BaseActionItem = { label: string };
type BaseComponentAction = BaseActionItem & { interacted?: boolean; perspectives?: IPerspective[] };
type BaseCodedComponentAction = BaseComponentAction & { codeId: number };

export type UncodedActionItem = BaseComponentAction & {
    type: ComponentType.UNCODED;
    selected: boolean;
    data?: string;
};

export type CodedActionItem = BaseCodedComponentAction & {
    type: ComponentType.CODED;
    selected: boolean;
};

export type NumericActionItem = BaseComponentAction & {
    type: ComponentType.NUMERIC_VALUE;
    value: string;
};

export type CodedNumericActionItem = BaseCodedComponentAction & {
    type: ComponentType.CODED;
    value: string;
};

export type TextActionItem = BaseComponentAction & { type: ComponentType.FREE_TEXT; value: string };
export type PickingListActionItem = BaseComponentAction & {
    type: ComponentType.PICKING_LIST;
    options: { text: string; data?: string }[];
    selected: { text: string; data?: string }[];
};

export type CodedPickingListActionItem = BaseComponentAction & {
    type: ComponentType.CODED_PICKING_LIST;
    options: { text: string; data?: string }[];
    selected: { text: string; data?: string }[];
};

export type ClinicalContentActionItem = BaseComponentAction & { type: ComponentType.CLINICAL_CONTENT; content: string };
export type DiaryEntryActionItem = BaseCodedComponentAction & { type: ComponentType.DIARY_ENTRY; date: string };
export type PanelActionItem = BaseActionItem & { type: ComponentType.PANEL; items: ActionItem[] };
export type BrandingActionItem = Omit<BaseComponentAction, "label"> & { type: ComponentType.BRANDING };

export type ActionItem =
    | UncodedActionItem
    | NumericActionItem
    | TextActionItem
    | PickingListActionItem
    | PanelActionItem
    | ClinicalContentActionItem
    | CodedActionItem
    | CodedNumericActionItem
    | CodedPickingListActionItem
    | DiaryEntryActionItem
    | BrandingActionItem;

export type ActionData = {
    dateCompleted: string;
    items: ActionItem[];
};

/**
 * The Radio item interface used in Radiobuttons component
 */
export interface IRadioItem {
    /**
     * Radio item checked by default
     */
    checked?: boolean;
    /**
     * Sets the radio button to disabled state
     */
    disabled?: boolean;
    /**
     * The display text of the item
     */
    text: string;
    /**
     * The value of the item
     */
    value: string | number | string[];
}
