/**
 * Utilities
 * This class exposes a utility method which is used to generate a random string
 */
export class IdGenerator {
    /**
     * End of string for random number generate
     */
    public static readonly end: number = 2;

    /**
     * Start of string for random number generate
     */
    public static readonly start: number = 12;

    /**
     * String conversion number
     */
    public static readonly stringbase: number = 36;

    /**
     * This method is used to used to generate a random string
     *
     * @param stringVal The additional text for concatination
     */
    public static generateRandomId(stringVal?: string): string {
        const getRandomCryptoNumber = () => {
            return 0.5 - crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
        };

        let randomString: string = getRandomCryptoNumber().toString(this.stringbase).substring(this.start, this.end);

        if (stringVal) {
            // convert the string by removing any non-alphanumeric characters (including spaces) and making lowercase
            randomString += stringVal.toLowerCase().replace(/[^0-9a-z]/gi, "");
        }

        return randomString;
    }
}
