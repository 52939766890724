import React, { FC } from "react";

interface IRibbonSublistItemsViewProps {
    /**
     * The sublist items
     */
    getSublistItems: JSX.Element[];
}

/**
 * Responsible for rendering the Ribbon sublist items view
 *
 * @param props The props passed to the component
 * @returns the RibbonSublistItemsView
 */
export const RibbonSubListItems: FC<IRibbonSublistItemsViewProps> = (
    props: IRibbonSublistItemsViewProps,
): JSX.Element => {
    const { getSublistItems } = props;

    return <>{getSublistItems}</>;
};
