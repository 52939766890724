import React from "react";
import { ButtonGroup } from "@emisgroup/ui-button";
import { Dialog } from "@emisgroup/ui-dialog";
import { v4 as uuid } from "uuid";
import { DialogButton } from "./dialogButton";
import { ButtonProps } from "../types";

export const getButtonGroup = (buttons: ButtonProps[], leftAlignButtons = false) => (
    <ButtonGroup className={leftAlignButtons ? "left-aligned-dialog-button-group" : ""}>
        {buttons.map(button => (
            <DialogButton button={button} key={uuid()} />
        ))}
    </ButtonGroup>
);

type FooterProps = { buttons: ButtonProps[]; leftAlignButtons: boolean };
const Footer = React.memo(({ buttons, leftAlignButtons }: FooterProps) => (
    <Dialog.Footer>{getButtonGroup(buttons, leftAlignButtons)}</Dialog.Footer>
));

interface DialogProps {
    title?: string;
    buttons: ButtonProps[];
    onCancel: () => void;
    children: React.ReactNode;
    contentClassName?: string;
    fillAvailableSpace?: boolean;
    ariaLabel?: string;
    leftAlignButtons?: boolean;
    disableDismiss?: boolean;
}

const GenericDialog = ({
    children,
    buttons,
    onCancel,
    title = "",
    contentClassName = "",
    ariaLabel,
    fillAvailableSpace = false,
    leftAlignButtons = false,
    disableDismiss = false,
}: DialogProps) => {
    return (
        <div role="none" onClick={evt => evt.stopPropagation()} aria-label={ariaLabel}>
            <Dialog open={true} onOpenChange={onCancel} preventBackdropClose={disableDismiss}>
                <Dialog.Inner className={`dialog_layer ${fillAvailableSpace ? "" : "auto-sized-dialog"}`}>
                    <Dialog.Header closeButtonTitle={title} omitCloseButton={false}>
                        {title}
                    </Dialog.Header>
                    <Dialog.Content className={contentClassName}>
                        <>
                            <hr />
                            {children}
                        </>
                    </Dialog.Content>
                    <Footer buttons={buttons} leftAlignButtons={leftAlignButtons} />
                </Dialog.Inner>
            </Dialog>
        </div>
    );
};

export default GenericDialog;
