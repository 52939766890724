/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button } from "@emisgroup/ui-button";
import { useTranslation } from "@emisgroup/application-intl";
import { Code } from "../types";
import { TemplateHistoryContext, HistoryItem } from "../context/templateHistory";
import TimedSpinner from "../uiComponents/timedSpinner";
import { Calendar, Refresh } from "@emisgroup/icons-react";

type HistoryButtonProps = { code?: Code; toggleHistoryList: () => void };
const HistoryButton = ({ code, toggleHistoryList }: HistoryButtonProps) => (
    <Button
        className="history-button"
        onClick={toggleHistoryList}
        ariaLabel={useTranslation().t("components.history.showHistoryForCodeTerm", { codeTerm: code?.term })}
    >
        <Calendar title="" />
    </Button>
);

type HeadlineDescriptionProps = { historyItem: HistoryItem };
const HeadlineDescription = ({ historyItem }: HeadlineDescriptionProps) => (
    <div className="headline-description" data-testid="headline-description">
        <div className="history-text" style={{ gridRow: 2 }}>
            {historyItem.term && <span className="history-term">{historyItem.term}</span>}
            {historyItem.value && <span className="history-value">{historyItem.value}</span>}
        </div>
        <div className="history-text" style={{ gridRow: 1 }}>
            {historyItem.date || ""}
        </div>
    </div>
);

type HeadlineProps = HistoryButtonProps & HeadlineDescriptionProps;
const Headline = ({ historyItem, code, toggleHistoryList }: HeadlineProps) => (
    <>
        <HeadlineDescription historyItem={historyItem} />
        <HistoryButton code={code} toggleHistoryList={toggleHistoryList} />
    </>
);

const getHistoryMessages = (t, inRetry) => ({
    errorText: (
        <div className="custom-form-element__error history-text" style={{ gridColumn: 1 }}>
            {t("components.history.cannotLoadEntries")}
        </div>
    ),
    noPreviousEntriesText: <div className="history-text">{t("components.history.noPreviousEntries")}</div>,
    retrievingHistoryText: (
        <TimedSpinner
            className="timedSpinner"
            horizontalAlign={true}
            delayMs={inRetry ? 0 : undefined}
            loading={t("components.history.unsupported")}
            text={t("components.history.retrievingHistory")}
        />
    ),
    unsupportedText: <div className="history-text">{t("components.history.unsupported")}</div>,
});

type HistoryHeadlineProps = { code?: Code; historyKey?: string; toggleHistoryList: () => void };
const HistoryHeadline = ({ code, historyKey, toggleHistoryList }: HistoryHeadlineProps) => {
    const { t } = useTranslation();

    const { history, historyWithErrors, retryHistory, isHistoryRetry } = React.useContext(TemplateHistoryContext);

    const [headline, noPreviousEntries] = React.useMemo(() => {
        if (!historyKey) {
            return [null, false];
        }
        const itemsForKey = history[historyKey];
        if (!itemsForKey) {
            return [null, false];
        }
        if (!itemsForKey.length) {
            return [null, true];
        }

        return [itemsForKey[0], false];
    }, [history, historyKey]);

    const hasError = React.useMemo(
        () => !isHistoryRetry && historyKey && historyWithErrors.some(h => h.key === historyKey),
        [historyKey, historyWithErrors, isHistoryRetry],
    );
    const m = getHistoryMessages(t, isHistoryRetry);

    const getTextViews = () => {
        if (noPreviousEntries) {
            return m.noPreviousEntriesText;
        }

        if (historyKey) {
            return m.retrievingHistoryText;
        }

        return m.unsupportedText;
    };

    const getFullHistoryView = () => {
        if (hasError) {
            return (
                <>
                    {m.errorText}
                    <Button
                        className="history-button"
                        onClick={retryHistory}
                        ariaLabel={t("components.history.retryRetrieval")}
                    >
                        <Refresh title="" />
                    </Button>
                </>
            );
        }

        if (headline) {
            return <Headline historyItem={headline} code={code} toggleHistoryList={toggleHistoryList} />;
        }

        return getTextViews();
    };

    const getHistoryLine = () => {
        if (hasError) {
            return m.errorText;
        }

        if (headline) {
            return <HeadlineDescription historyItem={headline} />;
        }

        return getTextViews();
    };

    return (
        <>
            <div className="canvas-item-history">{getFullHistoryView()}</div>
            <div className="canvas-item-history-line">{getHistoryLine()}</div>
        </>
    );
};

export default HistoryHeadline;
