/* eslint-disable react/style-prop-object */
import { Button } from "@emisgroup/ui-button";
import { Checkbox } from "@emisgroup/ui-checkbox";
import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { v4 as uuid } from "uuid";
import { CanvasItem, findCanvasItem, PickingListOption, TemplateContext } from "@emisgroup/clint-templates-common";
import { ConnectDragSource, ConnectDropTarget } from "react-dnd";
import { LIST_ITEM_DRAG_TYPE } from "../constants";
import addDragDropSort from "./addDragDropSort";
import ListBuilderItem from "./listBuilderItem";
import "./listBuilder.css";
import PropertyError from "./propertyError";
import { getCanvasItemIdsActedOnBy } from "../utils/ruleUtils";
import { AddSecondary } from "@emisgroup/icons-react";

export type ItemWithKey = {
    value: PickingListOption;
    key: string;
};

const SortableListBuilderItem = addDragDropSort(ListBuilderItem, LIST_ITEM_DRAG_TYPE);

const swapItems = (items: ItemWithKey[], fromIndex: number, toIndex: number) => {
    const updatedItems = items.slice();
    const to = updatedItems[toIndex];

    updatedItems[toIndex] = updatedItems[fromIndex];
    updatedItems[fromIndex] = to;

    return updatedItems;
};

type ListConfigProps = {
    hasValues: boolean;
    hasFilingData: boolean;
    onAdd: () => void;
    onHasValuesToggle: (value: boolean) => void;
    onHasFilingDataToggle: (value: boolean) => void;
};
const ListConfig = ({ hasValues, hasFilingData, onAdd, onHasValuesToggle, onHasFilingDataToggle }: ListConfigProps) => {
    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", columnGap: "0.5rem", alignItems: "center" }}>
            <Button data-testid="list-item-add-button" onClick={onAdd} variant="filled">
                <AddSecondary title="" />
                {""}
                {t("listEntry.addOption")}
            </Button>
            <Checkbox data-testid="values-toggle" checked={hasValues} onChange={checked => onHasValuesToggle(checked)}>
                Value
            </Checkbox>
            <Checkbox
                data-testid="filing-data-toggle"
                checked={hasFilingData}
                onChange={checked => onHasFilingDataToggle(checked)}
            >
                Data
            </Checkbox>
        </div>
    );
};

export type ListBuilderProps = {
    componentId: string;
    items: ItemWithKey[];
    isReadOnly: boolean;
    hasValues: boolean;
    hasFilingData: boolean;
    validity: { isValid: boolean; message?: string };
    updateItems: (items: ItemWithKey[], updateProperty: boolean) => void;
    onHasValuesToggle: (value: boolean) => void;
    onHasFilingDataToggle: (value: boolean) => void;
    onExclusiveCheckboxToggle: (value: boolean) => void;
    exclusiveCheckbox: boolean;
    displayStyle?: string;
};

export const ListBuilder = ({
    componentId,
    items,
    isReadOnly,
    hasValues,
    hasFilingData,
    validity,
    updateItems,
    onHasValuesToggle,
    onHasFilingDataToggle,
    exclusiveCheckbox,
    displayStyle,
    onExclusiveCheckboxToggle,
}: ListBuilderProps) => {
    const { t } = useTranslation();
    const exclusiveCheckboxText = t("listEntry.exclusiveCheckboxText");
    const exclusiveCheckboxId = t("listEntry.exclusiveCheckboxId");
    const RADIO_BUTTONS = t("components.pickingList.styles.radioButtons");
    const { templateDefinition } = React.useContext(TemplateContext);
    const [focusedItemIndex, setFocusedItemIndex] = React.useState<number | undefined>();
    const handleExclusiveCheckboxToggle = (checked: boolean) => {
        if (checked) {
            // Remove any existing "None of the above" items with different IDs
            const updatedItems = items.filter(item => item.value.id !== exclusiveCheckboxId);
            // Add the new "None of the above" item if it doesn't exist or if the "None of the above" text is changed, use the edited text value
            const existingExclusiveItem = items.find(item => item.value.id === exclusiveCheckboxId);
            const exclusiveText = existingExclusiveItem?.value?.text ?? exclusiveCheckboxText;

            updatedItems.push({
                value: {
                    id: exclusiveCheckboxId,
                    text: exclusiveText,
                    value: existingExclusiveItem?.value?.value ?? undefined,
                },
                key: exclusiveCheckboxId,
            });
            updateItems(updatedItems, true);
        } else {
            const noneOfTheAboveItemIndex = items.findIndex(item => item.value.id === exclusiveCheckboxId);
            if (noneOfTheAboveItemIndex !== -1) {
                const updatedItems = [...items];
                updatedItems.splice(noneOfTheAboveItemIndex, 1);
                onExclusiveCheckboxToggle(checked);
                updateItems(updatedItems, true);
            }
        }
    };

    React.useEffect(() => {
        handleExclusiveCheckboxToggle(exclusiveCheckbox);
    }, [exclusiveCheckbox]);

    // Remove any existing option with id "exclusive-checkbox-option" (None of the above) when switching to radio buttons displayStyle
    React.useEffect(() => {
        if (displayStyle && t(displayStyle) === RADIO_BUTTONS) {
            const noneOfTheAboveItemIndex = items.findIndex(item => item.key === exclusiveCheckboxId);
            if (noneOfTheAboveItemIndex !== -1) {
                const updatedItems = [...items];
                updatedItems.splice(noneOfTheAboveItemIndex, 1);
                onExclusiveCheckboxToggle(false);
                updateItems(updatedItems, true);
            }
        }
    }, [displayStyle, items]);

    const handleAdd = () => {
        setFocusedItemIndex(items.length);
        const id = uuid();
        const updatedItems = [
            ...items.filter(item => item.key !== exclusiveCheckboxId),
            { value: { id, text: "" }, key: id },
        ];
        const noneOfTheAboveItem = items.find(item => item.key === exclusiveCheckboxId);
        if (noneOfTheAboveItem) {
            updatedItems.push(noneOfTheAboveItem);
        }
        updateItems(updatedItems, false);
    };

    const handleRemove = (indexInItemList: number) => () => {
        setFocusedItemIndex(undefined);
        let updatedItems = [...items];
        updatedItems.splice(indexInItemList, 1);
        updateItems(updatedItems, true);

        if (items[indexInItemList].key === exclusiveCheckboxId) {
            updatedItems = items.filter(item => item.key !== exclusiveCheckboxId);
            // if none of the above is removed, uncheck the checkbox as well
            handleExclusiveCheckboxToggle(false);
            updateItems(updatedItems, true);
        }
    };

    const handleUpdate = (index: number) => (newValue: PickingListOption) => {
        setFocusedItemIndex(undefined);
        const updatedItems = items.slice();
        updatedItems[index] = { ...updatedItems[index], value: newValue };
        updateItems(updatedItems, false);
    };

    const handleBlur = (e: React.FocusEvent) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
            e.stopPropagation();
        } else {
            updateItems(items, true);
        }
    };

    const handleSwapItems = (fromIndex: number, toIndex: number) => {
        if (typeof fromIndex === "undefined" || typeof toIndex === "undefined") {
            return;
        }

        updateItems(swapItems(items, fromIndex, toIndex), true);
    };

    const valueValidityClass = validity.isValid ? "" : "invalid-property-value";

    const componentsActedOn = React.useMemo<CanvasItem[]>(
        () =>
            templateDefinition.members
                ? (getCanvasItemIdsActedOnBy(componentId, templateDefinition)
                      .map(id => findCanvasItem(id, templateDefinition.members))
                      .filter(Boolean) as CanvasItem[])
                : [],
        [componentId],
    );

    return (
        <div data-testid="list-items" className={`list-builder-items ${valueValidityClass}`} onBlur={handleBlur}>
            {items.map((item, index) => {
                const props = {
                    componentId,
                    value: item.value,
                    index,
                    key: item.key,
                    isReadOnly,
                    hasValues,
                    hasFilingData,
                    exclusiveCheckbox,
                    componentsActedOn,
                    setFocus: index === focusedItemIndex,
                    onRemove: handleRemove(index),
                    onUpdate: handleUpdate(index),
                    swapItems: handleSwapItems,
                };
                return !isReadOnly ? (
                    <SortableListBuilderItem
                        {...props}
                        className={item.key === exclusiveCheckboxId ? "exclusive-checkbox-option" : ""}
                    />
                ) : (
                    <ListBuilderItem
                        {...props}
                        id={componentId}
                        isDragging={false}
                        connectDragSource={{} as ConnectDragSource}
                        connectDropTarget={{} as ConnectDropTarget}
                    />
                );
            })}

            {!isReadOnly && (
                <ListConfig
                    hasValues={hasValues}
                    hasFilingData={hasFilingData}
                    onAdd={handleAdd}
                    onHasValuesToggle={onHasValuesToggle}
                    onHasFilingDataToggle={onHasFilingDataToggle}
                />
            )}
            <PropertyError validity={validity} />
        </div>
    );
};
