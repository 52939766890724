import { getRequiredQueries } from "@emisgroup/clint-content";
import { ComponentType } from "@emisgroup/clint-templates-common";

const getQueriesForAssociatedText = associatedText =>
    associatedText && typeof associatedText !== "string" ? getRequiredQueries(associatedText) : [];

export const getQueriesForComponentType = {
    [ComponentType.CLINICAL_CONTENT]: ({ content }) => getRequiredQueries(content),
    [ComponentType.PANEL]: panel =>
        [].concat(
            ...panel.members
                .map(member => (getQueriesForComponentType[member.type] || (() => []))(member))
                .filter(Boolean),
        ),
    [ComponentType.UNCODED]: component => getQueriesForAssociatedText(component.associatedText),
};

export const RULES_ENGINE_EQUALS_OPERATOR = "equal";
export const RULES_ENGINE_NOT_EQUALS_OPERATOR = "notEqual";
export const RULES_ENGINE_GREATER_THAN_OPERATOR = "greaterThan";
export const RULES_ENGINE_GREATER_THAN_OR_EQUAL_OPERATOR = "greaterThanInclusive";
export const RULES_ENGINE_LESS_THAN_OPERATOR = "lessThan";
export const RULES_ENGINE_LESS_THAN_OR_EQUAL_OPERATOR = "lessThanInclusive";
export const RULES_ENGINE_CONTAINS_OPERATOR = "contains";
export const RULES_ENGINE_DOES_NOT_CONTAIN_OPERATOR = "doesNotContain";
export const RULES_ENGINE_IN_OPERATOR = "in";

export const RENDERED_ITEMS_UPDATE_EVENT = "rendered-items-update";
export const GO_TO_ITEM_EVENT = "go-to-item";

export const TEMPLATE_RUN_DATE_KEY = "TEMPLATE_RUN_DATE";
