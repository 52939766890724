/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Dialog } from "@emisgroup/ui-dialog";
import { Input } from "@emisgroup/ui-input";
import { FormElement } from "@emisgroup/ui-form";
import { Tag } from "@emisgroup/content-picker";
import { useTranslation } from "@emisgroup/application-intl";

import "./saveDraftDialog.css";
import { SaveScope } from "../types";
import { capitalise } from "../utils/componentUtils";
import {
    NAME_FIELD_ID,
    DESCRIPTION_FIELD_ID,
    TAGS_FIELD_ID,
    getValidationErrors,
    isValid,
    PropertyErrors,
    ValidationErrors,
} from "./saveDraftDialogUtils";

type SaveDraftDialogProps = {
    saveScope: SaveScope;
    initialName: string;
    propertyErrors: PropertyErrors;
    onSave: (name: string, description: string, tags: string[]) => void;
    onCancel: () => void;
};

const SaveDraftDialog = ({ saveScope, initialName, propertyErrors, onSave, onCancel }: SaveDraftDialogProps) => {
    const { t } = useTranslation();
    const [name, setName] = React.useState(initialName);
    const [description, setDescription] = React.useState("");
    const [tags, setTags] = React.useState<string[]>([]);
    const [tag, setTag] = React.useState("");
    const [validationErrors, setValidationErrors] = React.useState<ValidationErrors>({
        [NAME_FIELD_ID]: propertyErrors.name,
        [DESCRIPTION_FIELD_ID]: propertyErrors.description,
    });

    const fieldsToValidate = React.useRef(new Set<string>());
    const fieldsIgnoringPropertyError = React.useRef(new Set<string>());

    const addFieldsToValidate = (fieldIds: string[]) => {
        fieldIds.forEach(field => fieldsToValidate.current.add(field));
    };

    const ignorePropertyError = (fieldId: string) => {
        fieldsIgnoringPropertyError.current.add(fieldId);
    };

    const updateValidationErrors = (newName: string, newDescription: string): ValidationErrors => {
        const newValidationErrors = getValidationErrors(
            t,
            fieldsToValidate.current,
            fieldsIgnoringPropertyError.current,
            newName,
            newDescription,
            propertyErrors,
        );

        setValidationErrors(newValidationErrors);
        return newValidationErrors;
    };

    const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newName = ev.target.value.trimStart();
        setName(newName);
        addFieldsToValidate([NAME_FIELD_ID]);
        updateValidationErrors(newName, description);
    };

    const handleDescriptionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription = ev.target.value.trimStart();
        setDescription(newDescription);
        addFieldsToValidate([DESCRIPTION_FIELD_ID]);
        updateValidationErrors(name, newDescription);
    };

    const handleTagChange = (ev: React.ChangeEvent<HTMLInputElement>) => setTag(ev.target.value.trimLeft());
    const handleAddTag = () => {
        setTags([...tags, tag]);
        setTag("");
    };

    const handleSave = () => {
        addFieldsToValidate([NAME_FIELD_ID, DESCRIPTION_FIELD_ID]);
        const newValidationErrors = updateValidationErrors(name, description);
        if (isValid(newValidationErrors)) onSave(name, description, tags);
    };

    React.useEffect(() => {
        fieldsIgnoringPropertyError.current.clear();
        updateValidationErrors(name, description);
    }, [propertyErrors]);

    const title = t(saveScope === SaveScope.Selection ? "save.selection" : "save.templateAs");
    const cancelLabel = t(saveScope === SaveScope.Selection ? "save.cancelSelection" : "save.cancelTemplate");
    const nameField = t("contentLibraryName");
    const descriptionField = t("description");
    const tagsField = t("tags");

    return (
        <Dialog open={true} onOpenChange={onCancel} preventBackdropClose>
            <Dialog.Inner className="dialog_layer auto-sized-dialog">
                <Dialog.Header closeButtonTitle={title}>{title}</Dialog.Header>
                <Dialog.Content>
                    <hr />
                    <FormElement
                        mandatory
                        labelText={capitalise(nameField)}
                        fieldId={NAME_FIELD_ID}
                        errorText={validationErrors[NAME_FIELD_ID]}
                    >
                        <Input
                            id={NAME_FIELD_ID}
                            type="text"
                            aria-label={nameField}
                            value={name}
                            onBlur={handleNameChange}
                            onChange={e => {
                                setName(e.target.value);
                                ignorePropertyError(NAME_FIELD_ID);
                            }}
                            invalid={Boolean(validationErrors[NAME_FIELD_ID])}
                        />
                    </FormElement>
                    <FormElement
                        mandatory
                        labelText={capitalise(descriptionField)}
                        fieldId={DESCRIPTION_FIELD_ID}
                        errorText={validationErrors[DESCRIPTION_FIELD_ID]}
                    >
                        <Input
                            id={DESCRIPTION_FIELD_ID}
                            type="text"
                            aria-label={descriptionField}
                            value={description}
                            onBlur={handleDescriptionChange}
                            onChange={e => {
                                setDescription(e.target.value);
                                ignorePropertyError(DESCRIPTION_FIELD_ID);
                            }}
                            invalid={Boolean(validationErrors[DESCRIPTION_FIELD_ID])}
                        />
                    </FormElement>
                    <FormElement labelText={capitalise(tagsField)} fieldId={TAGS_FIELD_ID}>
                        <div className="tag-entry">
                            <input
                                className="custom-text-input"
                                id={TAGS_FIELD_ID}
                                type="text"
                                aria-label={t("enterATag")}
                                value={tag}
                                onChange={handleTagChange}
                            />
                            <Button
                                type="button"
                                variant="filled"
                                disabled={tag === ""}
                                onClick={handleAddTag}
                                ariaLabel={t("addTag")}
                            >
                                {t("addTag")}
                            </Button>
                        </div>
                        <div className="tag-list">
                            {tags.map(tagText => (
                                <Tag key={tagText} text={tagText} />
                            ))}
                        </div>
                    </FormElement>
                </Dialog.Content>
                <Dialog.Footer>
                    <ButtonGroup className="left-aligned-dialog-button-group">
                        <Button
                            data-testid={`save ${saveScope}`}
                            type="button"
                            variant="filled"
                            onClick={handleSave}
                            ariaLabel={title}
                        >
                            {t("save.save")}
                        </Button>
                        <Button
                            data-testid={`cancel save ${saveScope}`}
                            type="button"
                            onClick={onCancel}
                            ariaLabel={cancelLabel}
                        >
                            {t("cancel")}
                        </Button>
                    </ButtonGroup>
                </Dialog.Footer>
            </Dialog.Inner>
        </Dialog>
    );
};

export default SaveDraftDialog;
