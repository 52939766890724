import * as React from "react";
import { Toast, ToastContainer } from "@emisgroup/ui-notification";
import { Button } from "@emisgroup/ui-button";
import { Close } from "@emisgroup/icons-react";
import "./timedNotification.css";

type TimedNotificationProps = {
    type: "success" | "info" | "error";
    text: string;
    milliseconds: number;
    onTimeout: () => void;
};

const TimedNotification = ({ type, text, milliseconds, onTimeout }: TimedNotificationProps) => {
    React.useEffect(() => {
        let notificationTimer: NodeJS.Timeout | null = null;
        notificationTimer = setTimeout(onTimeout, milliseconds) as unknown as NodeJS.Timeout;

        return () => {
            if (notificationTimer) {
                clearTimeout(notificationTimer);
            }
        };
    }, [milliseconds, onTimeout]);
    return (
        <ToastContainer>
            <Toast open={true} sensitivity="foreground" variant={type}>
                <Toast.Content>{text}</Toast.Content>
                <Toast.Close asChild={true}>
                    <Button borderless={true} variant="mono">
                        <Close title="" />
                    </Button>
                </Toast.Close>
            </Toast>
        </ToastContainer>
    );
};

export default TimedNotification;
