import React, { FC } from "react";
import { ISubRibbonOptions } from "./ISubRibbonOptions";
import { RibbonActionItemLogic } from "./ribbonActionItemLogic";
import { RibbonSubListItems } from "./ribbonSubListItems";

interface IRibbonSublistItemProps {
    /**
     * Callback function for handling what sublist button was clicked
     */
    onActionSelect: (selectedAction: string) => void;

    /**
     * The sublist items
     */
    options: ISubRibbonOptions[];

    /**
     * The type of button needed
     */
    type: string;
}

/**
 * Responsible for rendering the Ribbon sublist items
 *
 * @param props The props passed to the component
 * @returns the RibbonSublistItems
 */
export const RibbonSublistItemsLogic: FC<IRibbonSublistItemProps> = (props: IRibbonSublistItemProps): JSX.Element => {
    const { onActionSelect, options, type } = props;

    /**
     * Handling action item click
     *
     * @param selectedAction The name of the action which was clicked
     */
    const actionClicked = (selectedAction: string): void => {
        // Pass back the selected action button title
        onActionSelect(selectedAction);
    };

    const getItems = (item: ISubRibbonOptions, elementType: string): JSX.Element => {
        const clickHandler = (): void => {
            actionClicked(item.text);
        };

        const pressHandler = (e: React.KeyboardEvent): void => {
            if (e.key === "Enter" || e.key === " ") {
                actionClicked(item.text);
            }
        };

        return (
            <RibbonActionItemLogic
                disabled={false}
                iconName="none"
                onClick={clickHandler}
                onKeyDown={pressHandler}
                title={item.text}
                type={elementType}
            />
        );
    };

    // Loop through any options and create the action item
    const getSublistItems = (): JSX.Element[] =>
        options.map((item: ISubRibbonOptions): JSX.Element => <li key={item.text}>{getItems(item, type)}</li>);

    return <RibbonSubListItems getSublistItems={getSublistItems()} />;
};
