import * as React from "react";
import { isEmbeddedContainer, CanvasItem, ComponentContainer } from "@emisgroup/clint-templates-common";
import { ParametersForPropertyUpdate } from "../types";
// eslint-disable-next-line import/no-cycle
import DesignerCanvasColumn from "./designerCanvasColumn";
import { getDisplayColumnIndexForItem } from "../utils/columnUtils";
import useMoveItem from "./useMoveItem";

type DesignerCanvasColumnsProps = {
    component: ComponentContainer;
    dragDropEnabled: boolean;
    onItemPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
    onItemSelect: (eventData) => void;
    handleItemAddition: (item: CanvasItem, index: number, columnIndex: number) => void;
    isCollapseAll?: boolean;
    isExpandAll?: boolean;
};

const DesignerCanvasColumns = ({
    component,
    dragDropEnabled,
    onItemPropertyUpdate,
    onItemSelect,
    handleItemAddition,
    isCollapseAll = false,
    isExpandAll = true,
}: DesignerCanvasColumnsProps) => {
    const { moveItem, moveItemDialog } = useMoveItem(component);

    const { columnCount, members } = component;
    const columns = new Array(columnCount).fill(null).map((_, index) => index);
    const getItemsInColumn = React.useCallback(
        (columnIndex: number) =>
            members.filter(item => getDisplayColumnIndexForItem(item, columnCount) === columnIndex),
        [members, columnCount],
    );
    const isEmbeddedComponent = isEmbeddedContainer(component);

    const handleItemDropped = (item: CanvasItem, index: number, columnIndex: number) => {
        if (item.id === "") {
            handleItemAddition(item, index, columnIndex);
        } else {
            moveItem(item, index, columnIndex);
        }
    };
    return (
        <>
            {columns.map(columnIndex => (
                <DesignerCanvasColumn
                    key={columnIndex}
                    items={getItemsInColumn(columnIndex)}
                    parent={component}
                    dragDropEnabled={!isEmbeddedComponent && dragDropEnabled}
                    onSelect={onItemSelect}
                    columnIndex={columnIndex}
                    onPropertyUpdate={onItemPropertyUpdate}
                    onItemDropped={handleItemDropped}
                    isCollapseAll={isCollapseAll}
                    isExpandAll={isExpandAll}
                />
            ))}
            {moveItemDialog}
        </>
    );
};

export default DesignerCanvasColumns;
