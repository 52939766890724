import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { ErrorHandling, ErrorState, Validity } from "@emisgroup/clinical-code-entry/lib/types";
import {
    Code,
    CodedDataItem,
    CodeValueType,
    DataEntryComponentProps,
    DataFromTemplateRun,
    DataItem,
    TemplateData,
} from "../types";
import { IListItem } from "@emisgroup/clint-templates-perspectives";
import { RunningTemplateContext } from "../context";
import { CodeEntry } from "./coded";
import PickingList from "./pickingList";
import PickingListDataItem from "./pickingListDataItem";
import { applyAdditionalDataPropertyUpdate } from "../utils";

enum SelectionValueType {
    DataValue,
    AdditionalValue,
}
type SelectionValidity = {
    emisCodeId: number;
    selectionValueType: SelectionValueType;
};

type PickingListProps = DataEntryComponentProps & {
    codes: Code[];
    showAllQualifiers: boolean;
    category?: string;
    isMultiSelect?: boolean;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
};

const CodedPickingList = (props: PickingListProps) => {
    const { t } = useTranslation();
    const { codes, onChange, id, showAllQualifiers, isMultiSelect, category = "" } = props;
    const { templateData = {} } = React.useContext(RunningTemplateContext);
    const defaultAssociatedText = templateData[id]?.associatedText;
    const [invalidSelectedItems, setInvalidSelectedItems] = React.useState<SelectionValidity[]>([]);

    const items = React.useMemo(
        () => (templateData[id] ? (templateData[id].items as CodedDataItem[]) || [] : []),
        [templateData],
    );

    const selectedItemsValidityChanged = (
        emisCodeId: number,
        selectionValueType: SelectionValueType,
        isValid: boolean,
    ) => {
        const itemAlreadyInvalid = invalidSelectedItems.some(
            invalidSelection =>
                invalidSelection.emisCodeId === emisCodeId &&
                invalidSelection.selectionValueType === selectionValueType,
        );

        if (!isValid && !itemAlreadyInvalid) {
            setInvalidSelectedItems(invalidSelectedItems.concat({ emisCodeId, selectionValueType }));
        } else if (isValid && itemAlreadyInvalid) {
            setInvalidSelectedItems(
                invalidSelectedItems.filter(
                    invalidSelection =>
                        invalidSelection.emisCodeId !== emisCodeId ||
                        invalidSelection.selectionValueType !== selectionValueType,
                ),
            );
        }
    };

    const handleCodeValueChange = (emisCodeId: number) => (value, validity?: Validity) => {
        if (value.interacted) {
            selectedItemsValidityChanged(emisCodeId, SelectionValueType.DataValue, validity?.isValid ?? true);
        }

        const updatedItems = items.map(item =>
            item.code.emisCodeId === emisCodeId ? { ...item, ...value, selected: true, interacted: true } : item,
        );

        onChange({
            [id]: { items: updatedItems },
        } as DataFromTemplateRun);
    };

    const handleAdditionalValueChange = (emisCodeId: number) => params => {
        const paramsForCode = { ...params[id] };
        const paramItems = paramsForCode.items;
        delete paramsForCode.items;
        const updatedItems = (paramItems || items).map(item =>
            item.code.emisCodeId === emisCodeId ? { ...item, ...paramsForCode } : item,
        );

        onChange({
            [id]: { items: updatedItems },
        } as DataFromTemplateRun);
    };

    const handleRemoveFromMultiSelect = (itemToRemove: DataItem) => () => {
        onChange({
            [id]: {
                items: items.map(item => (item === itemToRemove ? { ...item, selected: false } : item)),
                interacted: true,
                associatedText: defaultAssociatedText,
            },
        } as DataFromTemplateRun);

        setInvalidSelectedItems(
            invalidSelectedItems.filter(
                invalidSelection => invalidSelection.emisCodeId !== (itemToRemove as CodedDataItem).code.emisCodeId,
            ),
        );
    };

    const dataSource = (isMultiSelect ? [] : [{ value: "", text: t("components.pickingList.selectFromList") }]).concat(
        codes.map(code => ({ value: code.emisCodeId.toString(), text: code.term })),
    ) as IListItem[];

    const handleMultiSelect = (selectedValues: string[]) => {
        const selectedItemsReducer = (newSelectedItems, code) => {
            const existingItem = items.find(({ code: { emisCodeId } }) => emisCodeId === code.emisCodeId);
            if (selectedValues.includes(code.emisCodeId.toString())) {
                return existingItem
                    ? [...newSelectedItems, { ...existingItem, selected: true }]
                    : [...newSelectedItems, { code, selected: true, associatedText: defaultAssociatedText }];
            }
            return existingItem ? [...newSelectedItems, { ...existingItem, selected: false }] : newSelectedItems;
        };

        onChange({
            [id]: {
                items: codes.reduce(selectedItemsReducer, [] as DataItem[]),
                interacted: true,
                associatedText: defaultAssociatedText,
            },
        } as DataFromTemplateRun);
    };

    const handleSingleSelect = (selectedValues: string[]) => {
        const [selectedValue] = selectedValues;
        const returnObject = {
            [id]: {
                associatedText: defaultAssociatedText,
                interacted: true,
            },
        } as DataFromTemplateRun;
        if (!selectedValue) {
            returnObject[id].items = [];
        }
        if (!items.length || (items.length && items[0].code.emisCodeId !== Number(selectedValue))) {
            returnObject[id].items = codes
                .filter(({ emisCodeId }) => Number(selectedValue) === emisCodeId)
                .map(code => ({ code, selected: true, associatedText: defaultAssociatedText }));
        } else {
            returnObject[id].items = items;
        }
        onChange(returnObject);
        setInvalidSelectedItems([]);
    };

    const getHasValue = (dataItem: CodedDataItem) => (data: TemplateData) => {
        const { selected, value } = data[id]?.items?.find(item => item === dataItem) || {};
        let isValidValue;
        const codeValues = value as CodeValueType;
        if (codeValues && Array.isArray(codeValues.values)) {
            isValidValue = codeValues.values.some(val => val.value !== null);
        } else {
            isValidValue = Boolean(value);
        }
        return Boolean(dataItem.code.isNumeric ? isValidValue : selected);
    };

    const selectedItems = items.filter(({ selected }) => selected);

    return (
        <PickingList
            {...props}
            dataSource={dataSource}
            selectedValues={selectedItems.map(({ code }) => code.emisCodeId.toString())}
            onPickingListSelect={isMultiSelect ? handleMultiSelect : handleSingleSelect}
            selectedValuesValid={invalidSelectedItems.length === 0}
            templateData={templateData[id]}
        >
            {selectedItems.map(item => (
                <PickingListDataItem
                    key={item.code.emisCodeId}
                    isMultiSelect={Boolean(isMultiSelect)}
                    {...props}
                    dataItem={item}
                    onRemove={handleRemoveFromMultiSelect(item)}
                    header={item.code.term}
                    getHasValue={getHasValue(item)}
                    onChange={handleAdditionalValueChange(item.code.emisCodeId)}
                    applyPropertyUpdate={applyAdditionalDataPropertyUpdate(templateData[id])}
                    onValidityChanged={isValid =>
                        selectedItemsValidityChanged(item.code.emisCodeId, SelectionValueType.AdditionalValue, isValid)
                    }
                >
                    <CodeEntry
                        {...props}
                        code={item.code}
                        showAllQualifiers={showAllQualifiers}
                        label=""
                        disallowSelection
                        isChecked
                        isMandatory={true}
                        value={item.value}
                        hasAssociatedText={false}
                        hasDatePrompt={false}
                        qualifierData={item.qualifierData || []}
                        category={category}
                        excludeHistory
                        onCodeChange={handleCodeValueChange(item.code.emisCodeId)}
                        onChange={handleAdditionalValueChange(item.code.emisCodeId)}
                    />
                </PickingListDataItem>
            ))}
        </PickingList>
    );
};

export default CodedPickingList;
