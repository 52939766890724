import * as React from "react";
import {
    AuthContext,
    CanvasItem,
    ComponentContainer,
    ComponentType,
    ConfigContext,
    SAVED_CONTENT_ENABLED,
    TemplateContext,
} from "@emisgroup/clint-templates-common";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Dialog } from "@emisgroup/ui-dialog";
import { useTranslation } from "@emisgroup/application-intl";
import { NOTIFICATION_TIMEOUT_MS, TEMPLATE_ID } from "../constants";
import {
    saveNewTemplate,
    updateTemplate,
    SaveTemplateResult,
    SaveTemplateResultType,
} from "../contentLibrary/saveTemplates";
import TimedNotification from "./timedNotification";
import SaveDraftDialog from "./saveDraftDialog";
import { PropertyErrors } from "./saveDraftDialogUtils";
import { getConflictsForSaveContainer } from "../utils/conflictUtils";
import {
    getDialogForSaveSelection,
    getBlockingErrorAlertDialog,
    getBlockingErrorForOutdatedAlertDialog,
    Conflicts,
} from "../utils/dialogUtils";
import {
    canEmbedItem,
    getTemplateDefinitionWithEmbeddedContainer,
    removeEmbeddedContent,
} from "../utils/embeddingUtils";
import { ContentLibraryContext } from "../context/contentLibrary";
import { SaveScope } from "../types";
import { DRAFT_STATUS } from "../contentLibrary/loadDefinition";
import { VERSION_OUT_OF_DATE_ERROR_MESSAGE } from "../contentLibrary/utils";

type ErrorDetails = { title: string; message: string };

const emptyPropertyErrors = {};
const emptyErrorDetails = {} as ErrorDetails;

const getTemplateDefinitionForSave = (
    members: CanvasItem[],
    label: string,
    columnCount: number,
): ComponentContainer => ({
    members: removeEmbeddedContent(members),
    id: TEMPLATE_ID,
    type: ComponentType.TEMPLATE,
    label,
    columnCount,
});

const EMPTY_SAVE_CONFLICTS = [];
type UseSaveParams = { onCancelSave?: () => void };
const useSave = ({ onCancelSave }: UseSaveParams) => {
    const { t } = useTranslation();
    const { features, contentLibraryUrl } = React.useContext(ConfigContext);
    const { contentLibrary, setContentLibrary } = React.useContext(ContentLibraryContext);
    const {
        setSelectedItem,
        selectedItem,
        setTemplateDefinition,
        clearTemplateDefinition,
        templateDefinition,
        invalidComponentDefinitionIds,
    } = React.useContext(TemplateContext);
    const [showingSaveTemplateDialog, setShowingSaveTemplateDialog] = React.useState(false);
    const [showingSaveSelectionDialog, setShowingSaveSelectionDialog] = React.useState(false);
    const [propertyErrors, setPropertyErrors] = React.useState<PropertyErrors>(emptyPropertyErrors);
    const [errorDetails, setErrorDetails] = React.useState(emptyErrorDetails);
    const [saving, setSaving] = React.useState(false);
    const [saveConfirmation, setSaveConfirmation] = React.useState("");
    const [saveSelectionConflicts, setSaveSelectionConflicts] = React.useState<Conflicts>(EMPTY_SAVE_CONFLICTS);
    const { getBearerToken } = React.useContext(AuthContext);
    const [versionOutDatedError, setVersionOutDatedError] = React.useState(false);

    const startSave = () => {
        setSaving(true);
        setPropertyErrors(emptyPropertyErrors);
    };

    const endSave = () => {
        setSaving(false);
    };

    const ensureSetErrorDetails = (title: string, message?: string) => {
        setErrorDetails({ title, message: message || "couldNotPerformTheAction" });
        setVersionOutDatedError(message === VERSION_OUT_OF_DATE_ERROR_MESSAGE);
    };

    const doSave = async (
        saveFn: () => Promise<SaveTemplateResult>,
        errorFn: (message?: string, propertyName?: string) => void,
        saveSuccessFn: (ern: string, ref: string) => void,
    ) => {
        try {
            startSave();
            const saveResult = await saveFn();
            if (saveResult.type === SaveTemplateResultType.Fail) {
                const {
                    error: { propertyName, message },
                } = saveResult;
                errorFn(message, propertyName);
                return false;
            }

            saveSuccessFn(saveResult.templateErn, saveResult.ref);
            return true;
        } catch {
            errorFn();
            return false;
        } finally {
            endSave();
        }
    };

    const saveAsOnSaveComplete = React.useRef<((success: boolean) => void) | null>(null);
    const handleSaveAs = async (name: string, description: string, tags: string[]) => {
        const bearerToken = await getBearerToken();
        const isSaveSuccess = await doSave(
            () =>
                saveNewTemplate({
                    contentLibraryUrl,
                    bearerToken,
                    name,
                    description,
                    tags,
                    template: getTemplateDefinitionForSave(
                        templateDefinition.members,
                        templateDefinition.label,
                        templateDefinition.columnCount,
                    ),
                }),
            (message, propertyName) => {
                if (propertyName && message) {
                    setPropertyErrors({ [propertyName]: message });
                } else {
                    setShowingSaveTemplateDialog(false);
                    ensureSetErrorDetails("save.unableToSaveTemplate", message);
                }
            },
            (ern, ref) => {
                setContentLibrary({ name, description, ern, ref, contentStatus: DRAFT_STATUS });
                setShowingSaveTemplateDialog(false);
                setTemplateDefinition(templateDefinition, true, false, false);
                setSaveConfirmation(t("save.templateSaveSuccess", { templateName: name }));
            },
        );

        if (saveAsOnSaveComplete.current) {
            saveAsOnSaveComplete.current(isSaveSuccess);
        }
    };

    const handleSaveSelectionAs = async (name: string, description: string, tags: string[]) => {
        const bearerToken = await getBearerToken();
        const containerToBeSaved = selectedItem as ComponentContainer;

        await doSave(
            () =>
                saveNewTemplate({
                    contentLibraryUrl,
                    bearerToken,
                    name,
                    description,
                    tags,
                    template: getTemplateDefinitionForSave(containerToBeSaved.members, containerToBeSaved.label, 1),
                }),
            (message, propertyName) => {
                if (propertyName && message) {
                    setPropertyErrors({ [propertyName]: message });
                } else {
                    setShowingSaveSelectionDialog(false);
                    ensureSetErrorDetails("save.unableToSaveSelection", message);
                }
            },
            ern => {
                setSelectedItem({} as CanvasItem);
                setTemplateDefinition(
                    getTemplateDefinitionWithEmbeddedContainer(templateDefinition, containerToBeSaved.id, ern, name),
                    false,
                    false,
                );
                setShowingSaveSelectionDialog(false);
                setSaveConfirmation(t("save.selectionSaveSuccess"));
            },
        );
    };

    const templateExistsInContentLibrary = !!contentLibrary.ern;

    const showInvalidPropertiesWarning = () =>
        ensureSetErrorDetails("save.unableToSave", "save.errorMandatoryInfoMissing");

    const saveAs = (onSaveComplete?: (success: boolean) => void) => {
        if (invalidComponentDefinitionIds.length > 0) {
            showInvalidPropertiesWarning();
            return;
        }
        saveAsOnSaveComplete.current = onSaveComplete || null;
        setShowingSaveTemplateDialog(true);
    };

    const save = async (onSaveComplete?: (success: boolean) => void) => {
        if (invalidComponentDefinitionIds.length > 0) {
            showInvalidPropertiesWarning();
            return;
        }

        if (!templateExistsInContentLibrary) {
            saveAs(onSaveComplete);
            return;
        }

        const bearerToken = await getBearerToken();
        const isSaveSuccess = await doSave(
            () =>
                updateTemplate({
                    contentLibraryUrl,
                    bearerToken,
                    ern: contentLibrary.ern,
                    ref: contentLibrary.ref,
                    template: getTemplateDefinitionForSave(
                        templateDefinition.members,
                        templateDefinition.label,
                        templateDefinition.columnCount,
                    ),
                }),
            message => {
                ensureSetErrorDetails("save.unableToSaveTemplate", message);
            },
            (ern, ref) => {
                setContentLibrary({ ...contentLibrary, ern, ref, contentStatus: DRAFT_STATUS });
                setTemplateDefinition(templateDefinition, true, false, false);
                setSaveConfirmation(t("save.templateSaveSuccess", { templateName: contentLibrary.name }));
            },
        );
        if (onSaveComplete) {
            onSaveComplete(isSaveSuccess);
        }
    };

    const handleCancel = () => {
        setShowingSaveTemplateDialog(false);
        setShowingSaveSelectionDialog(false);
        if (onCancelSave) {
            onCancelSave();
        }
    };

    const saveSelection = () => {
        if (invalidComponentDefinitionIds.length > 0) {
            showInvalidPropertiesWarning();
            return;
        }

        const conflicts = getConflictsForSaveContainer(t, selectedItem as ComponentContainer, templateDefinition);

        if (conflicts.length === 0) {
            setShowingSaveSelectionDialog(true);
        } else {
            setSaveSelectionConflicts(conflicts);
        }
    };
    const view = (
        <>
            {showingSaveTemplateDialog && (
                <SaveDraftDialog
                    saveScope={SaveScope.Template}
                    initialName={templateDefinition.label}
                    propertyErrors={propertyErrors}
                    onSave={handleSaveAs}
                    onCancel={handleCancel}
                />
            )}

            {showingSaveSelectionDialog && (
                <SaveDraftDialog
                    saveScope={SaveScope.Selection}
                    initialName={selectedItem.label}
                    propertyErrors={propertyErrors}
                    onSave={handleSaveSelectionAs}
                    onCancel={handleCancel}
                />
            )}
            {saving && (
                <Dialog open={true} preventBackdropClose={true}>
                    <Dialog.Inner id="spinner-dialog" className="dialog_layer">
                        <ProgressSpinner delay={1} className="spinner" size="small" text={t("saving")} />
                    </Dialog.Inner>
                </Dialog>
            )}
            {saveConfirmation && (
                <TimedNotification
                    type="success"
                    text={saveConfirmation}
                    milliseconds={NOTIFICATION_TIMEOUT_MS}
                    onTimeout={() => setSaveConfirmation("")}
                />
            )}
            {errorDetails.message &&
                (versionOutDatedError
                    ? getBlockingErrorForOutdatedAlertDialog({
                          t,
                          title: errorDetails.title,
                          errorMessage: errorDetails.message,
                          onDiscard: () => {
                              setErrorDetails(emptyErrorDetails);
                              setVersionOutDatedError(false);
                              clearTemplateDefinition();
                          },
                          onCancel: () => {
                              setErrorDetails(emptyErrorDetails);
                              setVersionOutDatedError(false);
                          },
                      })
                    : getBlockingErrorAlertDialog({
                          t,
                          title: errorDetails.title,
                          errorMessage: errorDetails.message,
                          onClose: () => setErrorDetails(emptyErrorDetails),
                      }))}
            {saveSelectionConflicts.length > 0 &&
                getDialogForSaveSelection({
                    t,
                    conflicts: saveSelectionConflicts,
                    onClose: () => setSaveSelectionConflicts(EMPTY_SAVE_CONFLICTS),
                })}
        </>
    );

    const canSaveSelection = React.useMemo(
        () => features[SAVED_CONTENT_ENABLED] && canEmbedItem(selectedItem, templateDefinition.members),
        [selectedItem, templateDefinition],
    );

    return { save, saveAs, saveSelection, canSaveSelection, view };
};

export default useSave;
