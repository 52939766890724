import { v4 as uuid } from "uuid";
import { ASSOCIATED_TEXT_IS_CLINICAL_CONTENT } from "./context/config";
import {
    CalculatorPropertyMap,
    ClinicalContentItemPropertyMap,
    ClinicalValuePropertyMap,
    CodedItemPropertyMap,
    ComponentDataType,
    ComponentType,
    FreeTextPropertyMap,
    PanelPropertyMap,
    PickingListPropertyMap,
    PropertyAttributes,
    TabPagePropertyMap,
    TabPropertyMap,
    TemplatePropertyMap,
    TypeOfProperty,
    UncodedItemPropertyMap,
    DiaryEntryPropertyMap,
    CodedPickingListPropertyMap,
    UncodedComponent,
    NumericValueComponent,
    PickingListComponent,
    BrandingPropertyMap,
    BrandingType,
} from "./types";
import { getListPropertyValidity, getMaxLengthValidity } from "./utils/componentUtils";
import {
    getCalculationExpressionValidity,
    getCalculationParametersValidity,
    getCalculationResultCodeValidity,
} from "./utils/calculatorUtils";

export const COMPONENT_TYPE_LABELS = {
    [ComponentType.BRANDING]: "components.branding.name",
    [ComponentType.UNCODED]: "components.uncoded.name",
    [ComponentType.CODED]: "components.coded.name",
    [ComponentType.NUMERIC_VALUE]: "components.numericEntry.name",
    [ComponentType.PANEL]: "components.panel.name",
    [ComponentType.CONTAINER]: "components.container",
    [ComponentType.CLINICAL_CONTENT]: "components.clinicalContent.name",
    [ComponentType.CLINICAL_CONTENT_ENTITY]: "components.clinicalContent.entity",
    [ComponentType.TEMPLATE]: "template",
    [ComponentType.TAB_CONTAINER]: "components.tabBar.name",
    [ComponentType.TAB_PAGE]: "components.tabBar.tabPage",
    [ComponentType.SAVED_CONTENT]: "components.savedContent",
    [ComponentType.PICKING_LIST]: "components.pickingList.uncoded",
    [ComponentType.FREE_TEXT]: "components.freeText.name",
    [ComponentType.DIARY_ENTRY]: "components.diary.name",
    [ComponentType.CODED_PICKING_LIST]: "components.pickingList.coded",
    [ComponentType.CALCULATOR]: "components.calculator.name",
};

const BaseProperties: PropertyAttributes[] = [
    {
        name: "type",
        description: "components.componentType",
        defaultValue: "",
        required: true,
        readOnly: true,
        dataAttributes: { type: ComponentDataType.STRING },
        propertyType: TypeOfProperty.NONE,
    },
];

const hasAssociatedTextProperty = {
    name: "hasAssociatedText",
    description: "components.associatedText.description",
    defaultValue: false,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.ADDITIONAL_DATA,
    dataAttributes: { type: ComponentDataType.BOOLEAN },
};

const defaultAssociatedTextProperty = {
    name: "associatedText",
    description: "components.associatedText.default",
    defaultValue: "",
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.NONE,
    dataAttributes: {
        type: ComponentDataType.MULTI_LINE_STRING,
        evaluateCondition: (component, features) =>
            !features[ASSOCIATED_TEXT_IS_CLINICAL_CONTENT] && Boolean(component.hasAssociatedText),
    },
};

const associatedTextProperties = [hasAssociatedTextProperty, defaultAssociatedTextProperty];

const tooltipProperty = {
    name: "tooltip",
    description: "components.property.tooltip",
    defaultValue: "",
    required: false,
    readOnly: false,
    allowsOverrideEdit: true,
    propertyType: TypeOfProperty.NONE,
    dataAttributes: { type: ComponentDataType.STRING },
};

const mandatoryProperty = {
    name: "isMandatory",
    description: "components.property.mandatory",
    defaultValue: false,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.ADDITIONAL_DATA,
    dataAttributes: { type: ComponentDataType.BOOLEAN },
};

export const CATEGORY_PROBLEM = "components.categories.problem";
const CATEGORY_FOLLOW_UP = "components.categories.followUp";

const categoryDefinition = {
    header: "components.categories.header",
    categories: [
        "components.categories.additional",
        "components.categories.assessment",
        "components.categories.comment",
        "components.categories.examination",
        "components.categories.familyHistory",
        "components.categories.history",
        CATEGORY_PROBLEM,
        "components.categories.procedure",
        "components.categories.result",
        "components.categories.social",
        "components.categories.testRequest",
    ],
};

const categoryProperty = {
    name: "category",
    description: categoryDefinition.header,
    defaultValue: process.env.APP_DEFAULT_CATEGORY || "",
    required: true,
    readOnly: false,
    propertyType: TypeOfProperty.MAIN,
    dataAttributes: { type: ComponentDataType.DROPDOWN, options: categoryDefinition.categories },
};

export const DEFAULT_STYLE = "components.pickingList.styles.dropdown";
export const RADIO_BUTTONS = "components.pickingList.styles.radioButtons";
const pickingListStyleDefinition = {
    header: "components.pickingList.styles.header",
    styles: [DEFAULT_STYLE, RADIO_BUTTONS],
};

const displayStylePickingListProperty = {
    name: "displayStyle",
    description: pickingListStyleDefinition.header,
    defaultValue: DEFAULT_STYLE,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.NONE,
    dataAttributes: {
        type: ComponentDataType.DROPDOWN,
        options: pickingListStyleDefinition.styles,
        onUpdate: (value, component) =>
            value === RADIO_BUTTONS && component.isMultiSelect
                ? { updatedItem: { ...component, isMultiSelect: false }, updatedProperties: ["isMultiSelect"] }
                : { updatedItem: component, updatedProperties: [] },
        sendChangesMessage: true,
    },
};

const effectiveDateProperty = {
    name: "hasDatePrompt",
    description: "components.property.promptForDate",
    defaultValue: false,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.ADDITIONAL_DATA,
    dataAttributes: { type: ComponentDataType.BOOLEAN },
};

const SELECTED_BY_DEFAULT_VISIBILITY_RULE_WARNING = "components.property.selectedByDefaultWarning";
const selectedByDefaultProperty = {
    name: "selectedByDefault",
    description: "components.property.selectedByDefault",
    defaultValue: false,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.ADDITIONAL_DATA,
    dataAttributes: {
        type: ComponentDataType.BOOLEAN,
        getWarnings: component =>
            component.selectedByDefault && component.rule ? [SELECTED_BY_DEFAULT_VISIBILITY_RULE_WARNING] : [],
    },
};

const codeProperty = {
    name: "code",
    description: "components.coded.code",
    dataAttributes: {
        type: ComponentDataType.CODE,
        onUpdate: (value, component) => {
            const updateLabel = !component.label || !component.code || component.code.term === component.label;
            return updateLabel
                ? { updatedItem: { ...component, label: value.term }, updatedProperties: ["label"] }
                : { updatedItem: component, updatedProperties: [] };
        },
    },
    defaultValue: "",
    required: true,
    readOnly: false,
    propertyType: TypeOfProperty.MAIN,
};
const showAllQualifiersProperty = {
    name: "showAllQualifiers",
    description: "components.coded.showAllQualifiers",
    dataAttributes: { type: ComponentDataType.BOOLEAN },
    defaultValue: false,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.ADDITIONAL_DATA,
};

const multiSelectProperty = {
    name: "isMultiSelect",
    description: "components.pickingList.allowMultipleSelection",
    defaultValue: false,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.ADDITIONAL_DATA,
    dataAttributes: {
        type: ComponentDataType.BOOLEAN,
        evaluateCondition: component => (component.displayStyle ?? "") !== RADIO_BUTTONS,
    },
};

const exclusiveCheckboxProperty = {
    name: "exclusiveCheckbox",
    description: "components.pickingList.exclusiveCheckbox",
    defaultValue: false,
    checked: false,
    required: false,
    readOnly: false,
    propertyType: TypeOfProperty.ADDITIONAL_DATA,
    dataAttributes: {
        type: ComponentDataType.BOOLEAN,
        evaluateCondition: component => (component.displayStyle ?? "") !== RADIO_BUTTONS,
        onUpdate: (value: boolean, component: any) => {
            return {
                updatedItem: { ...component, exclusiveCheckbox: value },
                updatedProperties: ["exclusiveCheckbox"],
            };
        },
    },
};

const brandingInfoProperty = {
    defaultValue: "",
    required: false,
    readOnly: false,
    allowsOverrideEdit: false,
    propertyType: TypeOfProperty.NONE,
    dataAttributes: { type: ComponentDataType.STRING },
};

const brandingMultiLine = {
    ...brandingInfoProperty,
    dataAttributes: { type: ComponentDataType.MULTI_LINE_STRING },
};

export const ItemTypePropertiesMap:
    | TemplatePropertyMap
    | UncodedItemPropertyMap
    | CodedItemPropertyMap
    | PanelPropertyMap
    | TabPropertyMap
    | TabPagePropertyMap
    | ClinicalContentItemPropertyMap
    | ClinicalValuePropertyMap
    | PickingListPropertyMap
    | FreeTextPropertyMap
    | DiaryEntryPropertyMap
    | CodedPickingListPropertyMap
    | BrandingPropertyMap
    | CalculatorPropertyMap = {
    [ComponentType.BRANDING]: BaseProperties.concat([
        {
            name: "brandingType",
            description: "components.branding.type",
            defaultValue: BrandingType.UKCALabelling,
            required: false,
            readOnly: true,
            dataAttributes: { type: ComponentDataType.STRING },
            propertyType: TypeOfProperty.NONE,
        },
        { ...brandingInfoProperty, name: "brandingName", description: "components.branding.ref" },
        { ...brandingInfoProperty, name: "brandingVersion", description: "components.branding.lot" },
        { ...brandingInfoProperty, name: "brandingReleaseDate", description: "components.branding.date" },
        { ...brandingMultiLine, name: "brandingAddress", description: "components.branding.address" },
        { ...brandingMultiLine, name: "brandingNotes", description: "components.branding.notes" },
    ]),
    [ComponentType.TEMPLATE]: [
        {
            name: "label",
            description: "components.property.title",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
            dataAttributes: {
                type: ComponentDataType.STRING,
                validate: (t, value) => getMaxLengthValidity(t, value, 100),
            },
        },
    ],
    [ComponentType.UNCODED]: BaseProperties.concat([
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            allowsOverrideEdit: true,
            propertyType: TypeOfProperty.MAIN,
            dataAttributes: {
                type: ComponentDataType.STRING,
                onUpdate: (value, component: UncodedComponent) => {
                    const updateData = !component.data || component.data === component.label;
                    return updateData
                        ? { updatedItem: { ...component, data: value }, updatedProperties: ["data"] }
                        : { updatedItem: component, updatedProperties: [] };
                },
                sendChangesMessage: true,
            },
        },
        {
            name: "data",
            description: "components.uncoded.dataDescription",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
            dataAttributes: { type: ComponentDataType.STRING, updateFromProperty: "label" },
        },
        selectedByDefaultProperty,
        tooltipProperty,
        mandatoryProperty,
        effectiveDateProperty,
        ...associatedTextProperties,
        categoryProperty,
    ]),
    [ComponentType.CODED]: BaseProperties.concat([
        codeProperty,
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        selectedByDefaultProperty,
        showAllQualifiersProperty,
        tooltipProperty,
        mandatoryProperty,
        effectiveDateProperty,
        ...associatedTextProperties,
        categoryProperty,
    ]),
    [ComponentType.PANEL]: BaseProperties.concat([
        {
            name: "panelName",
            description: "components.panel.nameOfPanel",
            defaultValue: "",
            required: false,
            readOnly: false,
            propertyType: TypeOfProperty.ADDITIONAL_DATA,
        },
        {
            name: "label",
            description: "components.panel.titleOfPanel",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        {
            name: "displayPanelTitle",
            description: "components.panel.displayPanelTitle",
            defaultValue: true,
            required: false,
            readOnly: false,
            propertyType: TypeOfProperty.ADDITIONAL_DATA,
            dataAttributes: { type: ComponentDataType.BOOLEAN },
        },
        {
            name: "libraryName",
            description: "components.panel.externalName",
            defaultValue: undefined,
            required: false,
            dataAttributes: { type: ComponentDataType.STRING },
            readOnly: true,
            propertyType: TypeOfProperty.NONE,
        },
    ]),
    [ComponentType.TAB_CONTAINER]: BaseProperties.concat([
        {
            name: "label",
            description: "components.tabBar.description",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
    ]),
    [ComponentType.TAB_PAGE]: BaseProperties.concat([
        {
            name: "label",
            description: "components.property.tabName",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
    ]),
    [ComponentType.CLINICAL_CONTENT]: BaseProperties.concat([
        {
            name: "content",
            description: "components.clinicalContent.name",
            defaultValue: undefined,
            required: false,
            readOnly: false,
            propertyType: TypeOfProperty.NONE,
            hasEditor: true,
        },
    ]),
    [ComponentType.NUMERIC_VALUE]: BaseProperties.concat([
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        {
            name: "value",
            description: "components.numericEntry.initialValue",
            dataAttributes: {
                type: ComponentDataType.NUMERIC,
                getWarnings: component =>
                    (component as NumericValueComponent).value && component.rule
                        ? [SELECTED_BY_DEFAULT_VISIBILITY_RULE_WARNING]
                        : [],
            },
            defaultValue: "",
            required: false,
            readOnly: false,
            propertyType: TypeOfProperty.DATA,
        },
        tooltipProperty,
        mandatoryProperty,
        effectiveDateProperty,
        ...associatedTextProperties,
        categoryProperty,
    ]),
    [ComponentType.FREE_TEXT]: BaseProperties.concat([
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            allowsOverrideEdit: true,
            propertyType: TypeOfProperty.MAIN,
        },
        tooltipProperty,
        mandatoryProperty,
        effectiveDateProperty,
        categoryProperty,
    ]),
    [ComponentType.PICKING_LIST]: BaseProperties.concat([
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            allowsOverrideEdit: true,
            propertyType: TypeOfProperty.MAIN,
        },
        {
            name: "options",
            description: "components.pickingList.options",
            dataAttributes: {
                type: ComponentDataType.VALUE_LIST,
                validate: (t, value, component: PickingListComponent) => {
                    const textValidity = getListPropertyValidity(t, value, "text");
                    if (!component.hasFilingData) {
                        return textValidity;
                    }
                    const isDataValid = value.every(option => option.data);
                    return {
                        isValid: textValidity.isValid && isDataValid,
                        message: [
                            textValidity.message,
                            isDataValid ? null : t("components.pickingList.allItemsRequireData"),
                        ]
                            .filter(Boolean)
                            .join(", "),
                    };
                },
            },
            defaultValue: [],
            getDefaultValue: () => [
                { id: uuid(), text: "" },
                { id: uuid(), text: "" },
            ],
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        displayStylePickingListProperty,
        multiSelectProperty,
        exclusiveCheckboxProperty,
        tooltipProperty,
        mandatoryProperty,
        effectiveDateProperty,
        ...associatedTextProperties,
        categoryProperty,
    ]),
    [ComponentType.DIARY_ENTRY]: BaseProperties.concat([
        codeProperty,
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        selectedByDefaultProperty,
        tooltipProperty,
        mandatoryProperty,
        { ...hasAssociatedTextProperty, defaultValue: true, readOnly: true },
        defaultAssociatedTextProperty,
        {
            name: "category",
            description: categoryDefinition.header,
            defaultValue: CATEGORY_FOLLOW_UP,
            required: false,
            readOnly: true,
            propertyType: TypeOfProperty.ADDITIONAL_DATA,
            dataAttributes: { type: ComponentDataType.STRING },
        },
    ]),
    [ComponentType.CODED_PICKING_LIST]: BaseProperties.concat([
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        {
            name: "codes",
            description: "components.pickingList.codes",
            dataAttributes: {
                type: ComponentDataType.CODE_LIST,
                validate: (t, value) => getListPropertyValidity(t, value, "emisCodeId"),
            },
            defaultValue: [],
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        displayStylePickingListProperty,
        showAllQualifiersProperty,
        multiSelectProperty,
        tooltipProperty,
        mandatoryProperty,
        effectiveDateProperty,
        ...associatedTextProperties,
        categoryProperty,
    ]),
    [ComponentType.CALCULATOR]: BaseProperties.concat([
        {
            name: "label",
            description: "components.property.label",
            defaultValue: "",
            required: true,
            readOnly: false,
            propertyType: TypeOfProperty.MAIN,
        },
        {
            name: "parameters",
            description: "components.calculator.parameters",
            dataAttributes: {
                type: ComponentDataType.PARAMETER_LIST,
                validate: getCalculationParametersValidity,
            },
            required: false,
            propertyType: TypeOfProperty.MAIN,
            defaultValue: [],
            readOnly: false,
        },
        {
            name: "expression",
            description: "components.calculator.expression",
            dataAttributes: {
                type: ComponentDataType.CALCULATION_EXPRESSION,
                validate: getCalculationExpressionValidity,
            },
            required: true,
            propertyType: TypeOfProperty.MAIN,
            defaultValue: "",
            readOnly: false,
        },
        {
            name: "code",
            description: "components.calculator.addCodeToResult",
            dataAttributes: {
                type: ComponentDataType.CODE,
                validate: getCalculationResultCodeValidity,
            },
            required: false,
            propertyType: TypeOfProperty.ADDITIONAL_DATA,
            defaultValue: "",
            readOnly: false,
        },
        {
            name: "fileResult",
            description: "components.calculator.fileResult",
            defaultValue: true,
            required: false,
            readOnly: false,
            propertyType: TypeOfProperty.ADDITIONAL_DATA,
            dataAttributes: { type: ComponentDataType.BOOLEAN },
        },
        tooltipProperty,
        mandatoryProperty,
        effectiveDateProperty,
        ...associatedTextProperties,
        categoryProperty,
    ]),
};

export const KEYS = {
    TAB: "Tab",
    ESC: "Escape",
    DELETE: "Delete",
};
