import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Delete } from "@emisgroup/icons-react";
import { Dimension, PerspectivesDefinition } from "@emisgroup/clint-templates-perspectives/lib";
import { Input } from "@emisgroup/ui-input";
import { Checkbox } from "@emisgroup/ui-checkbox";
import "./perspectives.css";
import { getStateValidationErrors, PerspectiveState, settingToDescription } from "../utils/perspectiveUtils";
import { capitalise } from "../utils/componentUtils";

export type PerspectiveEditProps = {
    states: PerspectiveState[];
    selectedIndex: number;
    updatePerspective: (state: PerspectiveState) => void;
    perspectiveSettings: PerspectivesDefinition[];
    generatePerspectiveName: (state: PerspectiveState) => string;
    deletePerspective: () => void;
};

type PerspectiveValueState = {
    isValueSelected: (key: string) => boolean;
    setValueSelected: (key: string, selected: boolean) => void;
};

const PerspectiveDimensionEdit = (props: {
    setting: string;
    dimension: Dimension;
    valueState: PerspectiveValueState;
}) => {
    const { setting, dimension, valueState } = props;
    return (
        <div className="perspective-dimension">
            <div className="perspective-dimension-name">{capitalise(dimension.name)}</div>
            <div className="perspective-dimension-values">
                {dimension.values.map(v => {
                    const key = `${setting}-${v}`;
                    const checked = valueState.isValueSelected(key);
                    return (
                        <Checkbox
                            key={key}
                            className="perspective-dimension-value"
                            checked={checked}
                            onChange={() => valueState.setValueSelected(key, !checked)}
                        >
                            {capitalise(v)}
                        </Checkbox>
                    );
                })}
            </div>
        </div>
    );
};

const PerspectiveSettingEdit = (props: { setting: PerspectivesDefinition; valueState: PerspectiveValueState }) => {
    const { t } = useTranslation();
    const { setting, valueState } = props;
    const { setting: settingName } = setting;
    return (
        <div className="perspective-setting">
            <div className="perspective-setting-label">{settingToDescription(t, settingName)}</div>
            <div className="perspective-setting-choices">
                {setting.dimensions.map(d => (
                    <PerspectiveDimensionEdit
                        key={settingName}
                        setting={settingName}
                        dimension={d}
                        valueState={valueState}
                    />
                ))}
            </div>
            <hr />
        </div>
    );
};

const updateName = (newName: string, state: PerspectiveState): PerspectiveState => {
    const trimmedName = newName.trim();
    return trimmedName.length > 0
        ? { ...state, perspectiveCustomName: trimmedName }
        : { ...state, perspectiveCustomName: undefined };
};

const PerspectiveEdit = (props: PerspectiveEditProps) => {
    const { t } = useTranslation();
    const { states, selectedIndex, updatePerspective, generatePerspectiveName } = props;
    const state = states[selectedIndex];

    const valueState: PerspectiveValueState = {
        isValueSelected: React.useCallback(key => state.selectedKeys.includes(key), [state]),
        setValueSelected: React.useCallback(
            (key, selected) => {
                const newSelectedKeys = selected
                    ? state.selectedKeys.concat(key)
                    : state.selectedKeys.filter(k => k !== key);
                updatePerspective({ ...state, selectedKeys: newSelectedKeys });
            },
            [state, updatePerspective],
        ),
    };
    const settings = props.perspectiveSettings;
    const validationErrors = getStateValidationErrors(t, selectedIndex, states);
    return (
        <div className="perspective-edit">
            <div className="perspective-name">
                <div className="perspective-name-label">{t("perspectives.name")}</div>
                <Input
                    className="perspective-name-input"
                    value={generatePerspectiveName(state)}
                    onChange={ev => updatePerspective(updateName(ev.currentTarget.value, state))}
                />
                <div className="perspective-delete" role="none" onClick={props.deletePerspective}>
                    <Delete title="delete" />
                </div>
            </div>
            {React.Children.toArray(validationErrors.map(e => <div className="perspective-error">{e}</div>))}
            <hr />
            {React.Children.toArray(settings.map(s => <PerspectiveSettingEdit setting={s} valueState={valueState} />))}
        </div>
    );
};

export default PerspectiveEdit;
