import React from "react";
import { IPropsWithId, IPropsWithTestId } from "@emisgroup/clint-templates-common";

interface IRibbonViewProps extends IPropsWithId, IPropsWithTestId {
    /**
     * Classlist for the actions button
     */
    actionButtonClasses?: string;

    /**
     * The action icon for the actions button
     */
    actionIcon?: JSX.Element;

    /**
     * A unique ID for the actions list
     */
    actionListUniqueId?: string;

    /**
     * Is the action menu open?
     */
    actionMenuOpen?: boolean;

    /**
     * The end view items
     */
    endView: () => JSX.Element | null;

    /**
     * Node reference object
     */
    node: React.RefObject<HTMLLIElement>;

    /**
     * The primary action button element
     */
    primaryActionButton: JSX.Element;

    /**
     * The ribbon classes
     */
    ribbonClasses?: string;

    /**
     * Classlist for the secondary actions
     */
    secondaryActionListClasses?: string;

    /**
     * The secondary actions elements
     */
    secondaryActions?: JSX.Element[];

    /**
     * Function to toggle the actions menu on small screens
     */
    toggleActionMenu?: () => void;
}

/**
 * Responsible for rendering the Ribbon view element
 *
 * @param props The props passed to the component
 * @returns the RibbonView
 */
export const Ribbon: React.FC<IRibbonViewProps> = (props: IRibbonViewProps): JSX.Element => {
    const {
        actionButtonClasses,
        actionIcon,
        actionListUniqueId,
        actionMenuOpen,
        "data-testid": dataTestId,
        endView,
        id,
        node,
        primaryActionButton,
        ribbonClasses,
        secondaryActionListClasses,
        secondaryActions,
        toggleActionMenu,
    } = props;

    return (
        <div className={ribbonClasses} data-testid={dataTestId} id={id}>
            {primaryActionButton}
            {secondaryActions && secondaryActions.length > 0 && (
                <div className="eui-ribbon__start">
                    <ul className="eui-ribbon__action">
                        <li ref={node} aria-expanded="false" className={actionButtonClasses}>
                            <button
                                aria-controls={actionListUniqueId}
                                aria-expanded={actionMenuOpen}
                                className="eui-ribbon__button-action eui-ribbon__button-action--sublist"
                                onClick={toggleActionMenu}
                                type="button"
                            >
                                Actions
                                {actionIcon}
                            </button>
                            <ul className={secondaryActionListClasses} id={actionListUniqueId}>
                                {secondaryActions}
                            </ul>
                        </li>
                    </ul>
                </div>
            )}
            {endView()}
        </div>
    );
};
