import * as React from "react";
import { useHistory } from "react-router";
import {
    AppMode,
    AppModeContext,
    TemplateContext,
    SUPPORT_MULTI_COLUMN,
    ConfigContext,
    ComponentType,
    PERSPECTIVES,
    PerspectiveSetting,
} from "@emisgroup/clint-templates-common";
import { findDimensionByValue, getDimensionsForSetting } from "@emisgroup/clint-templates-perspectives";
import { useTranslation } from "@emisgroup/application-intl";
import { RunStyle } from "@emisgroup/clint-templates-runner";
import { getDialogForItemDeletion, getDialogForUngroupingPanel } from "../utils/dialogUtils";
import useSave from "./useSave";
import { getDeletionInformation } from "../utils/conflictUtils";
import useCreateItem from "./useCreateItem";
import mockPatients from "../../../../../../mock-patients.json";
import { SelectedPatientContext } from "../context/selectedPatientContext";
import { TestRunMode, TestRunModeContext } from "../context/testRunMode";
import useChangesWarning from "./useChangesWarning";

import "./ribbonBar.css";
import ReportPreviewDialog from "./reportPreviewDialog";
import { RibbonLogic, IRibbonAction } from "./ribbon/ribbonLogic";

enum RibbonAction {
    DELETE,
    GROUP,
    UNGROUP,
    PREVIEW,
}

const FILE_GROUP = "file";
const UNDO_GROUP = "undo";
const COMPONENT_GROUP = "component";

const capitaliseFirstLetter = string => string[0].toUpperCase() + string.slice(1);

type RibbonBarProps = {
    templateHasId: boolean;
    deleteEnabled: boolean;
    groupToPanelEnabled: boolean;
    ungroupPanelEnabled: boolean;
    conditionalPanelEnabled: boolean;
    useMockPatientSelect: boolean;
    allowedModes: AppMode[];
    onDelete: () => void;
    switchToLoadMode: () => void;
    switchToEditMode: () => void;
    switchToRunMode: () => void;
    switchToReadMode: () => void;
    ungroupPanel: () => void;
    addConditionalPanel: () => void;
    closeRedirectUri?: string;
};

const RibbonBar = ({
    templateHasId,
    deleteEnabled,
    groupToPanelEnabled,
    ungroupPanelEnabled,
    conditionalPanelEnabled,
    useMockPatientSelect,
    onDelete,
    allowedModes,
    switchToLoadMode,
    switchToEditMode,
    switchToRunMode,
    switchToReadMode,
    ungroupPanel,
    addConditionalPanel,
    closeRedirectUri,
}: RibbonBarProps) => {
    const { t } = useTranslation();

    const EDIT_MODE_TEXT = t("edit");
    const RUN_MODE_TEXT = t("templates.testRun");
    const READ_MODE_TEXT = t("view");

    const SAVE_TEXT = t("save.save");
    const SAVE_AS_TEXT = t("save.as");
    const SAVE_SELECTION_TEXT = t("save.selection");

    const COLUMNS_TEXT = {
        1: t("builderLayout.columns.one"),
        2: t("builderLayout.columns.two"),
    };

    const COMPACT_VIEW_TEXT = t("builderLayout.view.compact");
    const FULL_WIDTH_TEXT = t("builderLayout.view.fullWidth");
    const ASSESSMENT_STYLE_TEXT = t("runStyle.assessment");

    const { features } = React.useContext(ConfigContext);
    const { mode } = React.useContext(AppModeContext);
    const { withChangesWarning, dialog } = useChangesWarning({});
    const {
        templateDefinition,
        setTemplateDefinition,
        clearTemplateDefinition,
        selectedItem,
        history,
        currentIndex,
        goBack,
        goForward,
    } = React.useContext(TemplateContext);
    const { save, saveAs, saveSelection, canSaveSelection, view: saveView } = useSave({});
    const { newItemProperties, handlePanelCreationFromGroupedItems } = useCreateItem(templateDefinition);
    const { patientId, updatePatientId } = React.useContext(SelectedPatientContext);
    const {
        mode: testRunMode,
        updateMode: updateTestRunMode,
        runStyle,
        updateRunStyle,
        runDimensionValues,
        updateRunDimensionValues,
    } = React.useContext(TestRunModeContext);

    const [currentAction, setCurrentAction] = React.useState<RibbonAction | null>(null);

    const isInRunMode = mode === AppMode.RUN;
    const isInReadMode = mode === AppMode.READ;

    const visibilityRuleAppliedInPanel = selectedItem.type === ComponentType.PANEL && selectedItem.rule !== null;

    const hideDialog = () => {
        setCurrentAction(null);
    };

    const showConfirmationDialog = () => {
        switch (currentAction) {
            case RibbonAction.DELETE:
                return getDialogForItemDeletion({
                    t,
                    ...getDeletionInformation(t, selectedItem, templateDefinition),
                    onDelete,
                    hideDialog,
                });
            case RibbonAction.UNGROUP:
                return getDialogForUngroupingPanel({ t, ungroupPanel, hideDialog });
            case RibbonAction.PREVIEW:
                return <ReportPreviewDialog hideDialog={hideDialog} />;
            default:
                break;
        }

        return null;
    };

    React.useEffect(() => {
        if (currentAction === RibbonAction.GROUP) {
            handlePanelCreationFromGroupedItems();
            hideDialog();
        }
    }, [currentAction]);

    const saveSublist = [{ text: SAVE_TEXT }, { text: SAVE_AS_TEXT }].concat(
        canSaveSelection ? [{ text: SAVE_SELECTION_TEXT }] : [],
    );

    // The title below has a space added so it can be distinguished from the save item in the sublist in the action handler!!
    const saveActions: IRibbonAction[] =
        templateHasId && !isInRunMode
            ? [
                  {
                      iconName: "save",
                      title: `${SAVE_TEXT} `,
                      group: FILE_GROUP,
                      sublist: saveSublist,
                      disabled: isInReadMode,
                  },
              ]
            : [];

    const undoUnavailable = isInReadMode || history.length === 0 || currentIndex === 0;
    const redoUnavailable = isInReadMode || history.length === 0 || currentIndex === history.length - 1;

    const closeBuilder = () => {
        if (closeRedirectUri) {
            window.location.href = closeRedirectUri;
        } else {
            clearTemplateDefinition();
        }
    };

    const fileActions: IRibbonAction[] = [
        {
            iconName: "open",
            title: t("open"),
            onClick: withChangesWarning(switchToLoadMode),
            group: FILE_GROUP,
        },
        {
            iconName: "close",
            title: t("close"),
            onClick: withChangesWarning(closeBuilder),
            group: FILE_GROUP,
        },
    ];

    const editActions: IRibbonAction[] =
        templateHasId && !isInRunMode
            ? [
                  {
                      iconName: "undo",
                      title: t("undo"),
                      disabled: undoUnavailable,
                      onClick: goBack,
                      group: UNDO_GROUP,
                  },
                  {
                      iconName: "redo",
                      title: t("redo"),
                      disabled: redoUnavailable,
                      onClick: goForward,
                      group: UNDO_GROUP,
                  },
                  {
                      iconName: "delete",
                      title: t("delete"),
                      disabled: isInReadMode || !deleteEnabled,
                      onClick: () => setCurrentAction(RibbonAction.DELETE),
                      group: COMPONENT_GROUP,
                  },
                  {
                      iconName: "group",
                      title: t("group"),
                      disabled: isInReadMode || !groupToPanelEnabled,
                      onClick: () => setCurrentAction(RibbonAction.GROUP),
                      group: COMPONENT_GROUP,
                  },
                  {
                      iconName: "ungroup",
                      title: t("ungroup"),
                      disabled: isInReadMode || !ungroupPanelEnabled,
                      onClick: () =>
                          visibilityRuleAppliedInPanel ? setCurrentAction(RibbonAction.UNGROUP) : ungroupPanel(),
                      group: COMPONENT_GROUP,
                  },
                  {
                      iconName: "new-window",
                      title: t("addConditionalPanel"),
                      disabled: isInReadMode || !conditionalPanelEnabled,
                      onClick: addConditionalPanel,
                      group: COMPONENT_GROUP,
                  },
              ]
            : [];

    const location = useHistory();
    const primaryAction: IRibbonAction = !isInRunMode
        ? {
              iconName: "add",
              title: t("new"),
              onClick: () => location.push("/new"),
          }
        : {
              iconName: "summary",
              title: t("Report preview"),
              disabled: runStyle !== RunStyle.Assessment,
              onClick: () => setCurrentAction(RibbonAction.PREVIEW),
          };
    const actionList: IRibbonAction[] = [...saveActions, ...fileActions, ...editActions];

    const updateColumnCount = (numberOfColumns: number) =>
        setTemplateDefinition({ ...templateDefinition, columnCount: numberOfColumns });

    const handleActionClick = (actionName: string) => {
        switch (actionName) {
            case EDIT_MODE_TEXT:
                switchToEditMode();
                break;
            case RUN_MODE_TEXT:
                switchToRunMode();
                break;
            case READ_MODE_TEXT:
                switchToReadMode();
                break;
            case SAVE_TEXT:
                save();
                break;
            case SAVE_AS_TEXT:
                saveAs();
                break;
            case SAVE_SELECTION_TEXT:
                saveSelection();
                break;
            case COLUMNS_TEXT[1]:
                updateColumnCount(1);
                break;
            case COLUMNS_TEXT[2]:
                updateColumnCount(2);
                break;
            case COMPACT_VIEW_TEXT:
                updateTestRunMode(TestRunMode.Compact);
                break;
            case FULL_WIDTH_TEXT:
                updateTestRunMode(TestRunMode.Standard);
                break;
            case ASSESSMENT_STYLE_TEXT:
                updateRunStyle(RunStyle.Assessment);
                break;
            default: {
                const dimension = findDimensionByValue(
                    features[PERSPECTIVES],
                    PerspectiveSetting.RUNNER,
                    actionName.toLowerCase(),
                );
                if (dimension) {
                    updateRunDimensionValues([
                        ...runDimensionValues.filter(value => !dimension.values.includes(value)),
                        actionName.toLowerCase(),
                    ]);

                    break;
                }

                const patient = mockPatients.find(({ text }) => text === actionName);
                if (useMockPatientSelect && patient) {
                    updatePatientId(patient.value);
                }
                break;
            }
        }
    };

    const allowEdit = allowedModes.includes(AppMode.EDIT);
    const allowRead = !allowEdit && allowedModes.includes(AppMode.READ);
    const allowRun = allowedModes.includes(AppMode.RUN);
    const getRadioButtons = () => {
        const optionArray = [] as any[];
        if (allowEdit) {
            optionArray.push({ checked: mode === AppMode.EDIT, text: EDIT_MODE_TEXT, value: EDIT_MODE_TEXT });
        }
        if (allowRead) {
            optionArray.push({ checked: mode === AppMode.READ, text: READ_MODE_TEXT, value: READ_MODE_TEXT });
        }
        if (allowRun) {
            optionArray.push({ checked: mode === AppMode.RUN, text: RUN_MODE_TEXT, value: RUN_MODE_TEXT });
        }

        return { optionArray };
    };

    const { columnCount } = templateDefinition;
    const getViewPanelActions = (): IRibbonAction[] => {
        if (mode === AppMode.RUN) {
            const runModeSelect = {
                iconName: "ribbon-view",
                title: testRunMode === TestRunMode.Compact ? COMPACT_VIEW_TEXT : FULL_WIDTH_TEXT,
                sublist: [
                    {
                        text: FULL_WIDTH_TEXT,
                    },
                    {
                        text: COMPACT_VIEW_TEXT,
                    },
                ],
            };
            const runStyleSelect = {
                iconName: "configuration",
                title: ASSESSMENT_STYLE_TEXT,
                sublist: [
                    {
                        text: ASSESSMENT_STYLE_TEXT,
                    },
                ],
            };

            const runDimensionValuesSelect = getDimensionsForSetting(
                features[PERSPECTIVES],
                PerspectiveSetting.RUNNER,
            ).map(dimension => {
                return {
                    iconName: dimension.icon,
                    title: capitaliseFirstLetter(
                        runDimensionValues.find(p => dimension.values.includes(p)) ?? dimension.name,
                    ),
                    sublist: dimension.values.map(dimensionValue => ({
                        text: capitaliseFirstLetter(dimensionValue),
                    })),
                };
            });

            return useMockPatientSelect
                ? [
                      {
                          iconName: "ribbon-patient",
                          title:
                              mockPatients.find(patient => patient.value === patientId)?.text ||
                              "Select a mock patient",
                          sublist: mockPatients.map(({ text }) => ({ text })),
                      },
                      runStyleSelect,
                      runModeSelect,
                      ...runDimensionValuesSelect,
                  ]
                : [runStyleSelect, runModeSelect, ...runDimensionValuesSelect];
        }

        return features[SUPPORT_MULTI_COLUMN]
            ? [
                  {
                      iconName: "ribbon-view",
                      sublist: [
                          {
                              text: COLUMNS_TEXT[1],
                          },
                          {
                              text: COLUMNS_TEXT[2],
                          },
                      ],
                      title: columnCount ? COLUMNS_TEXT[columnCount] : COLUMNS_TEXT[1],
                      disabled: isInReadMode,
                  },
              ]
            : [];
    };

    return (
        <>
            <RibbonLogic
                primaryAction={primaryAction}
                actionList={actionList}
                radioButtons={getRadioButtons()}
                viewPanelActions={getViewPanelActions()}
                onActionClick={handleActionClick}
            />
            {dialog}
            {saveView}
            {currentAction !== null && showConfirmationDialog()}
            {newItemProperties && newItemProperties()}
        </>
    );
};

export default RibbonBar;
