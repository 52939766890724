import React from "react";
import { Tag } from "@emisgroup/ui-tag";
import { useTranslation } from "@emisgroup/application-intl";
import { ChevronUp, ChevronDown } from "@emisgroup/icons-react";
import AppModeContext from "../context/appMode";
import { TemplateContext } from "../context/template";
import { Container } from "./container";
import { isEmbeddedContainer } from "../utils/componentUtils";
import { isDescendantOfEmbeddedItem } from "../utils/embeddingUtils";
import { AppMode, CanvasItemClickHandler, ComponentType, Panel, ActionButton } from "../types";
import "./panelComponent.css";
import InvalidPropertyIndicator from "./invalidPropertyIndicator";
import ComponentInfo from "./componentInfo";
import ActionButtons from "./actionButtons";

type PanelProps = {
    children: React.ReactNode | React.ReactNodeArray;
    component: Panel;
    arePropertiesValid?: boolean;
    onSelect: CanvasItemClickHandler;
    onRaiseAction?: (actionButton: ActionButton) => void;
    isCollapseAll?: boolean;
    isExpandAll?: boolean;
};

function PanelComponent({
    children,
    component,
    arePropertiesValid = true,
    onSelect,
    onRaiseAction,
    isCollapseAll = false,
    isExpandAll = true,
}: PanelProps) {
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const { templateDefinition: rootContainer, selectedItem } = React.useContext(TemplateContext);
    const { id, type, label, panelName, displayPanelTitle } = component;
    const isInRunMode = mode === AppMode.RUN;
    const isEmbeddedComponent = isEmbeddedContainer(component);
    const isMasterEmbeddedComponent =
        isEmbeddedComponent && !isDescendantOfEmbeddedItem(component, rootContainer.members);
    const isSelected = !isInRunMode && selectedItem.id === id;
    const invalidClass = arePropertiesValid ? "" : " invalid";
    const [isOpen, setIsOpen] = React.useState(true);
    const openClass = isOpen ? "open" : "closed";

    React.useEffect(() => {
        if (isCollapseAll) {
            setIsOpen(false);
        } else if (isExpandAll) {
            setIsOpen(true);
        }
    }, [isCollapseAll, isExpandAll]);

    return (
        <Container
            id={id}
            label={label}
            dataTestId={`${type}-${id}`}
            className={`canvas-item panel ${invalidClass}`}
            onItemSelect={isInRunMode ? undefined : onSelect}
            component={component}
            isSelected={isSelected}
        >
            {!arePropertiesValid && <InvalidPropertyIndicator />}
            <div className="panel-header" data-testid="panel-header">
                <div data-testid="panel-title-container">
                    <span className="panel-name">{panelName}</span>
                    <span className="panel-label">
                        {displayPanelTitle && label}
                        {isMasterEmbeddedComponent && mode === AppMode.EDIT && (
                            <Tag className="content-library-indicator tag-color2" color="--" textColor="--">
                                {t("components.panel.contentLibraryResource")}
                            </Tag>
                        )}
                    </span>
                </div>
                {mode === AppMode.EDIT && (
                    <span
                        className={`panel-icon ${openClass}`}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        data-testid="panel-toggle"
                    >
                        <ChevronUp title="" />
                        <ChevronDown title="" />
                    </span>
                )}
            </div>
            <div className={`panel-content ${openClass}`} data-testid="panel-content">
                {children}
                {component.actionButtons && component.actionButtons.length > 0 && (
                    <div className="panel-action-buttons">
                        <ActionButtons
                            actionButtons={component.actionButtons}
                            disabled={mode === AppMode.EDIT}
                            onRaiseAction={onRaiseAction}
                        />
                    </div>
                )}
            </div>
            <div className="panel-info" data-testid="panel-info">
                <ComponentInfo
                    id={id}
                    type={ComponentType.PANEL}
                    rule={component.rule}
                    panelActions={component.actionButtons}
                    perspectives={component.perspectives}
                />
            </div>
        </Container>
    );
}

export default PanelComponent;
