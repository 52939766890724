import { Button } from "@emisgroup/ui-button";
import { ButtonProps } from "../types";
import "./dialogButton.css";

export function DialogButton({ button }: { button: ButtonProps }) {
    const { ariaLabel, label, disabled, onClick, isSecondary, isDanger, isWarning, isPrimary } = button;
    let buttonStyle: any = "outlined";
    if (isDanger) {
        buttonStyle = "danger";
    } else if (isWarning) {
        buttonStyle = "warning";
    } else if (isPrimary) {
        buttonStyle = "filled";
    }

    let buttonClassName = "dialog-button";
    if (isWarning) {
        buttonClassName = "warning-icon";
    } else if (isDanger) {
        buttonClassName = "danger-icon";
    } else if (isPrimary) {
        buttonClassName = "primary-button";
    }

    return (
        <Button
            key={label}
            type="button"
            variant={buttonStyle}
            onClick={onClick}
            ariaLabel={ariaLabel}
            disabled={disabled ?? false}
            borderless={isSecondary}
            className={buttonClassName}
        >
            {label}
        </Button>
    );
}
