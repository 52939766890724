import React, { FC } from "react";

interface IRibbonSublistViewProps {
    /**
     * The ARIA ID being uses for this component
     */
    ariaId?: string;

    /**
     * Callback function for handling what sublist content to render
     */
    getSublistContent: () => JSX.Element | null;

    /**
     * Node reference object
     */
    node: React.RefObject<HTMLDivElement>;

    /**
     * Heading for the list of sub-items (View dropdown only)
     */
    optionsHeading?: string;

    /**
     * The type of button that was clicked
     */
    type: string;
}

/**
 * Responsible for rendering the Ribbon sublist view element
 *
 * @param props The props passed to the component
 * @returns the RibbonSublistView element
 */
export const RibbonSubList: FC<IRibbonSublistViewProps> = (props: IRibbonSublistViewProps): JSX.Element => {
    const { ariaId, getSublistContent, node, optionsHeading, type } = props;

    return (
        <div ref={node} className="eui-ribbon__sublist" id={ariaId}>
            {type === "viewSublist" ? <div className="eui-ribbon__sublist-header">{optionsHeading}</div> : <></>}
            <ul className="eui-ribbon__sublist-list">{getSublistContent()}</ul>
        </div>
    );
};
