import React, { useEffect } from "react";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Dialog } from "@emisgroup/ui-dialog";

type TimedSpinnerProps = {
    loading: boolean;
    text: string;
    error?: () => JSX.Element;
    alternate?: () => JSX.Element;
    large?: boolean;
    horizontalAlign?: boolean;
    className?: string;
    delayMs?: number;
    timeoutMs?: number;
};

const MIN_MILLISECONDS_TO_WAIT = 2000;
const MAX_MILLISECONDS_TO_WAIT = 10000;

const useTimers = (
    loading: boolean,
    delay: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    timeout: number,
): {
    showSpinner: boolean;
    showError: boolean;
} => {
    const [showSpinner, setShowSpinner] = React.useState(delay <= 0);
    const [showError, setShowError] = React.useState(false);
    useEffect(() => {
        let spinnerTimer: NodeJS.Timeout | null = null;
        let errorTimer: NodeJS.Timeout | null = null;
        if (loading) {
            setShowSpinner(delay <= 0);
            setShowError(false);
            if (delay > 0) spinnerTimer = setTimeout(() => setShowSpinner(true), delay);
            errorTimer = setTimeout(() => setShowError(true), timeout);
        }
        return () => {
            if (errorTimer) clearTimeout(errorTimer);
            if (spinnerTimer) clearTimeout(spinnerTimer);
        };
    }, [loading]);
    return { showSpinner, showError };
};

const TimedSpinner = (props: TimedSpinnerProps): JSX.Element => {
    const delay = props.delayMs ?? MIN_MILLISECONDS_TO_WAIT;
    const timeout = props.timeoutMs ?? MAX_MILLISECONDS_TO_WAIT;

    const { showError, showSpinner } = useTimers(props.loading, delay, timeout);

    if (showError && props.error) return props.error();

    const spinner = (
        <Dialog open={true} preventBackdropClose={true}>
            <Dialog.Inner id="spinner-dialog">
                {props.large === true ? (
                    <ProgressSpinner
                        delay={1}
                        size="small"
                        text={props.text}
                        className={`${props.className} spinner`}
                    />
                ) : (
                    <ProgressSpinner
                        delay={1}
                        size="x-small"
                        text={props.text}
                        className={`${props.className} spinner`}
                        horizontal={props.horizontalAlign}
                    />
                )}
            </Dialog.Inner>
        </Dialog>
    );

    const noSpinner = props.alternate ?? ((): JSX.Element => <></>);
    return showSpinner ? spinner : noSpinner();
};

export default TimedSpinner;
