// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@emisgroup/ui-styles/dist/base.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctc-warning-box{margin-right:0.5em;margin-top:0.5em;width:100%}.ctc-warning-box__title{font-weight:700}.banner-warning[data-color=warning] svg path{fill:#2B3337}
`, "",{"version":3,"sources":["webpack://./../clint-templates-common/lib/warningBox.css"],"names":[],"mappings":"AAEA,iBACI,kBAAmB,CACnB,gBAAiB,CACjB,UAAW,CACd,wBAGG,eAAgB,CACnB,6CAIO,YAAa","sourcesContent":["@import \"~@emisgroup/ui-styles/base.css\";\n\n.ctc-warning-box {\n    margin-right: 0.5em;\n    margin-top: 0.5em;\n    width: 100%;\n}\n\n.ctc-warning-box__title {\n    font-weight: 700;\n}\n\n.banner-warning[data-color=warning] {\n    svg path {\n        fill: #2B3337;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
