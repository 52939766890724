/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button } from "@emisgroup/ui-button";
import { useTranslation } from "@emisgroup/application-intl";
import { Clear } from "@emisgroup/icons-react";
import "./multiSelectSelection.css";

type MultiSelectSelectionProps = {
    id: string;
    itemName: string;
    children?: React.ReactNode | React.ReactNodeArray;
    onRemove: () => void;
};

const MultiSelectSelection = ({ id, itemName, children, onRemove }: MultiSelectSelectionProps) => (
    <div className="multi-select-selection-container" data-testid={`multi-select-selection-${id}-${itemName}`}>
        <div className="multi-select-selection-header">
            <div className="multi-select-selection-name">{itemName}</div>
            <div className="multi-select-remove-selection">
                <Button
                    data-testid="multi-select-remove-button"
                    borderless={true}
                    title={useTranslation().t("components.pickingList.removeSelection")}
                    onClick={onRemove}
                    className="button-borderless"
                >
                    <Clear title="" />
                </Button>
            </div>
        </div>
        {children}
    </div>
);

export default MultiSelectSelection;
