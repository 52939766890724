/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Dialog } from "@emisgroup/ui-dialog";
import { InfoError } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import TreeView from "./treeView/treeView";
import { TreeNodeData } from "./treeView/types";
import { findTreeNode, getTreeNodeSelectionError } from "../utils/componentSelectorUtils";

import "./componentSelectorDialog.scss";

type ComponentSelectorDialogProps = {
    componentsTreeRootNode: TreeNodeData;
    selectedComponentId?: string;
    showComponentId?: string;
    onSelect: (componentId: string) => void;
    onCancel: () => void;
};

const ComponentSelectorDialog = ({
    componentsTreeRootNode,
    selectedComponentId,
    showComponentId,
    onSelect,
    onCancel,
}: ComponentSelectorDialogProps) => {
    const showRootNode = false;
    const initialNodeSelection = selectedComponentId
        ? findTreeNode(selectedComponentId, componentsTreeRootNode, showRootNode)
        : undefined;

    const { t } = useTranslation();
    const [selectionError, setSelectionError] = React.useState<string | undefined>(
        getTreeNodeSelectionError(initialNodeSelection),
    );
    const selectedNode = React.useRef<TreeNodeData | undefined>(initialNodeSelection);

    const handleNodeSelect = (node: TreeNodeData) => {
        selectedNode.current = node;
        setSelectionError(getTreeNodeSelectionError(node));
    };

    const handleNodeDoubleClick = (node: TreeNodeData) => {
        handleNodeSelect(node);
        if (!getTreeNodeSelectionError(node)) {
            onSelect(node.nodeId);
        }
    };

    const handleAccept = () => {
        if (!selectedNode.current) {
            setSelectionError("componentSelection.errors.selectAComponent");
        } else if (!getTreeNodeSelectionError(selectedNode.current)) {
            onSelect(selectedNode.current.nodeId);
        }
    };

    return (
        <Dialog open={true} onOpenChange={onCancel} preventBackdropClose>
            <Dialog.Inner className="dialog_layer">
                <Dialog.Header closeButtonTitle={t("componentSelection.componentSelector")}>
                    {t("componentSelection.componentSelector")}
                </Dialog.Header>
                <Dialog.Content className="dialog-content">
                    <div className="tree-container">
                        <TreeView
                            showRootNode={showRootNode}
                            rootNode={componentsTreeRootNode}
                            selectNodeId={selectedComponentId}
                            showNodeId={showComponentId}
                            onNodeSelect={handleNodeSelect}
                            onNodeDoubleClick={handleNodeDoubleClick}
                            emptyWarning={t("componentSelection.templateDoesNotContainComponents")}
                        />
                    </div>
                </Dialog.Content>
                <Dialog.Footer>
                    {selectionError && (
                        <div className="selection-error">
                            <InfoError className="selection-error-icon" color="danger" title="" />
                            <div className="selection-error-message" data-testid="selection-error-message">
                                {t(selectionError)}
                            </div>
                        </div>
                    )}
                    <ButtonGroup>
                        <Button
                            data-testid="select-component"
                            type="button"
                            variant="filled"
                            onClick={handleAccept}
                            ariaLabel={t("componentSelection.selectComponent")}
                        >
                            {t("componentSelection.selectComponent")}
                        </Button>
                        <Button
                            data-testid="cancel-select-component"
                            type="button"
                            onClick={onCancel}
                            ariaLabel={t("cancel")}
                            borderless
                            className="button-borderless"
                        >
                            {t("cancel")}
                        </Button>
                    </ButtonGroup>
                </Dialog.Footer>
            </Dialog.Inner>
        </Dialog>
    );
};

export default ComponentSelectorDialog;
