/* eslint-disable react/style-prop-object */
import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Dropdown } from "@emisgroup/ui-dropdown";
import { FormElement } from "@emisgroup/ui-form";
import { Button } from "@emisgroup/ui-button";
import { Clear } from "@emisgroup/icons-react";
import {
    ComponentCondition,
    ComponentType,
    Condition,
    ConditionSource,
    ConditionSourceType,
    findCanvasItem,
    ComponentIcon,
    QueryIcon,
    TemplateContext,
    NumericEntry,
    ComponentValueComparisionCondition,
    ConditionState,
    ComponentValueSelectedCondition,
} from "@emisgroup/clint-templates-common";
import { ConditionForDisplay, VisibilityRuleFields } from "../types";
import {
    getConditionSourceType,
    getConditionState,
    getConditionWithNewState,
    getVisiblityFieldValidity,
} from "../utils/ruleUtils";
import { getAvailableRuleComponentStates } from "../utils/componentUtils";
import VisibilityConditionOperator from "./visibilityConditionOperator";
import { ComponentValueConditionOperator } from "@emisgroup/clint-templates-common/src/types";

const getSourceIcon = (conditionSourceType: ConditionSourceType, conditionComponentType?: ComponentType) => {
    return conditionSourceType === ConditionSourceType.COMPONENT ? (
        <ComponentIcon type={conditionComponentType as ComponentType} />
    ) : (
        <QueryIcon />
    );
};

const initialNumericComparisonValue = (condition: Condition) => {
    if (
        condition.conditionSource === ConditionSource.COMPONENT_VALUE_COMPARISON ||
        condition.conditionSource === ConditionSource.COMPONENT_SCORE_VALUE_COMPARISON
    ) {
        const componentValueComparisonCondition = condition as ComponentValueComparisionCondition;
        if (
            typeof componentValueComparisonCondition.value !== "undefined" &&
            !Number.isNaN(componentValueComparisonCondition.value)
        ) {
            return Number(componentValueComparisonCondition.value);
        }
    }
    return undefined;
};

const initialRangeScoreValue = (condition: Condition) => {
    const componentValueComparisonCondition = condition as ComponentValueComparisionCondition;
    if (
        typeof componentValueComparisonCondition.value !== "undefined" &&
        componentValueComparisonCondition.value?.toString().includes("-")
    ) {
        const [minValue, maxValue] = componentValueComparisonCondition.value.split("-").map(Number);
        return {
            minValue,
            maxValue,
        };
    }
    return undefined;
};

const isState = (condition: Condition) =>
    [ConditionSource.COMPONENT_SELECTED, ConditionSource.COMPONENT_HAS_VALUE].includes(condition.conditionSource);

const ariaLabel = (t, condition: Condition, sourceText) =>
    t(isState(condition) ? "templates.rules.selectStateForName" : "templates.rules.selectValueForName", {
        name: sourceText,
    });

const nanToUndefined = (value: number) => (!Number.isNaN(value) ? value : undefined);

type VisibilityConditionProps = {
    canvasItemLabel: string;
    conditionForDisplay: ConditionForDisplay;
    invalidConditionFields?: any;
    conditionIndex: number;
    canEditRules: boolean;
    onUpdate: (condition: Condition) => void;
    onDelete: () => void;
    onSelectNewSource: () => void;
};

const VisibilityCondition = ({
    canvasItemLabel,
    conditionForDisplay,
    invalidConditionFields = {},
    conditionIndex,
    canEditRules,
    onUpdate,
    onDelete,
    onSelectNewSource,
}: VisibilityConditionProps) => {
    const { t } = useTranslation();
    const { templateDefinition } = React.useContext(TemplateContext);
    const {
        condition: initialCondition,
        sourceText,
        conditionText,
        conditionSourceType,
        conditionComponentType,
    } = conditionForDisplay;

    const [condition, setCondition] = React.useState<Condition>(initialCondition);

    const [numericComparisonValue, setNumericComparisonValue] = React.useState<number | undefined>(
        initialNumericComparisonValue(initialCondition),
    );
    const [rangeValues, setRangeValues] = React.useState(initialRangeScoreValue(initialCondition));

    const currentConditionState = getConditionState(condition);

    const componentStates = React.useMemo<ConditionState[]>(
        () =>
            getAvailableRuleComponentStates(
                t,
                findCanvasItem((condition as ComponentCondition).actorCanvasItemId, templateDefinition.members),
            ),
        [(condition as ComponentCondition).actorCanvasItemId, templateDefinition],
    );

    const queryStates: ConditionState[] = [
        {
            text: t("passed"),
            value: "true",
            conditionSource: ConditionSource.QUERY,
        },
        {
            text: t("failed"),
            value: "false",
            conditionSource: ConditionSource.QUERY,
        },
    ];

    const conditionStates = React.useMemo<ConditionState[]>(() => {
        return condition.conditionSource === ConditionSource.QUERY ? queryStates : componentStates;
    }, [condition.conditionSource]);

    const getAriaLabel = () => `${t("dependencyFor")} ${canvasItemLabel}: ${sourceText} - ${conditionText}`;

    const updateConditionOperator = (negated?: string) => {
        let conditionSource = ConditionSource.COMPONENT_SCORE_VALUE_COMPARISON;
        if (negated === "is" || negated === "isNot") {
            conditionSource = ConditionSource.COMPONENT_VALUE_SELECTED;
        }
        const updatedCondition = { ...condition, negated, conditionSource } as ComponentValueSelectedCondition;
        setCondition(updatedCondition);
    };

    const updateConditionState = (state: string) => {
        const newConditionValue = conditionStates.find(
            conditionState => conditionState.value === state,
        ) as ConditionState;

        let newCondition;
        if (
            getConditionSourceType(condition) === ConditionSourceType.COMPONENT &&
            newConditionValue.conditionSource !== condition.conditionSource
        ) {
            newCondition = getConditionWithNewState(
                {
                    conditionSource: newConditionValue.conditionSource,
                    actorCanvasItemId: (condition as ComponentCondition).actorCanvasItemId,
                } as ComponentCondition,
                state,
            );
        } else {
            newCondition = getConditionWithNewState(condition, state);
        }
        setCondition(newCondition);
    };

    const handleMinChange = (value: any) => {
        setRangeValues(previousState => ({
            maxValue: previousState?.maxValue ?? 0,
            minValue: nanToUndefined(value) ?? previousState?.minValue ?? 0,
        }));
    };

    const handleMaxChange = (value: any) => {
        setRangeValues(previousState => ({
            minValue: previousState?.minValue ?? 0,
            maxValue: nanToUndefined(value) ?? previousState?.maxValue ?? 0,
        }));
    };

    const handleChangeComparisonConditionValue = (newValue: number) => {
        setNumericComparisonValue(nanToUndefined(newValue));
    };

    const handleBlurComparisonConditionValue = (_, e) => {
        e.stopPropagation();
        const newCondition = { ...condition, value: numericComparisonValue };
        onUpdate(newCondition);
    };

    const handleBlurMinMaxComparisonConditionValue = (_, e) => {
        e.stopPropagation();
        if (
            rangeValues?.maxValue &&
            rangeValues.minValue &&
            (rangeValues.minValue || 0) <= (rangeValues.maxValue || 0)
        ) {
            const newCondition = { ...condition, value: `${rangeValues.minValue}-${rangeValues.maxValue}` };
            onUpdate(newCondition);
        }
    };

    const handleBlurCondition = (e: React.FocusEvent) => {
        e.stopPropagation();
        const dropdownElements = document.querySelectorAll('[class^="Dropdown_dropdownContent"]');
        if (!(dropdownElements && (dropdownElements[0] as Node))) {
            onUpdate(condition);
        }
    };

    const { isValid: isNumericValueValid, message: numericValueErrorMessage } = getVisiblityFieldValidity(
        VisibilityRuleFields.COMPONENT_COMPARISON_VALUE,
        invalidConditionFields,
    );

    const renderScoreFields = () => {
        if ((condition as ComponentValueSelectedCondition).negated == ComponentValueConditionOperator.RANGE) {
            return (
                <>
                    <NumericEntry
                        className={`custom-text-input ${isNumericValueValid ? "" : " custom-text-input--invalid"}`}
                        aria-label={t("templates.rules.enterValueForCondition")}
                        onChange={handleMinChange}
                        onBlur={handleBlurMinMaxComparisonConditionValue}
                        defaultValue={rangeValues?.minValue}
                    />
                    <NumericEntry
                        className={`custom-text-input ${isNumericValueValid ? "" : " custom-text-input--invalid"}`}
                        aria-label={t("templates.rules.enterValueForCondition")}
                        onChange={handleMaxChange}
                        onBlur={handleBlurMinMaxComparisonConditionValue}
                        defaultValue={rangeValues?.maxValue}
                    />
                </>
            );
        } else {
            return (
                <NumericEntry
                    className={`custom-text-input ${isNumericValueValid ? "" : " custom-text-input--invalid"}`}
                    aria-label={t("templates.rules.enterValueForCondition")}
                    onChange={handleChangeComparisonConditionValue}
                    onBlur={handleBlurComparisonConditionValue}
                    defaultValue={numericComparisonValue}
                />
            );
        }
    };

    return (
        <div
            key={conditionForDisplay.key}
            className={`sidebar-row visibility-condition ${
                !isNumericValueValid ? " visibility-condition--invalid" : ""
            }`}
            aria-label={getAriaLabel()}
        >
            <div className="visibility-condition__content">
                <div className="visibility-condition__source">
                    <Button
                        type="button"
                        className="visibility-condition__source_button button-outlined"
                        onClick={onSelectNewSource}
                        disabled={!canEditRules}
                        data-testid={`condition-source-button-${conditionIndex}`}
                    >
                        <div>{getSourceIcon(conditionSourceType, conditionComponentType)}</div>
                        <div
                            className="visibility-condition__source__label"
                            data-testid={`condition-source-${conditionIndex}`}
                        >
                            {sourceText}
                        </div>
                    </Button>
                </div>
                <div
                    data-testid={`visibility-condition-${conditionIndex}`}
                    onBlur={handleBlurCondition}
                    className="visibility-condition__condition"
                >
                    <VisibilityConditionOperator
                        condition={condition}
                        conditionIndex={conditionIndex}
                        canEdit={canEditRules}
                        onChanged={updateConditionOperator}
                    />
                    <div
                        className={`visibility-condition__condition_dropdown ${
                            (condition as ComponentValueSelectedCondition).negated ==
                            ComponentValueConditionOperator.RANGE
                                ? "visibility-condition__range_wrapper"
                                : ""
                        }`}
                    >
                        {(condition as ComponentValueSelectedCondition).negated == "is" ||
                        (condition as ComponentValueSelectedCondition).negated == "isNot" ||
                        (condition as ComponentValueSelectedCondition).negated == undefined ? (
                            <Dropdown
                                data-testid={`condition-value-${conditionIndex}`}
                                onValueChange={state => {
                                    updateConditionState(state);
                                }}
                                value={currentConditionState}
                                disabled={!canEditRules}
                            >
                                <Dropdown.Trigger aria-label={ariaLabel(t, condition, sourceText)} />
                                <Dropdown.Content>
                                    {conditionStates.map(state => (
                                        <Dropdown.Item key={state.text} value={state.value}>
                                            {state.text}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Content>
                            </Dropdown>
                        ) : (
                            renderScoreFields()
                        )}
                    </div>
                    {condition.conditionSource === ConditionSource.COMPONENT_VALUE_COMPARISON && (
                        <div className="visibility-condition__condition_value">
                            <FormElement errorText={isNumericValueValid ? undefined : numericValueErrorMessage}>
                                <NumericEntry
                                    className={`custom-text-input ${
                                        isNumericValueValid ? "" : " custom-text-input--invalid"
                                    }`}
                                    aria-label={t("templates.rules.enterValueForCondition")}
                                    onChange={handleChangeComparisonConditionValue}
                                    onBlur={handleBlurComparisonConditionValue}
                                    defaultValue={numericComparisonValue}
                                />
                            </FormElement>
                        </div>
                    )}
                </div>
            </div>
            {canEditRules && (
                <div className="visibility-condition__delete">
                    <button
                        type="button"
                        className="btn btn-default"
                        data-testid={`condition-delete-${conditionIndex}`}
                        onClick={() => onDelete()}
                    >
                        <Clear title={t("templates.rules.deleteCondition")} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default VisibilityCondition;
