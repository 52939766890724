import React from "react";
import {
    AppModeContext,
    TemplateContext,
    Container,
    AppMode,
    CanvasItem,
    useCanvasSizeClass,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { ParametersForPropertyUpdate } from "../types";
import useCreateItem from "./useCreateItem";
import DesignerCanvasColumns from "./designerCanvasColumns";
import TitleBar from "../titleBar";
import { DesignLayoutContext } from "../context/designLayoutContext";

import "@emisgroup/clint-templates-common/lib/appCanvas.scss";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-over-1280.css";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-800-1280.css";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-630-800.css";
import "@emisgroup/clint-templates-common/lib/appCanvas-width-under-630.css";

type AppCanvasProps = {
    onSelect: (eventData) => void;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const emptyItem = {} as CanvasItem;

export default function AppCanvas({ onSelect, onPropertyUpdate }: AppCanvasProps) {
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const { templateDefinition: rootContainer } = React.useContext(TemplateContext);
    const { members: templateItems = [] } = rootContainer;
    const canvasRef = React.useRef<HTMLDivElement>(null);
    const canvasSizeClass = useCanvasSizeClass(() => canvasRef.current as HTMLDivElement);
    const { isPropInspectorExpanded } = React.useContext(DesignLayoutContext);
    const [isCollapseAll, setIsCollapseAll] = React.useState(false);
    const [isExpandAll, setIsExpandAll] = React.useState(true);

    const { newItemProperties, handleItemAddition } = useCreateItem(rootContainer);

    const canvasClass = React.useCallback(() => {
        if (mode === AppMode.READ) return `canvas ${isPropInspectorExpanded ? "width-0" : ""}`;
        return `canvas design-mode ${isPropInspectorExpanded ? "width-0" : ""}`;
    }, [mode, isPropInspectorExpanded]);

    const deselect = evt => {
        onSelect({ evt, emptyItem });
        evt.stopPropagation();
    };

    const modifiedRootContainer = { ...rootContainer, members: templateItems };

    const handleCollapseAll = () => {
        setIsCollapseAll(true);
        setIsExpandAll(false);
        // reset isCollapseAll to allow for future toggles
        setTimeout(() => setIsCollapseAll(false), 0);
    };

    const handleExpandAll = () => {
        setIsExpandAll(false);
        setIsCollapseAll(false);
        // reset isExpandAll to allow for future toggles
        setTimeout(() => setIsExpandAll(true), 0);
    };

    return (
        <div role="none" data-testid="app-canvas" className={canvasClass()} onClick={deselect} ref={canvasRef}>
            <TitleBar onSelect={onSelect} />
            <div
                className={`canvas-body canvas-body-flex template-columns-${rootContainer.columnCount} ${
                    canvasSizeClass || ""
                }`}
            >
                {mode === AppMode.EDIT && (
                    <div className="canvas-toggle" data-testid="toggle-wrapper">
                        <span data-testid="collapse-all-button" onClick={handleCollapseAll}>
                            {t("components.collapseAll")}
                        </span>
                        &nbsp;/&nbsp;
                        <span data-testid="expand-all-button" onClick={handleExpandAll}>
                            {t("components.expandAll")}
                        </span>
                    </div>
                )}
                <Container
                    component={modifiedRootContainer}
                    className="canvas-container"
                    onItemSelect={mode === AppMode.EDIT ? onSelect : undefined}
                >
                    <DesignerCanvasColumns
                        component={modifiedRootContainer}
                        dragDropEnabled={mode === AppMode.EDIT}
                        onItemPropertyUpdate={onPropertyUpdate}
                        onItemSelect={onSelect}
                        handleItemAddition={handleItemAddition}
                        isCollapseAll={isCollapseAll}
                        isExpandAll={isExpandAll}
                    />
                </Container>
                {modifiedRootContainer.members.length === 0 && (
                    <div className="canvas-overlay">
                        <div className="canvas-overlay-title">{t("templates.getStarted.title")}</div>
                        <div>{t("templates.getStarted.message")}</div>
                    </div>
                )}
                {newItemProperties && newItemProperties(modifiedRootContainer)}
            </div>
        </div>
    );
}
