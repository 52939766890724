import {
    CanvasItem,
    Condition,
    ComponentConfigData,
    PropertyDataType,
    TypeOfProperty,
    ComponentType,
    ConditionSourceType,
} from "@emisgroup/clint-templates-common";
import { ListQualifiers, ValueQualifiers } from "@emisgroup/clinical-code-entry/lib/qualifiersEntry/types";
import { NumericQualifier } from "@emisgroup/clinical-code-entry/lib/numericEntry/types";
import { IPerspective } from "@emisgroup/clint-templates-perspectives/lib";

export type ConditionForDisplay = {
    key: string;
    condition: Condition;
    sourceText: string;
    conditionSourceType: ConditionSourceType;
    conditionComponentType?: ComponentType;
    conditionText: string;
};

export enum SelectionMode {
    SINGLE,
    MULTIPLE,
}

type PropertyIdentifier = { item: CanvasItem; propertyName: string };
export interface ParametersForPropertyUpdate extends PropertyIdentifier {
    propertyValue: any;
    perspectiveKey?: string;
}

export type PropertyValidity = { isValid: boolean; message?: string };
export type PropertyValidities = { [name: string]: PropertyValidity };

export type PerspectiveValidities = IPerspective & PropertyValidities;

export type PropertyAndPerspectiveValidities = {
    [name: string]: PropertyValidity | PerspectiveValidities[];
};

export type InvalidPerspective = IPerspective & {
    [name: string]: string;
};
export type InvalidProperties = {
    [name: string]: string | InvalidPerspective[];
};

export interface PropsForProperty {
    component: CanvasItem;
    name: string;
    description: string;
    dataAttributes?: PropertyDataType;
    defaultValue: any;
    readOnly: boolean;
    propertyType: TypeOfProperty;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
    setFocus?: boolean;
    validity?: PropertyValidity;
    allowsOverrideEdit?: boolean;
}

export interface ITabItemType {
    /**
     * The aria control for the item
     */
    ariaControls: string;
    /**
     * The text on the item
     */
    text: string;
}

export type ContentLibraryStore = {
    name: string;
    description: string;
    ern: string;
    ref: string;
    contentStatus?: string;
    localStorageDate?: Date;
};

export enum ConflictType {
    VisibilityRule,
    CalculationParameter,
}

export type DeletionInformation = {
    canvasItemType: string;
    membersExist: boolean;
    conflicts: { description: string; conflictType: ConflictType }[];
};

export type ConfigForComponents = {
    data: Array<ComponentConfigData>;
};

export type Qualifiers = {
    numeric?: NumericQualifier;
    list?: ListQualifiers;
    value?: ValueQualifiers;
};

export enum SaveScope {
    Template = "template",
    Selection = "selection",
}

export enum ContentLibraryActivity {
    LOAD,
    SAVE,
}

export type ComponentDragDropLayout = {
    dragDropEnabled: boolean;
    isFirstChild: boolean;
    isLastChild: boolean;
};

export enum VisibilityRuleFields {
    COMPONENT_COMPARISON_VALUE = "ComponentComparisonValue",
}

export type ConditionFieldError = {
    [VisibilityRuleFields.COMPONENT_COMPARISON_VALUE]?: string;
};

export type VisibilityFieldValidity = {
    isValid: boolean;
    message?: string;
};

export enum ActionButtonProperty {
    LABEL = "Label",
}

export type ActionButtonErrors = {
    [ActionButtonProperty.LABEL]?: string;
};

export type ActionButtonPropertyValidity = VisibilityFieldValidity;

/**
 * The Radio item interface used in Radiobuttons component
 */
export interface IRadioItem {
    /**
     * Radio item checked by default
     */
    checked?: boolean;
    /**
     * Sets the radio button to disabled state
     */
    disabled?: boolean;
    /**
     * The display text of the item
     */
    text: string;
    /**
     * The value of the item
     */
    value: string | number | string[];
}
