import * as React from "react";
import { Checkbox } from "@emisgroup/ui-checkbox";
import { PropertyEntryProps } from "./types";

type BooleanPropertyEntryProps = PropertyEntryProps;

const BooleanPropertyEntry = ({ id, component, name, isReadOnly, onPropertyUpdate }: BooleanPropertyEntryProps) => {
    const handleCheckboxChange = (checked: boolean) => {
        onPropertyUpdate({ item: component, propertyName: name, propertyValue: checked });
    };

    const hasDefinedValue = typeof component[name] !== "undefined" && Boolean(String(component[name]).trim());
    const isChecked = hasDefinedValue ? Boolean(component[name]) : false;

    return (
        <Checkbox
            id={id}
            data-testid={id}
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={isReadOnly}
        ></Checkbox>
    );
};

export default BooleanPropertyEntry;
