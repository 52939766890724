import React from "react";
import { ICheckboxProps } from "../types";
import { FormElement } from "@emisgroup/ui-form";
import { useTranslation } from "@emisgroup/application-intl";

type CheckboxProps = ICheckboxProps & {
    onFocus?: React.FocusEventHandler;
    onBlur?: React.FocusEventHandler;
    tabIndex?: number;
    isMandatory?: boolean;
};

const ControlledCheckbox = ({
    id,
    className = "",
    checked,
    labelText,
    invalid = false,
    isMandatory = false,
    onChange,
    onFocus,
    onBlur,
    disabled,
    title,
    "data-testid": dataTestId,
    tabIndex,
}: CheckboxProps) => {
    const { t } = useTranslation();
    return (
        <FormElement
            errorText={invalid ? t("components.selectionMandatory") : undefined}
            className="controlled-checkbox"
        >
            {isMandatory && <span className="checkbox-mandatory-indicator controlled-form-element__label--mandatory" />}
            <span>
                <input
                    className={`controlled-checkbox__input${
                        invalid ? " controlled-checkbox__input--invalid" : ""
                    } ${className}`}
                    id={id}
                    type="checkbox"
                    onChange={onChange}
                    disabled={disabled}
                    title={title}
                    data-testid={dataTestId}
                    checked={checked}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                <label className="controlled-checkbox__label" htmlFor={id} tabIndex={tabIndex}>
                    {labelText || <>&nbsp;</>}
                </label>
            </span>
        </FormElement>
    );
};

export default ControlledCheckbox;
