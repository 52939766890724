import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Dropdown } from "@emisgroup/ui-dropdown";
import { Condition, ConditionSource, ComponentValueSelectedCondition } from "@emisgroup/clint-templates-common";
import { IListItem } from "@emisgroup/clint-templates-perspectives";
import { ComponentValueConditionOperator } from "@emisgroup/clint-templates-common/src/types";

const is = "is";
const isNot = "isNot";

type VisibilityConditionOperatorProps = {
    condition: Condition;
    conditionIndex: number;
    canEdit: boolean;
    onChanged: (value?: string) => void;
};

const VisibilityConditionOperator = ({
    condition,
    conditionIndex,
    canEdit,
    onChanged,
}: VisibilityConditionOperatorProps) => {
    const { t } = useTranslation();

    const options: IListItem[] = [
        {
            text: t("templates.rules.is"),
            value: is,
        },
        {
            text: t("templates.rules.isNot"),
            value: isNot,
        },
        {
            text: t("templates.rules.isEquals"),
            value: ComponentValueConditionOperator.EQUALS,
        },
        {
            text: t("templates.rules.isLessThan"),
            value: ComponentValueConditionOperator.LESS_THAN,
        },
        {
            text: t("templates.rules.isGreaterThan"),
            value: ComponentValueConditionOperator.GREATER_THAN,
        },
        {
            text: t("templates.rules.isLessThanOrEqual"),
            value: ComponentValueConditionOperator.LESS_THAN_OR_EQUAL,
        },
        {
            text: t("templates.rules.isGreaterThanOrEqual"),
            value: ComponentValueConditionOperator.GREATER_THAN_OR_EQUAL,
        },
        {
            text: t("templates.rules.isRange"),
            value: ComponentValueConditionOperator.RANGE,
        },
    ];

    if (
        condition.conditionSource === ConditionSource.COMPONENT_VALUE_SELECTED ||
        condition.conditionSource === ConditionSource.COMPONENT_SCORE_VALUE_COMPARISON
    ) {
        const componentValueSelectedCondition = condition as ComponentValueSelectedCondition;
        const currentValue = componentValueSelectedCondition.negated || is;

        return (
            <div className="visibility-condition__condition__operator">
                <Dropdown
                    data-testid={`condition-operator-${conditionIndex}`}
                    onValueChange={value => onChanged(value)}
                    value={currentValue}
                    disabled={!canEdit}
                >
                    <Dropdown.Trigger />
                    <Dropdown.Content>
                        {options.map(option => (
                            <Dropdown.Item key={option.text} value={option.value.toString()}>
                                {option.text}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Content>
                </Dropdown>
            </div>
        );
    }

    return (
        <div className="visibility-condition__condition__is">
            {condition.conditionSource === ConditionSource.QUERY ? t("templates.rules.has") : t("templates.rules.is")}
        </div>
    );
};

export default VisibilityConditionOperator;
