import * as React from "react";
import "./warningBox.css";
import { Banner } from "@emisgroup/ui-banner";
import { InfoWarning } from "@emisgroup/icons-react";

type WarningBoxProps = { title: string; className?: string };
type PropsWithChildren = React.PropsWithChildren<WarningBoxProps>;

const WarningBox = ({ title, className = "", children }: PropsWithChildren) => {
    return (
        <Banner color="warning" className={`ctc-warning-box ${className} banner-warning`}>
            <Banner.Cap>
                <InfoWarning title={title} />
            </Banner.Cap>
            <Banner.Content vertical={true}>
                <div className="ctc-warning-box__title">{title}</div>
                <div>{children}</div>
            </Banner.Content>
        </Banner>
    );
};

export default WarningBox;
